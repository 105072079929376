import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import React, { FC } from "react";
interface Props {
	onChange: any;
	label: string;
	defaultValue: any;
}

const AVTimePicker: FC<Props> = ({ label, onChange, defaultValue }) => {
	const timeSteps = { hours: 1, minutes: 1 };
	const date = new Date();
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DemoContainer components={["TimePicker", "TimePicker"]}>
				<TimePicker
					defaultValue={defaultValue}
					label={label}
					onChange={onChange}
					slotProps={{ textField: { fullWidth: true } }}
					timeSteps={timeSteps}
				/>
			</DemoContainer>
		</LocalizationProvider>
	);
};

export default AVTimePicker;
