import { RootStateOrAny } from 'react-redux';
import { store } from '../Redux/store';
import { Server } from '../Platform/Server';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { Alert } from '../AVCore/Alert';
import React from 'react';
import { Auth } from '../Platform/Auth';
import { AVAuth } from '../Platform/AVAuth';
import _ from 'lodash';
import ToastMessage from '../Hooks/useAlertToast';
import { setAlertState, setAlerts, setNewAlerts } from "../Redux/Actions/alertActions";
import { setFeedOwners, setFeeds, setFeedsState, setFeedsUpdate, setFetchProcess, setPostList } from "../Redux/Actions/feedsAction";
import { setConvo, setConvoState } from "../Redux/Actions/convoActions";
import { setEntityToken, setEntitydata, setFeedShowing, setFeedsLoad, setFollowRequestData, setFollowersData, setIsNotificationEnabled, setLocation, setSearch, setToastShow, setUser, setuserisguardian, setusersLang, setusersprofile, setisActive, setDeviceFcmToken, setIsConvoScreenOpen, setLastFeedUpdate, setresetState } from "../Redux/Actions/userActions";
import * as AV from '../AVCore/AVCore';

export const SSE = async () => {
	let processedAlertIds = new Set();

	let pingdata: any = [];

	const SSEupdate = async () => {
		const { bearer, host, impersonate } = await Server.init();
		const headers: Record<string, string> = { Authorization: bearer };

		if (impersonate !== "") {
			headers.AVImpersonate = impersonate;
		}
		if (Server?.SSE == null) {
			Server.SSE = await new EventSourcePolyfill(`${host}/api/Alert/SSE`, { headers });
			SSEEvents();
		}
		return Server.SSE;
	};

	const SSEEvents = async () => {
		let checkNewDMTimeout;
		Server.SSE.onmessage = async event => {
			if (pingdata?.length < 2 && event?.data == "ping") {
				pingdata?.push(event?.data);
			}
			if (event?.data !== "ping") {
				var data = event?.data === "AlertListUpdate" ? event?.data : JSON.parse(event?.data);
				if (data?.alertType == "NewDM" || data?.alertType == "ReplyDM") {
					console.log("Received SSE alert for convo.....");
				}

				console.log("data", data);
				if (data?.data !== "AddConvo" && data?.alertType) {
					if (!processedAlertIds.has(data.id)) {
						const reduxstate: RootStateOrAny = store.getState();
						const { alerts, user } = reduxstate;
						let alertsval = alerts.alert[0];
						const existingAlertIds = new Set(alertsval?.map(alert => alert?.id));
						if (!existingAlertIds?.has(data?.id)) {
							let ToastAlert = { type: data?.alertType };
							ToastMessage({ ToastAlert });
							console.log("Toast 1");
							store.dispatch(setNewAlerts([JSON.parse(event?.data)]));
						}
						processedAlertIds.add(data.id);
						if (data?.alertType == "NewDM" || data?.alertType == "ReplyDM") {
							//handleNewDMAlert(data);
						}
					}
				} else if (data === "AlertListUpdate") {
					fetchAlertsOnfocus(true);
				}
			}
		};
	};

	const list_to_tree = list => {
		var map = {},
			node: any,
			roots: any = [],
			i;

		for (i = 0; i < list?.length; i += 1) {
			map[list[i]?.id] = i; // initialize the map
			if (list[i]?.children?.length === 0 || !list[i]?.children) list[i]["children"] = []; // initialize the children
			if (list[i]?.galleryPost?.length === 0 || !list[i]?.galleryPost) list[i]["galleryPost"] = [];
			list[i]["ReplyPost"] = [];
		}

        for ( i = 0; i < list?.length; i += 1 )
        {
            node = list[ i ];
            if ( node.parentPost !== undefined )
            {
                if (
                    list[ map[ node.parentPost ] ]?.gallery?.items?.filter( ( x ) => x === node.id )?.length > 0 &&
                    node.gallery == undefined
                )
                {
                    let excitingpost = list[ map[ node.parentPost ] ]?.galleryPost?.filter(
                        ( x ) => x?.id === node?.id
                    );
                    if ( excitingpost?.length === 0 || excitingpost === undefined )
                        list[ map[ node.parentPost ] ]?.galleryPost.push( node );
                } else
                {
                    let PPost = list.filter( ( x ) => x?.id === node?.parentPost );
                    node.ReplyPost.push( PPost[ 0 ] );
                    roots.push( node );
                }
            } else
            {
                roots.push( node );
            }
        }
        return roots;
    };

    async function updateUnreadMessages ( item )
    {
        let id = item?.data?.feed?.id ? item.data.feed.id : item?.id;
        const reduxstate : RootStateOrAny = store.getState();
        const { alerts, user } = reduxstate;
        let useralertList = alerts?.alert[ 0 ];
        let usernewalertList = alerts?.newalert;
        const UnReadChatMessage = useralertList?.filter( ( x : any ) => x?.actionTaken == undefined && x?.data?.feed?.id === id && ( x?.alertType === "NewDM" || x?.alertType === "ReplyDM" ) );
        const UnReadNewChatMessage = usernewalertList?.filter( ( x : any ) => x?.actionTaken == undefined && x?.data?.feed?.id === id && ( x?.alertType === "NewDM" || x?.alertType === "ReplyDM" ) );
        if ( UnReadChatMessage?.length > 0 )
        {
            for ( const message of useralertList )
            {
                if ( message?.data?.feed?.id === id && message?.actionTaken !== "Viewed" && ( message?.alertType === "NewDM" || message?.alertType === "ReplyDM" ) )
                {
                    message.actionTaken = 'Viewed';
                }
            }
            store.dispatch( setAlerts( useralertList ) );
            for ( let i = 0; i < UnReadChatMessage.length; i++ )
            {
                await Alert.Save( UnReadChatMessage[ i ] );
            }
        }
        if ( UnReadChatMessage?.length == 0 )
        {
            for ( const message of usernewalertList )
            {
                if ( message?.data?.feed?.id === id && message?.actionTaken !== "Viewed" && ( message?.alertType === "NewDM" || message?.alertType === "ReplyDM" ) )
                {
                    message.actionTaken = 'Viewed';
                }
            }
            useralertList =  useralertList?.concat( usernewalertList );
            useralertList = _.uniqBy( useralertList, 'id')
            store.dispatch( setAlerts( useralertList ) );
            for ( let i = 0; i < UnReadNewChatMessage.length; i++ )
            {
                await Alert.Save( UnReadNewChatMessage[ i ] );
            }
        }
    }
    async function handleNewDMAlert ( item )
    {
        console.log("processing newDM alert in SSE.....")

        const reduxState : RootStateOrAny = store.getState();
        const { user, convo } = reduxState;    
        const userconvo = convo.convo[ 0 ];
        const lastUpdateFeed = user.lastUpdateFeed;
        const feedId = item?.data?.feed?.id;
        const postdata = userconvo?.find((x) => x?.id === feedId);

        const post = postdata?.posts?.find( ( x ) => x?.id === item?.target?.id );
        if ( user?.isConvoScreenOpen?.isOpen && user?.isConvoScreenOpen?.convoId == item?.data?.feed?.id )
        {
            updateUnreadMessages( item )
        }
        let PostList : any = [];
        PostList[ 0 ] = item?.target?.id;
        let response = userconvo?.find((x : any) => x?.id === item?.data?.feed?.id)
        if (response) {
            await AV.Feed.getPosts( AV.AVEntity.getFk( response ),PostList.slice(0, 50)).then((x : any) => {
                if (x?.length > 0) {
                    var usercon = userconvo || [];
                    let Index = response.posts?.findIndex( ( y ) => y.id == x[0]?.id )
                    if ( Index !== -1 )
                    {
                        x[ 0 ].galleryPost = response.posts[ Index ].galleryPost; 
                        // response.posts[ Index ] = x[0];
                        response.posts = response.posts?.filter( ( y ) => y.id !== x[ 0 ]?.id )
                        response.posts = list_to_tree( response?.posts )
                        response?.posts?.sort( ( a, b ) =>
                        {
                            return ( new Date( b.createDate ) as any ) - ( new Date( a.createDate ) as any );
                        } );
                        usercon = userconvo?.filter( ( x : any ) => x?.id != item?.data?.feed?.id )
                        usercon.unshift( response );
                        usercon = _.uniqBy( usercon, 'id' );
                        store.dispatch( setConvo( usercon ) );

                        //unshift the edited one
                        response.posts?.unshift( x[ 0 ] );
                        response.posts = list_to_tree( response?.posts )
                        response?.posts?.sort( ( a, b ) =>
                        {
                            return ( new Date( b.createDate ) as any ) - ( new Date( a.createDate ) as any );
                        } );
                        usercon = userconvo?.filter( ( x : any ) => x?.id != item?.data?.feed?.id )
                        usercon.unshift( response );
                        usercon = _.uniqBy( usercon, 'id' );
                    }
                    else
                    {
                        response.posts?.unshift( x[0] );
                        response.posts = _.uniqBy(response?.posts, 'id' );
                        //response.lastUpdated = x[ 0 ].lastUpdated;
                        response.posts = list_to_tree( response?.posts )
                        response?.posts?.sort( ( a, b ) =>
                        {
                            return ( new Date( b.createDate ) as any ) - ( new Date( a.createDate ) as any );
                        } );
                        usercon = userconvo?.filter( ( x : any ) => x?.id != item?.data?.feed?.id )
                        usercon.unshift( response );
                        usercon = _.uniqBy( usercon, 'id' );
                    }
                }
                store.dispatch(setConvo(usercon));
            });
        }
        else {
            const feedParam : AV.IFeedParam = {
                feed: item?.data?.feed,
                startDate: postdata?.posts?.length > 0 ? postdata?.lastUpdated : new Date(0), forward: true
            };
            await AV.Feed.getFeed(feedParam).then(async (response : any) => {
                if (response?.id) {
                    let PostList = await AV.Feed.getPostList(response);
                    PostList = PostList?.filter(item => item !== null);
                    if (PostList.length > 0) {
                        await AV.Feed.getPosts(AV.AVEntity.getFk(response), PostList).then((x : any) => {
                            if (x?.length > 0) {
                                response.posts = x;
                                response.posts = list_to_tree( response?.posts )
                                var usercon = userconvo || [];
                                const existingconvo = usercon.find((x : any) => x?.id === response?.id);
                                if (!existingconvo) {
                                        usercon.unshift(response);
                                }
                                store.dispatch(setConvo(usercon));
                            }
                        });
                    }
                }
            });
        }

		//Temporary code to update feeds in background
		const feedParam: AV.IFeedParam = {
			feed: item?.data?.feed,
			startDate: postdata?.posts?.length > 0 ? postdata?.lastUpdated : new Date(0),
			forward: true,
		};
		AV.Feed.getFeed(feedParam).then(async (response: any) => {
			if (response?.id) {
				//set last successful get feed
				let data: any = {
					Ifeed: feedParam,
					lasttime: new Date(),
				};
				let Index = lastUpdateFeed?.findIndex(x => x?.Ifeed?.feed?.id == item?.feed?.id);
				if (Index !== -1) {
					lastUpdateFeed[Index] = data;
				}
				store.dispatch(setLastFeedUpdate(lastUpdateFeed));


                let PostList = await AV.Feed.getPostList(response);
                PostList = PostList?.filter(item => item !== null);
                if (PostList.length > 0) {
                    AV.Feed.getPosts(AV.AVEntity.getFk(response), PostList).then((x : any) => {
                        if (x?.length > 0) {
                            response.posts = x;
                            var usercon = userconvo || [];
                            const existingconvo = usercon.find((x : any) => x?.id === response?.id);
                            if (!existingconvo) {
                                let user : any = AV.Person._self;
                                if (usercon?.length == 0 && (user?.convos == undefined || user?.convos?.length == 0))
                                    usercon.unshift(response);
                                else
                                    return;
                            } else {
                                let updatedFeeds = [ ...(existingconvo?.posts || []) ];
                                response?.posts.forEach((newconvo : any) => {
                                    let existingFeed = existingconvo?.posts?.find((x : any) => x?.id === newconvo?.id);
                                    if (!existingFeed) {
                                        updatedFeeds.unshift(newconvo);
                                    } else {
                                        existingFeed.impressions = newconvo.impressions;
                                        existingFeed.text = newconvo.text;
                                    }
                                });
                                existingconvo.posts = _.uniqBy([ ...updatedFeeds ], 'id');
                                //existingconvo.lastUpdated = response.lastUpdated;
                                existingconvo.people = response.people;
                                usercon.unshift(existingconvo);
                                usercon = _.uniqBy(usercon, 'id');
                            }
                            store.dispatch(setConvo(usercon));
                        }
                    });
                }
            }           
        });
    }
    async function fetchAlertsOnEvery30secs (notificationEnabled) {
        let fetchInterval;
        fetchInterval = setInterval(() => {
            if (document.visibilityState === 'visible') {
                console.log('Get alert on every 30secs...');
                fetchAlertsOnfocus(notificationEnabled);
            }
        }, 180000);
    }
    async function fetchAlertsOnfocus (notificationEnabled) {

        console.log("notificationEnabled....", notificationEnabled);
        const reduxstate : RootStateOrAny = store.getState();
        const { alerts, user } = reduxstate;
        const userdata = AVAuth.currentUser();
        if ( userdata == undefined )
        {
            store.dispatch( setAlertState() );
            store.dispatch( setConvoState() );
            store.dispatch( setFeedsState() );
            store.dispatch( setresetState() );
            return;
        }
        let alertsval = alerts.alert[ 0 ];
        if (alertsval == undefined)
            alertsval = [];
        //if ( alertsval[ 0 ]?.alertType === "NewDM" && pingdata?.length <=2)
        //{

		//    SSEupdate();
		//}
		if (alertsval?.length > 0) {
			const existingAlertIds = new Set(alertsval?.map(alert => alert?.id));
			Alert.List(new Date(alertsval?.length > 0 ? alertsval[0]?.date : 0), !notificationEnabled).then(x => {
				//var alertList: any = x?.alerts;
				console.log("Test:::::::::::::::::::::::::::::::::::::::::", x);
				//var alertList: any = x;
				//alertList?.sort(function (a, b) {
				//	let c: any = new Date(b.date);
				//	let d: any = new Date(a.date);
				//	return d - c;
				//});
				//if (alertList?.length > 0) {
				//	for (const alert of alertList) {
				//		if (!existingAlertIds?.has(alert?.id) && alert?.actionTaken !== "Viewed") {
				//			alertsval.unshift(alert);

				//			store.dispatch(setNewAlerts([alert]));
				//			existingAlertIds.add(alert.id);
				//		} else if (
				//			alert?.actionTaken !== undefined &&
				//			alert?.actionTaken !== "Viewed" &&
				//			!existingAlertIds?.has(alert?.id)
				//		) {
				//		const existingAlertIndex = alertsval?.findIndex(existingAlert => existingAlert?.id === alert?.id);
				//			if (existingAlertIndex !== -1) {
				//				alertsval.splice(existingAlertIndex, 1);
				//				alertsval.unshift(alert);
				//				let ToastAlert = { type: alert?.alertType };
				//				ToastMessage({ ToastAlert });
				//				console.log("Toast 3");
				//				store.dispatch(setNewAlerts([alert]));
				//			}
				//		}
				//	}
				//}
			} );
			let alerts = await Alert.GetAlerts();
		}
	}

	return { SSEupdate, fetchAlertsOnfocus, fetchAlertsOnEvery30secs };
};
