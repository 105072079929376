import {
    AddIcon,
    Box,
    Divider,
    HStack,
    Pressable,
    ScrollView,
    Text,
    VStack,
    CloseIcon,
    Button,
    Image,
    Input,
} from "native-base";
import React, { FC, useRef } from "react";
import { AVButtonRoundedFull, AVButtonRoundInvert } from "components/common/AVButtons";
import AVCancel from "components/common/AVCancel";
import AVEmoji from "components/common/AVEmoji";
import { StyleSheet } from "react-native";
import Picker from "emoji-picker-react";
import * as AV from "AVCore/AVCore";
import { AddLinkIcon, AddPhotoIcon, VideoIcon, DropdownIcon } from "assets/icons/icon-assets";
import { useWindowDimension } from "Utility/WindowDimensions";
import { Loc } from "App/Loc";
import { Media, Post, Person } from "../../AVCore/AVCore";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setFeeds } from "../../Redux/Actions/feedsAction";
import Url from "../../assets/images/Url.png";
import photos from "../../assets/images/photos.png";
import Dropdown from "../../assets/images/dropdown.svg";
import { v4 as uuidv4 } from "uuid";
import AVEmojiPicker from "../../components/common/AVEmojiPicker";
import { setisActive } from "../../Redux/Actions/userActions";
import { LineIcon } from "react-share";
import {
    extractUrlsFromText,
    extractYouTubeVideoId,
    getContentTypeFromUrl,
    isHuddleURL,
    isYouTubeURL,
} from "./preview/utils/util";
import AVInputtext from "../../components/AVInputtext";
import LinkPreview from './preview/LinkPreview'
import CustomToast from "../../Hooks/useCustomToast";

interface Props {
    visibilityPermission: string;
    setShowCreatePost: any;
    Postdata: any;
    type: string;
}

const AVPostPopup: FC<Props> = ({ setShowCreatePost, Postdata, type, visibilityPermission }) => {
    const dispatch = useDispatch();
    const dimensions = useWindowDimension();
    const textRef = React.useRef<any>(null);
    const imageInputRef = React.useRef<HTMLInputElement>(null);
    const currentUser = useSelector((state: RootStateOrAny) => state.user.user[0]);
    const userfeeds = useSelector((state: RootStateOrAny) => state.feeds.feeds[0]);
    const [showAddMedia, setShowAddMedia] = React.useState(false);
    const [images, setImages] = React.useState<any>([]);
    const [videos, setvideos] = React.useState<any>([]);
    const [media, setMedia] = React.useState<any>([]);
    const [Img, setImg] = React.useState<any>([]);
    const [video, setvio] = React.useState<any>([]);
    const [link, setLink] = React.useState<any>([]);
    const [picker, setPicker] = React.useState(false);
    const [text, setText] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [isImageProcessing, setIsImageProcessing] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [successMessage, setSuccessMessage] = React.useState('');
    const [showExternalLinkInput, setShowExternalLinkInput] = React.useState(false);
    const [externalLink, setExternalLink] = React.useState("");
    const [visibility, setvisibility] = React.useState(
        (Postdata?.id == "78b5b063-fd3e-4925-92bd-2a1888c6a46a" || Postdata?.parent?.id == "78b5b063-fd3e-4925-92bd-2a1888c6a46a") ? "Public" : visibilityPermission == "B" ? "Public" : "Private");
    const [isActive, setIsActive] = React.useState(false);
    const isStaffOfEntity = Postdata?.staff?.some((x: any) => x?.id === currentUser?.id);
    const isMobile = dimensions?.width <= 500 || false;
    const [addLinkLoad, setAddLinkLoad] = React.useState(false);
    const [isOpenBox, setIsOpenBox] = React.useState(false);
    const menuRef = useRef(null);
    const newGuid = uuidv4();
    const handleDrop = async (e) => {
        e.preventDefault();

        const doc = e.dataTransfer.files;
        const fileInput: any = imageInputRef.current;
        let files: any = Array.from(doc);
        const updatedFiles = files.map((file: any) => {
            const fileName = file.name;
            if (fileName.toLowerCase().endsWith(".jfif")) {
                const updatedFileName = fileName.replace(/\.\w+$/, ".jpg");
                const updatedFile = new File([file], updatedFileName, { type: "image/jpeg" });
                return updatedFile;
            } else {
                return file;
            }
        });
        files = updatedFiles;
        let m = "";
        console.log(files);
        if (files.length > 20) {
            m = Loc.currentLang?.alertMessages?.maxUploadFiles || Loc.currentLang.postPopup.youCannotUploadMoreThan20FilesPerPost;
            setAlertMessage(m);
            return;
        }
        setShowAddMedia(false);
        setPicker(false);
        files.forEach((img: any) => {
            if (
                img.type !== "image/jpeg" &&
                img.type !== "image/jfif" &&
                img.type !== "image/png" &&
                img.type !== "image/webp" &&
                img.type !== "image/gif" &&
                img.type !== "video/mp4" &&
                img.type !== "video/quicktime"
            ) {
                m =
                    Loc.currentLang.alertMessages.supportedFormats || Loc.currentLang.postPopup.formatIsUnsupportedOnlyJpegJfifPngWebpGifAreAllowed;
                files = files.filter((item: any) => item.name !== img.name);
                return;
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(img);
                reader.onload = readerEvent => {
                    if (img.type.startsWith("image")) setImages(images => [...images, readerEvent.target?.result]);
                    else setvideos(videos => [...videos, readerEvent.target?.result]);
                };
            }
        });
        setIsImageProcessing(true);

        const data = await AV.Media.saveFiles(AV.Media.getFormData(files), currentUser?.region);
        if (data?.length > 0) {
            setMedia(prev => [...prev, ...data]);
            data?.forEach((img: any) => {
                if (img?.type?.startsWith("image")) setImg(images => [...images, img]);
                else setvio(videos => [...videos, img]);
            });
        }
        fileInput.value = null;
        setIsImageProcessing(false);
    };

    const handleDragOver = e => {
        e.preventDefault();
    };

    const handleExternalLink = e => {
        setExternalLink(e.target.value);
    };
    const handleImages = async (e: any) => {
        setAlertMessage("");
        const fileInput: any = imageInputRef.current;

        let files: any = Array.from(e?.target?.files);
        const updatedFiles = files.map((file: any) => {
            const fileName = file.name;
            if (fileName.toLowerCase().endsWith(".jfif")) {
                const updatedFileName = fileName.replace(/\.\w+$/, ".jpg");
                const updatedFile = new File([file], updatedFileName, { type: "image/jpeg" });
                return updatedFile;
            } else {
                return file;
            }
        });
        files = updatedFiles;
        let m = "";

        if (files.length > 20) {
            m = Loc.currentLang?.alertMessages?.maxUploadFiles || Loc.currentLang.postPopup.youCannotUploadMoreThan20FilesPerPost;
            setAlertMessage(m);
            return;
        }
        setShowAddMedia(false);
        setPicker(false);
        files.forEach((img: any) => {
            if (
                img.type !== "image/jpeg" &&
                img.type !== "image/jfif" &&
                img.type !== "image/png" &&
                img.type !== "image/webp" &&
                img.type !== "image/gif" &&
                img.type !== "video/mp4" &&
                img.type !== "video/quicktime"
            ) {
                m =
                    Loc.currentLang.alertMessages.supportedFormats ||
                    Loc.currentLang.postPopup.formatIsUnsupportedOnlyJpegJfifPngWebpGifAreAllowed
                files = files.filter((item: any) => item.name !== img.name);
                return;
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(img);
                reader.onload = readerEvent => {
                    if (img.type.startsWith("image")) setImages(images => [...images, readerEvent.target?.result]);
                    else setvideos(videos => [...videos, readerEvent.target?.result]);
                };
            }
        });
        setIsImageProcessing(true);
        console.log(files);
        const data = await AV.Media.saveFiles(AV.Media.getFormData(files), currentUser?.region);
        if (data?.length > 0) {
            setMedia(prev => [...prev, ...data]);
            if (data?.length > 0) {
                data.forEach(img => {
                    if (img?.type?.startsWith("image")) setImg(images => [...images, img]);
                    else setvio(videos => [...videos, img]);
                });
            }
        }
        fileInput.value = null;
        setIsImageProcessing(false);
    };

    const handlePublish = async () => {
        const text = textRef?.current.value || null;
        if (text === null && media.length === 0) {
            setAlertMessage(Loc.currentLang.menu.emptyMediaErrorMsg);
            return;
        }
        if (media.length > 20) {
            setAlertMessage(Loc.currentLang.menu.limitExccedErrorMsg);
            return;
        }
        setAlertMessage("");
        setIsLoading(true);
        let param: AV.IFeedParam = {
            startDate: new Date(0),
            forward: true,
            checkArchive: false,
            feed: AV.AVBase.getFk(Postdata ? Postdata : Person.self()),
        };
        if (param?.feed?.id) {
            let post: any = { pk: param?.feed?.id, region: param?.feed?.region };
            post.text = text;
            post.owner = AV.AVEntity.getFk(Postdata ? Postdata : Person.self());
            post.visibility = visibility == Loc.currentLang.menu.followers ? "Follow" : visibility;
            if (media && media.length === 1) {
                post.media = media[0];
            }
            if (media && media.length > 1) {
                let galleryPost: any[] = [];
                let gallery = { layout: null, Items: [] };
                post.gallery = gallery;
                post.id = newGuid;
                let parentPostId = newGuid;
                let galleryArray: string[] = [];
                for (var i = 0; i < media.length; i++) {
                    let param1: AV.IFeedParam = {
                        feed: AV.AVBase.getFk(Postdata ? Postdata : Person.self()),
                        startDate: new Date(0),
                        forward: true,
                        checkArchive: false,
                    };
                    let post1: any = { pk: param1?.feed?.id, region: param1?.feed?.region };
                    post1.parentPost = parentPostId;
                    post1.media = media[i];
                    post1.visibility = visibility == Loc.currentLang.menu.followers ? "Follow" : visibility;
                    post1.ptype = "G";
                    let childresponse: any = await Post.save(post1);
                    dispatch(setisActive(true));
                    if (childresponse === "Inappropriate Content") {
                        setAlertMessage(Loc.currentLang.menu.inappropriateErrorMsg);
                        setIsLoading(false);
                        return;
                    }
                    galleryArray.push(childresponse.id);
                    galleryPost.push(childresponse);
                }
                post.status = "New";
                post.gallery.items = galleryArray;
                let val: any = await Post.save(post);
                dispatch(setisActive(true));
                if (val === "Inappropriate Content") {
                    setAlertMessage(Loc.currentLang.menu.inappropriateErrorMsg);
                    setIsLoading(false);
                    return;
                }
                val.galleryPost = galleryPost;
                userfeeds.unshift(val);
                dispatch(setFeeds(userfeeds));
                setMedia([]);
                setIsLoading(false);
                setShowCreatePost(false);
                return;
            }


            let val: any = await AV.Post.save(post);
            dispatch(setisActive(true));
            if (val === "Inappropriate Content") {
                setAlertMessage(Loc.currentLang.menu.inappropriateErrorMsg);
                setIsLoading(false);
                return;
            }
            userfeeds.unshift(val);
            dispatch(setFeeds(userfeeds));
            setMedia([]);
            setIsLoading(false);
            setSuccessMessage(Loc.currentLang.menu.postPublishSuccessMsg);
            setShowCreatePost(false);
        }
    };
    const HandleAddLinkToMedia = async () => {
        setAddLinkLoad(true)
        //let newLink = externalLink;
        let getExternalLinkType = "application/octet-stream";
        //const youtubeUrl = isYouTubeURL( newLink );
        //const huddleUrl = isHuddleURL( newLink );
        //if ( youtubeUrl )
        //{
        //    newLink = youtubeUrl;
        //} else if ( huddleUrl )
        //{
        //    newLink = huddleUrl;
        //} else
        //{
        //    getExternalLinkType = getContentTypeFromUrl( newLink );
        //    console.log( getExternalLinkType );
        //}
        const isValidLink = /^(https?|ftp):\/\/[a-zA-Z0-9.-]+(\.[a-zA-Z]{2,})?(:\d+)?(\/\S*)?$/;
        if (!isValidLink?.test(externalLink)) {
            let clientError = { message: "Invalid link format." };
            CustomToast({ clientError }); // Display error message using CustomToast
            setAddLinkLoad(false);
            setExternalLink('');
            setShowExternalLinkInput(false)
            return; // Exit the function early if the link format is invalid
        }
        let fd = new FormData();
        fd.append("linkURL", externalLink);
        const data = await AV.Media.saveFiles(fd, currentUser?.region);
        data[0].linkURL = externalLink;
        data[0].orgFile = "";
        data[0].type = getExternalLinkType;
        setMedia(prev => [...prev, ...data]);
        //post.media = data[ 0 ];
        if (data?.length > 0) {
            data.forEach(img => {
                setLink(link => [...link, img]);
            });
        }
        setAddLinkLoad(false);
        setExternalLink('');
        setShowExternalLinkInput(false)
    }

    const menuRefs = useRef(null);
    useOutsideAlerter(menuRefs);
    function useOutsideAlerter(ref) {
        React.useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsActive(false);
                    setPicker(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const handleEmoji = (e, { emoji }) => {
        const ref: any = textRef.current;
        ref.focus();
        const start = text.substring(0, ref.selectionStart);
        const end = text.substring(ref.selectionStart);
        const newText = start + emoji + end;
        setText(newText);
        setPicker(!picker);
    };
    const handeEmojiPicker = e => {
        const ref: any = textRef.current;
        ref.focus();
        const start = text?.substring(0, ref.selectionStart);
        const end = text?.substring(ref.selectionStart);
        const newText = start + e?.native + end;
        setText(newText);
        setPicker(!picker);
    };

    const handletest = () => {
        setPicker(false);
    };
    const mobile = dimensions?.width < 600 || false;

    return (
        <>
            {!showExternalLinkInput && (<Box position="fixed" bgColor={"rgba(0, 0, 0, 0.25)"} bottom={0} right={0} top={0} left={0}>
                <div
                    className={`create_post_popup ${mobile && "create_post_popup_mobile"}`}
                    style={{
                        width: mobile ? dimensions.width - 15 : "631px",
                        maxHeight: mobile ? "650px" : showExternalLinkInput && media?.length == 0 ? "465px" : media?.length > 0 && !showExternalLinkInput ? "500px" : showExternalLinkInput && media?.length > 0 ? "525px" : "405px",
                        padding: "24px",
                    }}>
                    {picker && !mobile && (
                        <Box
                            position={"absolute"}
                            ref={menuRefs}
                            zIndex={99}
                            borderColor={"#2FA3BE"}
                            borderStyle={"solid"}
                            borderWidth={"5px"}
                            width={mobile ? "85%" : "88%%"}>
                            <AVEmojiPicker
                                onEmojiSelect={e => {
                                    return handeEmojiPicker(e);
                                }}
                            />
                        </Box>
                    )}
                    {picker && mobile && (
                        <Box
                            position={"fixed"}
                            top={0}
                            right={0}
                            ref={menuRefs}
                            zIndex={99}
                            borderColor={"#2FA3BE"}
                            borderStyle={"solid"}
                            borderWidth={"5px"}
                            width={"85%"}>
                            <AVEmojiPicker
                                onEmojiSelect={e => {
                                    return handeEmojiPicker(e);
                                }}
                            />
                        </Box>
                    )}
                    <VStack>
                        <HStack
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            borderBottomColor={"AVColor.grey"}>
                            <Text fontSize={24}>{Loc.currentLang.post.createPost}</Text>
                            <AVCancel callback={setShowCreatePost} color={undefined} size={undefined} />
                        </HStack>
                        <ScrollView showsHorizontalScrollIndicator={false} maxHeight={mobile ? '300px' : '400px'}>
                            {(visibilityPermission != "P") && <HStack marginTop={'30px'} space={3}>
                                <Text fontSize={'18'}>{Loc.currentLang.menu.whoCanView} :</Text>
                                <HStack
                                    ref={menuRefs}
                                >
                                    <HStack >
                                        <Button
                                            width={'248px'}
                                            bgColor={'rgba(0, 166, 188, 0.15)'}
                                            rounded='full'
                                            fontWeight='550px'
                                            color='#fff'
                                            justifyContent={'flex-start'}
                                            minHeight='40px'
                                            marginTop={'-5px'}
                                            borderRadius={'20px'}
                                            borderColor={'AVColor.primary'}
                                            onPress={() => {
                                                setIsActive(!isActive)
                                            }}
                                        >
                                            <Text color={'rgba(0, 167, 188, 1)'} font-size={'18px'} lineHeight={'1rem'} paddingLeft={'18px'} >{visibility}</Text>
                                        </Button>
                                        <Pressable position={'absolute'} right={'12px'} top={'10px'} onPress={() => {
                                            setIsActive(!isActive)
                                        }} >
                                            <DropdownIcon height={'9'} width={'16'} />
                                        </Pressable>
                                    </HStack>
                                    <HStack>
                                        {isActive && (<HStack position={'absolute'} right={0} top={'39px'} flexDirection={'column'} width={'240px'} borderRadius={'5px'} bgColor='#FFFFFF' shadow='9' rounded='lg' zIndex={100} >
                                            {(visibilityPermission == "F" || visibilityPermission == "B") && <Button onPress={() => { setvisibility(Loc.currentLang.menu.private); setIsActive(!isActive); }} bgColor='#FFFFFF' height='30px' justifyContent={'start'} _hover={{ backgroundColor: 'AVColor.hovercolor' }} width={'240px'}  ><Text color={'black'} width={'130px'} text-overflow={'ellipsis'}
                                                numberOfLines={1}>{Loc.currentLang.menu.private}</Text></Button>}
                                            {( visibilityPermission == "B" || Postdata?.id == currentUser?.id || Postdata == undefined || isStaffOfEntity ) && <Button onPress={() => { setvisibility(Loc.currentLang.menu.public); setIsActive(!isActive); }} bgColor='#FFFFFF' height='30px' justifyContent={'start'} _hover={{ backgroundColor: 'AVColor.hovercolor' }} width={'240px'}><Text color={'black'} width={'130px'} text-overflow={'ellipsis'}
                                                numberOfLines={1}>{Loc.currentLang.menu.public}</Text></Button>}
                                            {(visibilityPermission == "F" || visibilityPermission == "B") && <Button onPress={() => { setvisibility(Loc.currentLang.menu.followers); setIsActive(!isActive); }} bgColor='#FFFFFF' height='30px' justifyContent={'start'} _hover={{ backgroundColor: 'AVColor.hovercolor' }} width={'240px'}><Text color={'black'} width={'130px'} text-overflow={'ellipsis'}
                                                numberOfLines={1} >{Loc.currentLang.menu.followers}</Text></Button>}
                                        </HStack>
                                        )}
                                    </HStack>
                                </HStack>
                            </HStack>}
                            <div>
                                <Box
                                    marginTop={'24px'}
                                    height={'174px'}
                                    borderWidth={1}
                                    borderRadius={5}
                                    overflow={'hidden'}
                                    borderColor={'AVColor.grey'}
                                    zIndex={isActive ? '-1' : '0'}
                                >
                                    <textarea
                                        ref={textRef}
                                        value={text}
                                        style={{
                                            border: 'none',
                                            resize: 'none',
                                            height: mobile ? '20px' : '95px',
                                            padding: '20px 15px',
                                            outline: 'none',
                                            fontFamily: 'inherit',
                                            fontSize: 'inherit',
                                            borderColor: 'rgba(0, 0, 0, 0.25)'
                                        }}
                                        placeholder={
                                            Loc.currentLang.post?.createPostPlaceholder || Loc.currentLang.postPopup.shareWhatIsOnYourMind
                                        }
                                        onChange={(e) => {
                                            setText(e.target.value);
                                            setAlertMessage('');
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter' && !e.shiftKey && !isMobile && !isLoading && !isImageProcessing) {
                                                e.preventDefault();
                                                handlePublish();
                                            }
                                        }}
                                        autoFocus
                                    ></textarea>
                                    <Text textAlign={'right'}>
                                        <AVEmoji showEmoji={() => setPicker((prev) => !prev)} />
                                    </Text>
                                </Box>
                            </div>
                            <HStack my={3} justifyContent={'center'} width={'85%'} space={4} alignItems={"center"} alignSelf={'center'} zIndex={0}>
                                <RenderImages
                                    setImg={setImg}
                                    Img={Img}
                                    setLink={setLink}
                                    link={link}
                                    setvio={setvio}
                                    video={video}
                                    images={images}
                                    setImages={setImages}
                                    setvideos={setvideos}
                                    videos={videos}
                                    setMedia={setMedia}
                                    media={media}
                                />
                            </HStack>
                        </ScrollView>
                        {(alertMessage || successMessage) && (
                            <HStack alignItems={"center"} justifyContent={"center"}>
                                <Text fontSize={16} color={alertMessage ? "danger.600" : "success.600"}>
                                    {alertMessage || successMessage}
                                </Text>
                            </HStack>
                        )}
                        {isOpenBox && (<VStack
                            ref={menuRefs}
                            width={'170px'}
                            position={'absolute'}
                            bottom={'60px'}
                            left={'20px'}
                            height={'100px'}
                            shadow={2}
                            background={'white'}
                            zIndex={999}
                            borderRadius={'6px'}
                        >
                            <HStack space={'40px'} margin={'24px'}>
                                <Pressable onPress={() => {
                                    setIsOpenBox(false);
                                    imageInputRef?.current?.click();
                                }}>
                                    <VStack space={3}>
                                        <img src={photos} height={'32px'} width={'32px'} />
                                        <Text>Photos</Text>
                                    </VStack>
                                </Pressable>
                                <Pressable onPress={() => { setIsOpenBox(false); setShowExternalLinkInput(!showExternalLinkInput); setExternalLink(''); }}>
                                    <VStack space={3}>
                                        <img src={Url} height={'32px'} width={'32px'} />
                                        <Text>Url</Text>
                                    </VStack>
                                </Pressable>
                            </HStack>
                        </VStack>)}
                        <input
                            type='file'
                            accept='image/jpeg,image/jfif,image/png,image/webp,image/gif,video/mp4,'
                            multiple
                            hidden
                            ref={imageInputRef}
                            onChange={handleImages}
                        />
                        <Pressable
                            position={'absolute'} bottom={0}
                            onPress={() => setIsOpenBox(!isOpenBox)}
                            _hover={{ bgColor: 'gray.100' }}
                            borderRadius={'10px'}
                            margin={'3px'}
                        >
                            <HStack space={3} >
                                <Button
                                    padding={'15px'}
                                    rounded={'full'}
                                    _hover={{
                                        opacity: 0.8
                                    }}
                                    onPress={() => setIsOpenBox(!isOpenBox)}
                                    backgroundColor={"AVColor.primary"}
                                >
                                    {!isOpenBox ? <AddIcon size={'15px'} color='AVColor.white' /> :
                                        <CloseIcon size={'15px'} color='AVColor.white' />}
                                </Button>
                                <Text alignSelf={'center'} fontSize={'18px'} fontWeight={400}>{Loc.currentLang.post.addToYourPost}</Text>
                            </HStack>
                        </Pressable>
                        <HStack justifyContent={"flex-end"} space={3} marginTop={'24px'} zIndex={-1}>
                            <>
                                <Button height={'40px'} width={'180px'} backgroundColor={' rgba(238, 238, 238, 1)'} borderRadius={"full"} onPress={() => setShowCreatePost(false)} ><Text fontWeight={400} color={'rgba(0, 0, 0, 0.5)'} fontSize={'16px'}>CANCEL</Text></Button>
                                <Button height={'40px'} width={'180px'} backgroundColor={"AVColor.primary"} borderRadius={"full"} onPress={handlePublish}
                                    isDisabled={text?.trim()?.length === 0 && media?.length === 0}
                                    isLoading={isLoading || isImageProcessing}
                                    isLoadingText={isImageProcessing ? Loc.currentLang.post.processing : Loc.currentLang.post.publishing}                            >
                                    <Text color={'white'} fontWeight={400} fontSize={'16px'}>PUBLISH</Text>
                                </Button>
                            </>
                        </HStack>
                    </VStack>
                </div>
            </Box>)}
            {showExternalLinkInput && (<Box position='fixed' bgColor={'AVColor.overlay2'} bottom={0} right={0} top={0} left={0}>
                <div
                    className={`create_post_popup ${isMobile && 'create_post_popup_mobile'}`}
                    style={{
                        width: isMobile ? dimensions.width - 15 : '565px',
                        height: isMobile ? '650px' : '240px',
                        marginTop: '12px',
                    }}
                >
                    <VStack width={'100%'} padding={'24px'}>
                        <HStack justifyContent={'space-between'} width={'100%'} alignItems={'center'}
                            marginTop={'20px'}
                            borderBottomColor={'AVColor.grey'}>
                            <Text fontSize={'24px'}>{Loc.currentLang.postPopup.addPostUrl}</Text>
                            <AVCancel callback={() => { setShowExternalLinkInput(false) }} color={undefined} size={undefined} />

                        </HStack>
                        <HStack width={'100%'} marginTop={'24px'}>
                            <Box width={'95%'} justifyContent="center">
                                <AVInputtext
                                    type={'text'}
                                    label={'Add Link'}
                                    maxLength={50}
                                    onChange={handleExternalLink}
                                    defaultValue={undefined}
                                    width={undefined} value={externalLink}
                                    height={'20px'}
                                    minRows={undefined}
                                    multiline={undefined}
                                    size={undefined}
                                    placeholder={undefined}
                                />

                            </Box>
                        </HStack>
                        <HStack justifyContent={'center'} space={'24px'} marginTop={'24px'}>
                            <Button
                                width={'180px'}
                                height={'40px'}
                                backgroundColor={'AVColor.primary'}
                                rounded={'full'}
                                isDisabled={externalLink?.trim().length === 0}
                                isLoading={addLinkLoad}
                                onPress={() =>

                                    HandleAddLinkToMedia()}
                            >
                                <Text fontWeight={500} color={'white'} >{Loc.currentLang.menu.ok}</Text>
                            </Button>
                        </HStack>
                    </VStack>
                </div>
            </Box>)}
        </>
    );
};
const RenderImages = ({ images, setImages, videos, setvideos, setMedia, media, setImg, Img, setvio, video, link, setLink }) => {
    const Grid2styles = StyleSheet.create({
        container: { flex: 1, flexDirection: "row", flexWrap: "wrap" },
        img1: {
            borderColor: "#fff",
            borderWidth: 1,
            width: 260,
            height: 160,
        },
    });
    const handlecancelimage = img => {
        setMedia(media.filter(f => f.id !== img.id));
        setImages(images.filter(f => f.id !== img.id));
        setvideos(videos.filter(f => f.id !== img.id));
        setImg(Img?.filter(f => f.id !== img.id));
        setvio(video?.filter(f => f.id !== img.id));
        setLink(link?.filter(f => f.id !== img.id));
    };

    return (
        <>
            <div className="add_pics_inside1 p0">
                {(Img.length > 0 || video.length > 0 || link?.length > 0) && (
                    <HStack alignContent={"center"} alignSelf={"center"} justifyContent={"center"} space={3}>
                        {(Img.length > 0 || video.length > 0 || link?.length > 0) && (
                            <Box style={Grid2styles.container}>
                                {Img.length > 0 &&
                                    Img?.slice(0, 20).map((media, i) => (
                                        <HStack style={Grid2styles["img1"]} key={media.id + i}>
                                            <Pressable
                                                position={"absolute"}
                                                background={"rgba(151, 151, 151, 0.85)"}
                                                borderRadius={"50px"}
                                                top={'12px'}
                                                right={'12px'}
                                                padding={'10px'}
                                                zIndex={4}
                                                onPress={() => {
                                                    handlecancelimage(media);
                                                }}>
                                                <CloseIcon color={"black"} size={'12px'} />
                                            </Pressable>
                                            <img width={260} height={160} style={{ borderRadius: 10 }} src={Media.getURL(media)} key={media.id + i} alt="" />
                                        </HStack>
                                    ))}
                                {video.slice(0, 20).map((vid, i) => (
                                    <Box key={i}>
                                        <HStack style={Grid2styles["img1"]} key={vid + i}>
                                            <Pressable
                                                position={"absolute"}
                                                background={"rgba(151, 151, 151, 0.85)"}
                                                borderRadius={"50px"}
                                                top={'12px'}
                                                right={'12px'}
                                                padding={'10px'}
                                                zIndex={4}
                                                onPress={() => {
                                                    handlecancelimage(vid);
                                                }}>
                                                <CloseIcon color={"black"} size={'12px'} />
                                            </Pressable>
                                        </HStack>
                                        <HStack position={"absolute"} top={"35%"} left={"35%"}>
                                            <VideoIcon size={"60px"} color="white" />
                                        </HStack>

                                        <img width={260} height={160} src={Media.getVideoPicURL(vid)} key={vid?.id + i} alt="" />
                                    </Box>
                                ))}
                                {link.slice(0, 20).map((vid, i) => (
                                    <Box key={i}>
                                        <HStack style={Grid2styles["img1"]} key={vid + i}>
                                            <Pressable
                                                position={"absolute"}
                                                background={"rgba(151, 151, 151, 0.85)"}
                                                borderRadius={"50px"}
                                                top={'12px'}
                                                right={'12px'}
                                                padding={'10px'}
                                                zIndex={4}
                                                onPress={() => {
                                                    handlecancelimage(vid);
                                                }}>
                                                <CloseIcon color={"black"} size={'12px'} />
                                            </Pressable>
                                            <LinkPreview url={vid.linkURL} imageHeight={'60px'} width={'260px'} height={'160'} />
                                        </HStack>
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </HStack>
                )}
            </div>
        </>
    );
};

export default AVPostPopup;
