import
    {
        AddIcon,
        Box,
        CloseIcon,
        Divider,
        HStack,
        Image,
        Pressable,
        ScrollView,
        Text,
        VStack,
        Button
    } from 'native-base';
import React, { FC, useRef } from 'react';
import { Media } from '../../AVCore/Media';
import {  StyleSheet } from 'react-native';
import AVAdd from 'components/common/AVAdd';
import { AVButtonRoundedFull, AVButtonRoundInvert } from 'components/common/AVButtons';
import AVCancel from 'components/common/AVCancel';
import AVEmoji from 'components/common/AVEmoji';
import Url from "../../assets/images/Url.png";
import photos from "../../assets/images/photos.png";
import './style.css';
import { AVBase, Post } from '../../AVCore/AVCore';
import { Loc } from 'App/Loc';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import * as AV from 'AVCore/AVCore';
import { AddPhotoIcon, DropdownIcon, AddLinkIcon, VideoIcon } from 'assets/icons/icon-assets';
import { setFeeds, setFeedsUpdate } from '../../Redux/Actions/feedsAction';
import Dropdown from '../../assets/images/dropdown.svg';
import { useWindowDimension } from 'Utility/WindowDimensions';
import AVEmojiPicker from '../../components/common/AVEmojiPicker';
import settings from '../../Platform/settings.json';
import MP3Icon from '../../assets/images/MP3ICON.png';
import { setisActive } from '../../Redux/Actions/userActions';
import { isMobile } from 'react-device-detect';
import LinkPreview from '../../components/common/preview/LinkPreview'
import AVInputtext from "../../components/AVInputtext";
import CustomToast from "../../Hooks/useCustomToast";

interface Props {
    setShowCreatePost : any | undefined;
    windowDimensions : any | undefined;
    headerHeight : any | undefined;
    item : any | undefined;
    showModal : any | undefined;
    reload : any | undefined;
    setItem : any | undefined;
    setShowDetails : any | undefined;
}

const EditPostpopup : FC<Props> = ({
    setShowCreatePost,
    headerHeight,
    reload,
    windowDimensions,
    item,
    setItem,
    setShowDetails,
    showModal
}) => {
    const dimensions = useWindowDimension();
    const mobile = dimensions?.width < 600 || false;
    const dispatch = useDispatch();
    const currentUser = useSelector((state : RootStateOrAny) => state.user.user[ 0 ]);
    const [ saveitem, setsaveitem ] = React.useState(item);
    const [ showAddMedia, setShowAddMedia ] = React.useState(false);
    const [ images, setImages ] = React.useState<any>([]);
    const [ docs, setDocs ] = React.useState<any>( [] );
    const [ links, setLinks ] = React.useState<any>( [] );
    const [ media, setMedia ] = React.useState<any>([]);
    const [ error, setError ] = React.useState('');
    const [ videos, setvideos ] = React.useState<any>([]);
    const [ picker, setPicker ] = React.useState(false);
    const [ text, setText ] = React.useState(item?.text);
    const [ cursorPosition, setCursorPosition ] = React.useState();
    const [ isLoading, setIsLoading ] = React.useState(false);
    const [ isImageProcessing, setIsImageProcessing ] = React.useState(false);
    const [ alertMessage, setAlertMessage ] = React.useState('');
    const [ noticeMessage, setNoticeMessage ] = React.useState<any>('');
    const [ successMessage, setSuccessMessage ] = React.useState<any>(null);
    const [ isActive, setIsActive ] = React.useState(false)
    const [ visibility, setvisibility ] = React.useState( item?.visibility === "Follow" ? "Followers" : item?.visibility);
    const imageInputRef : any = React.useRef(null);
    const textEditRef : any = React.useRef();
    const userFeeds = useSelector((state : RootStateOrAny) => state.feeds.feeds[ 0 ]);
    const [ showEditFilePicker, setShowEditFilePicker ] = React.useState(false);
    const [ deletepostlist, setdeletepostlist ] = React.useState<any>([]);
    const [ isTrue, setIsTrue ] = React.useState( false );
    const [ showExternalLinkInput, setShowExternalLinkInput ] = React.useState( false );
    const [ externalLink, setExternalLink ] = React.useState( "" );
    const [ allMedia, setAllMedia ] = React.useState<any>( [] );
    const [ addLinkLoad, setAddLinkLoad ] = React.useState( false );
    const [ isOpenBox, setIsOpenBox ] = React.useState( false );


    const handleDrop = async (e) => {
        e.preventDefault();

        const doc = e.dataTransfer.files;
        const fileInput : any = imageInputRef.current;
        let files : any = Array.from(doc);
        const updatedFiles = files.map((file : any) => {
            const fileName = file.name;
            if (fileName.toLowerCase().endsWith('.jfif')) {
                const updatedFileName = fileName.replace(/\.\w+$/, '.jpg');
                const updatedFile = new File([ file ], updatedFileName, { type: "image/jpeg" });
                return updatedFile;
            }
            else {
                return file;
            }
        });
        files = updatedFiles;
        setShowAddMedia(false);
        files.forEach((img : any) => {
            if (
                img.type !== 'image/jpeg' &&
                img.type !== 'image/jfif' &&
                img.type !== 'image/png' &&
                img.type !== 'image/webp' &&
                img.type !== 'image/gif' &&
                img.type !== 'video/mp4' &&
                !img?.type?.startsWith('application')

            ) {
                setError(`${ img.name } ${ Loc.currentLang.postPopup.formatIsUnsupportedOnlyJpegJfifPngWebpGifAreAllowed}`);
                files = files.filter((item : any) => item.name !== img.name);
                return;
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(img);
                reader.onload = (readerEvent) => {
                    /*setImages((images) => [ ...images, readerEvent.target.result ]);*/
                };
            }
        });
        setIsImageProcessing(true);
        setNoticeMessage(Loc.currentLang.postPopup.pleaseWaitWhileWeProcessYourImages);
        const data = await AV.Media.saveFiles(AV.Media.getFormData(files), currentUser?.region);
        setMedia((prev) => [ ...prev, ...data ]);
        data?.forEach((img) => {
            if (img.type.startsWith('image')) {
                setImages((images) => [ ...images, img ]);
            }
            else if (img.type.startsWith('application')) {
                setDocs((images) => [ ...images, img ])
            }
            else setvideos((videos) => [ ...videos, img ]);
        });
        setNoticeMessage(null);
        setIsImageProcessing(false);
        fileInput.value = null;
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleImages = async (e : any) => {
        const fileInput : any = imageInputRef.current;
        let files : any = Array.from(e?.target?.files);
        const updatedFiles = files.map((file : any) => {
            const fileName = file.name;
            if (fileName.toLowerCase().endsWith('.jfif')) {
                const updatedFileName = fileName.replace(/\.\w+$/, '.jpg');
                const updatedFile = new File([ file ], updatedFileName, { type: "image/jpeg" });
                return updatedFile;
            }
            else {
                return file;
            }
        });
        files = updatedFiles;
        setShowAddMedia(false);
        files.forEach((img : any) => {
            if (
                img.type !== 'image/jpeg' &&
                img.type !== 'image/jfif' &&
                img.type !== 'image/png' &&
                img.type !== 'image/webp' &&
                img.type !== 'image/gif' &&
                img.type !== 'video/mp4' &&
                !img?.type?.startsWith('application')

            ) {
                setError(`${ img.name } ${ Loc.currentLang.postPopup.formatIsUnsupportedOnlyJpegJfifPngWebpGifAreAllowed }`);
                files = files.filter((item : any) => item.name !== img.name);
                return;
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(img);
                reader.onload = (readerEvent) => {
                    /*setImages((images) => [ ...images, readerEvent.target.result ]);*/
                };
            }
        });
        setIsImageProcessing(true);
        setNoticeMessage(Loc.currentLang.postPopup.pleaseWaitWhileWeProcessYourImages);
        const data = await AV.Media.saveFiles(AV.Media.getFormData(files), currentUser?.region);
        setMedia((prev) => [ ...prev, ...data ]);
        data?.forEach((img) => {
            if (img.type.startsWith('image')) {
                setImages((images) => [ ...images, img ]);
            }
            else if (img.type.startsWith('application')) {
                setDocs((images) => [ ...images, img ])
            }
            else setvideos((videos) => [ ...videos, img ]);
        });
        setNoticeMessage(null);
        setIsImageProcessing(false);
        fileInput.value = null;
    };

    const handleUpdatePost = async (e : any) => {
        let reloading = null;
        setIsLoading(true);
        for (var i = 0; i < deletepostlist.length; i++) {
            let FK : any = {
                pk: deletepostlist[ i ].pk, id: deletepostlist[ i ].id, region: deletepostlist[ i ].region, type: deletepostlist[ i ].type
            };
            await Post.delete(FK);
        };
        let media : any = [];
        images?.forEach((ele) => {
            media.push(ele);
        });
        videos?.forEach((ele) => {
            media.push(ele);
        });
        docs.forEach((ele) => {
            media.push(ele);
        } );
        links.forEach( ( ele ) =>
        {
            media.push( ele );
        } );
        e.preventDefault();
        let gallery = { layout: null, Items: [] };
        let galleryArray : any = [];
        item.gallery = gallery;
        item.text = text;
        item.owner = AV.AVEntity.getFk(item.owner);
        item.visibility = visibility == Loc.currentLang.menu.followers ? "Follow" : visibility;
        if ( !item?.media || media.length === 0 )
            item.media = null;
        if (media && media.length === 1) {
            item.media = media[ 0 ];
        }
        if (media && media.length > 1) {
            let galleryPost : any = [];
            item.media = null;
            for (var i = 0; i < media.length; i++) {
                let galleryPostlist : any = item.galleryPost?.filter((x) => x.media.id == media[ i ].id);
                if (galleryPostlist?.length === 0 || galleryPostlist === undefined) {
                    let param1 : AV.IFeedParam = {
                        feed: AVBase.getFk(item.owner),
                        startDate: new Date(0),
                        forward: true
                    };
                    if (param1?.feed?.id) {
                        let post1 : any = { pk: param1?.feed?.id, region: param1?.feed?.region };
                        post1.visibility = visibility == Loc.currentLang.menu.followers ? "Follow" : visibility;
                        post1.parentPost = item.id;
                        post1.visibility = visibility == Loc.currentLang.menu.followers ? "Follow" : visibility;
                        post1.media = media[ i ];
                        post1.ptype = "G";
                        let childresponse = await Post.save(post1);
                        dispatch(setisActive(true));
                        galleryArray.push(childresponse.id);
                        galleryPost.push(childresponse);
                    }
                } else {
                    galleryArray.push(item.galleryPost?.filter((x) => x.media.id == media[ i ].id)[ 0 ].id);
                    galleryPost.push(item.galleryPost?.filter((x) => x.media.id == media[ i ].id)[ 0 ]);
                }
            }
            item.gallery.Items = galleryArray;
            item.galleryPost = [];
            let val : any = await AV.Post.save(item);
            dispatch(setisActive(true));
            item.galleryPost = galleryPost;
            val.galleryPost = galleryPost;
            if (item?.children?.length > 0)
                val.children = item.children;
            if (item?.impressions?.length > 0)
                val.impressions = item.impressions;
            setsaveitem(val);
            await FeedsUpdate(val);
            setIsLoading(false);
            setSuccessMessage(Loc.currentLang.postPopup.yourPostHasBeenPublished);
            setShowCreatePost(false);
            return;
        }

        let val : any = await AV.Post.save(item);
        dispatch(setisActive(true));
        if (val) {
            if (item?.children?.length > 0 ? true : false)
                val.children = item.children;
            if ( item?.impressions?.length > 0 ? true : false )
                val.impressions = item.impressions;
            if ((val?.galleryPost?.length == 0 || val?.galleryPost?.length == undefined) || (val?.media?.id?.trim()?.length == 0 || val?.media?.id?.trim()?.length == undefined)) {
                setShowDetails(false);
            }
            setsaveitem(val);
            setItem( val );
            let feeds = userFeeds.filter( x => x.id == val.id );
            if ( feeds?.length > 0 )
            {
                feeds = userFeeds?.filter( x => x?.id !== val?.id );
                dispatch( setFeeds( feeds ) );
            }
            else
            {
                const updatedParentPost = await replaceChildrenInParent( val?.id, val?.parentPost, userFeeds, val );
                let exciting = userFeeds?.filter( x => x.id == updatedParentPost?.id );
                userFeeds[ userFeeds.indexOf( exciting[ 0 ] ) ] = updatedParentPost
                dispatch( setFeeds( userFeeds ) );
                setMedia( [] );
                setIsLoading( false );
                setSuccessMessage(Loc.currentLang.postPopup.yourPostHasBeenPublished);
                setShowCreatePost( false );
                return
            }
            feeds.unshift( val );
            dispatch( setFeeds( feeds ) );
            dispatch( setFeedsUpdate( true ) );
        }
        setMedia([]);
        setIsLoading(false);
        setSuccessMessage(Loc.currentLang.postPopup.yourPostHasBeenPublished);
        setShowCreatePost(false);
    };

    const FeedsUpdate = async (val) => {
        setItem(val);
        let feeds = userFeeds.filter(x => x.id == val.id);
        if (feeds?.length > 0) {
            userFeeds[ userFeeds.indexOf(feeds[ 0 ]) ] = val
        }
        else {
            const updatedParentPost = await replaceChildrenInParent(val?.id, val?.parentPost, userFeeds, val);
            let exciting = userFeeds?.filter(x => x.id == updatedParentPost?.id);
            userFeeds[ userFeeds.indexOf(exciting[ 0 ]) ] = updatedParentPost
        }
        dispatch(setFeeds(userFeeds));
        dispatch(setFeedsUpdate(true));
    }

    const replaceChildrenInParent = (postId, parentpostId, nestedData, newChildren) => {
        for (const item of nestedData) {
            if (item.id === parentpostId) {
                if (!item?.children)
                    item.children = [];
                let val = item.children.filter(x => x?.id == postId);
                if (val?.length > 0)
                    item.children[ item.children.indexOf(val[ 0 ]) ] = newChildren
                else
                    item.children.push(newChildren);
                return item;
            }

            if (item.children && item.children.length > 0) {
                // Continue searching in children
                const updatedChild = replaceChildrenInParent(postId, parentpostId, item.children, newChildren);
                if (updatedChild) {
                    return item; // Return the parent with updated children
                }
            }
            if (item?.galleryPost && item.galleryPost.length > 0) {
                // Continue searching in children
                const updatedChild = replaceChildrenInParent(postId, parentpostId, item.galleryPost, newChildren);
                if (updatedChild) {
                    return item; // Return the parent with updated children
                }
            }

        }
        return null; // Parent post not found
    };

    const handleDeleteImage = async (media) => {
        //if (saveitem.media?.id == media.id) {
        //    saveitem.media = null;
        //    await Post.save(saveitem);
        //    dispatch(setisActive(true));
        //} 
            let deletepost : any = item?.galleryPost?.filter((x) => x?.media?.id == media?.id);
            if (deletepost?.length > 0)
                deletepostlist.push(deletepost[ 0 ]);
        
        await handlecancelimage(media);
    };

    const handlecancelimage = (img) => {
        setMedia(media.filter((f) => f != img));
        setDocs(docs.filter( ( f ) => f != img ) )
        setLinks(links.filter( ( f ) => f != img ) )
        setImages(images.filter((f) => f != img));
        setvideos(videos.filter((f) => f != img));
    };

    const handeEmojiPicker = (e) => {
        const ref : any = textEditRef.current;
        ref.focus();
        const start = text?.substring(0, ref.selectionStart);
        const end = text?.substring(ref.selectionStart);
        const newText = start + e?.native + end;
        setText(newText);
        setPicker(!picker);
        const cursor = start.length + e?.native.length;

        ref.setSelectionRange(cursor, cursor)
    }

    const Grid2styles = StyleSheet.create({
        container: { flex: 1, flexDirection: 'row', flexWrap: 'wrap', minwidth: '120px' },
        img1: {
            backgroundColor: 'lightblue',
            borderColor: '#fff',
            borderWidth: 1,
            width: '340px',
            height: 230,

        },
    });

    const Grid1styles = StyleSheet.create({
        container: { flex: 1, flexDirection: 'row', flexWrap: 'wrap', minwidth: '120px' },
        vid1: {
            backgroundColor: 'black',
            borderColor: '#fff',
            borderWidth: 1,
            width: '340px',
            height: 'auto',
        },
    } );
    const HandleAddLinkToMedia = async () =>
    {
        setAddLinkLoad( true )
        //let newLink = externalLink;
        let getExternalLinkType = "application/octet-stream";
        //const youtubeUrl = isYouTubeURL( newLink );
        //const huddleUrl = isHuddleURL( newLink );
        //if ( youtubeUrl )
        //{
        //    newLink = youtubeUrl;
        //} else if ( huddleUrl )
        //{
        //    newLink = huddleUrl;
        //} else
        //{
        //    getExternalLinkType = getContentTypeFromUrl( newLink );
        //    console.log( getExternalLinkType );
        //}
        const isValidLink = /^(https?|ftp):\/\/[a-zA-Z0-9.-]+(\.[a-zA-Z]{2,})?(:\d+)?(\/\S*)?$/;
        if ( !isValidLink?.test( externalLink ) )
        {
            let clientError = { message: "Invalid link format." };
            CustomToast( { clientError } ); // Display error message using CustomToast
            setAddLinkLoad( false );
            setExternalLink( '' );
            setShowExternalLinkInput( false )
            return; // Exit the function early if the link format is invalid
        }
        let fd = new FormData();
        fd.append( "linkURL", externalLink );
        const data = await AV.Media.saveFiles( fd, currentUser?.region );
        data[ 0 ].linkURL = externalLink;
        data[ 0 ].orgFile = "";
        data[ 0 ].type = getExternalLinkType;
        setAllMedia( prev => [ ...prev, ...data ] );
        //post.media = data[ 0 ];
        if ( data?.length > 0 )
        {
            data.forEach( img =>
            {
                setLinks( link => [ ...link, img ] );
            } );
        }
        setAddLinkLoad( false );
        setExternalLink( '' );
        setShowExternalLinkInput( false );
    }

    const menuRefs = useRef(null);

    useOutsideAlerter(menuRefs);
    function useOutsideAlerter (ref) {
        React.useEffect(() => {
            function handleClickOutside (event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsActive(false);
                    setPicker(false);
                }
            }
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ ref ]);
    }


    const handleExternalLink = e =>
    {
        setExternalLink( e.target.value );
    };

    React.useEffect(() => {
        let img : any = [];
        let videoUrl : any = [];
        let docsUrl : any = [];
        let linkUrl : any = [];
        if (item?.media?.type?.startsWith('image')) img.push(item.media);
        else if (item?.media?.type?.startsWith('video')) videoUrl.push(item.media);
        else if
            ( item?.media?.type?.startsWith( 'application' && !item?.media?.linkURL ) ) docsUrl.push( item.media );
        else if ( item?.media?.linkURL ) links?.push( item.media )


        item?.galleryPost?.map((item1) => {
            if (item1?.media?.type?.startsWith('image')) img.push(item1.media);
            else if (item1?.media?.type?.startsWith('video')) videoUrl.push(item1.media);
            else if ( item1?.media?.type?.startsWith( 'application' ) && !item?.media?.linkURL ) docsUrl.push( item1.media );
            else if ( item?.media?.linkURL ) links?.push( item.media )
        });
        setImages(img);
        setvideos(videoUrl);
        setDocs( docsUrl );
        setLinks( links )
        let MediaAll = [ ...img, ...videoUrl, ...docsUrl, ...links ]
        setAllMedia( MediaAll )
        if ( img.length > 0 || videoUrl.length > 0 || docs.length > 0 || links?.length > 0) {
            setShowEditFilePicker(true);
        }
    }, []);

    React.useEffect(() => {
        if (textEditRef.current && !isTrue) {
            textEditRef.current.focus();
            textEditRef.current.setSelectionRange(text?.length, text?.length);
        }
        setIsTrue(true)
    }, [ showModal ]);

    return (
        <>
            { !showExternalLinkInput && (
                <Box
            position={ 'fixed' }
            top={ 0 }
            bottom={ 0 }
            left={ 0 }
            right={ 0 }
            bgColor={ "rgba(0, 0, 0, 0.25)" }
            zIndex={ 99999 }
        >
            <div
                className={ `create_post_popup ${ mobile && "create_post_popup_mobile" }` }
                style={ {
                    width: mobile ? dimensions.width - 15 : "631px",
                    maxHeight: mobile ? "650px" : showExternalLinkInput && allMedia?.length == 0 ? "465px" : allMedia?.length > 0 && !showExternalLinkInput ? "500px" : showExternalLinkInput && allMedia?.length > 0 ? "525px" : "405px",
                    padding: "24px",
                } }>
                { picker && (
                    <Box position={ 'absolute' } ref={ menuRefs } zIndex={ 99 } borderColor={ "#2FA3BE" } borderStyle={ "solid" } borderWidth={ "5px" } width={ mobile ? '85%' : '88%%' }>
                        <AVEmojiPicker onEmojiSelect={ (e) => { return handeEmojiPicker(e); } } />
                    </Box>
                ) }
                <VStack>
                    <HStack
                        alignItems={ 'center' }
                        justifyContent={ 'space-between' }
                    >
                        <Text fontSize={ 24 } > { item?.parentPost ? Loc.currentLang.post.editComment : Loc.currentLang.post.editPost }</Text>
                        <AVCancel callback={ setShowCreatePost } color={ undefined } size={ undefined } />
                    </HStack>
                    <ScrollView showsHorizontalScrollIndicator={ false } maxHeight={ mobile ? '300px' : '380px' }>
                        { item?.parentPost === undefined && <HStack marginTop={ '30px' } space={ 3 } >
                            <Text fontSize={ '18' }>{ Loc.currentLang.menu.whoCanView }:</Text>
                            <HStack
                                ref={ menuRefs }
                            >
                                <HStack>
                                    <Button
                                        width={ '248px' }
                                        bgColor={ 'rgba(0, 166, 188, 0.15)' }
                                        rounded='full'
                                        fontWeight='550px'
                                        color='#fff'
                                        justifyContent={ 'flex-start' }
                                        minHeight='40px'
                                        marginTop={ '-5px' }
                                        borderRadius={ '20px' }
                                        borderColor={ 'AVColor.primary' }
                                        onPress={ () => {
                                            setIsActive(!isActive)
                                        } }
                                    >
                                        <Text color={ 'rgba(0, 167, 188, 1)' } font-size={ '18px' } lineHeight={ '1rem' } paddingLeft={ '18px' } >{item.visibility == "Follow" ? "Followers" : item.visibility}</Text>
                                    </Button>
                                    <Pressable position={ 'absolute' } right={ '12px' } top={ '10px' } onPress={ () =>
                                    {
                                        setIsActive( !isActive )
                                    } } >
                                        <DropdownIcon height={ '9' } width={ '16' } />
                                    </Pressable>
                                </HStack>
                                <HStack>
                                    { isActive && ( <HStack position={ 'absolute' } right={ 0 } top={ '39px' } flexDirection={ 'column' } width={ '240px' } borderRadius={ '5px' } bgColor='#FFFFFF' shadow='9' rounded='lg' zIndex={ 100 } >
                                        <Button onPress={ () => { item.visibility = "Private"; setvisibility( "Private" ); setIsActive( !isActive ); } } bgColor='#FFFFFF' height='30px' justifyContent={ 'start' } _hover={ { backgroundColor: 'AVColor.hovercolor' } } width={ '240px' } ><Text color={ 'black' } >{ Loc.currentLang.menu.private }</Text></Button>
                                        <Button onPress={ () => { item.visibility = "Public"; setvisibility( "Public" ); setIsActive( !isActive ); } } bgColor='#FFFFFF' height='30px' justifyContent={ 'start' } _hover={ { backgroundColor: 'AVColor.hovercolor' } } width={ '240px' }><Text color={ 'black' }>{ Loc.currentLang.menu.public }</Text></Button>
                                        <Button onPress={ () => { item.visibility = "Followers"; setvisibility( "Followers" ); setIsActive( !isActive ); } } bgColor='#FFFFFF' height='30px' justifyContent={ 'start' } _hover={ { backgroundColor: 'AVColor.hovercolor' } } width={ '240px' }><Text color={ 'black' } >{ Loc.currentLang.menu.followers }</Text></Button>
                                    </HStack>
                                    ) }
                                </HStack>
                            </HStack>
                        </HStack> }
                        <div>
                            <Box
                                marginTop={ '24px' }
                                height={ '174px' }
                                borderWidth={ 1 }
                                borderRadius={ 5 }
                                overflow={ 'hidden' }
                                borderColor={ 'AVColor.grey' }
                                zIndex={ isActive ? '-1' : '0' }
                            >
                                <textarea
                                    ref={ textEditRef }
                                    value={ text }
                                    maxLength={ 1000 }
                                    style={ {
                                        border: 'none',
                                        resize: 'none',
                                        height: mobile ? '20px' : '95px',
                                        padding: '20px 15px',
                                        outline: 'none',
                                        fontFamily: 'inherit',
                                        fontSize: 'inherit',
                                    } }
                                    placeholder={ Loc.currentLang.post.createPostPlaceholder }
                                    onChange={ (e) => setText(e.target.value) }
                                    onKeyDown={ (e) => {
                                        if (e.key === 'Enter' && !e.shiftKey && !isMobile) {
                                            e.preventDefault();
                                            handleUpdatePost(e);
                                        }
                                    } }
                                    autoFocus
                                ></textarea>

                                <Text textAlign={ 'right' }>
                                    <AVEmoji showEmoji={ () => setPicker((prev) => !prev) } />
                                </Text>
                            </Box>
                            <HStack my={ 3 } justifyContent={ 'center' } width={ '85%' } space={ 4 } alignItems={ "center" } alignSelf={ 'center' } zIndex={ 0 }>
                                <RenderImages
                                    setLinks={ setLinks }
                                    links={ links }
                                    images={ images }
                                    setImages={ setImages }
                                    setvideos={ setvideos }
                                    videos={ videos }
                                    setMedia={ setMedia }
                                    media={ media }
                                />
                            </HStack>
                        </div>
                    </ScrollView>
                    { (alertMessage || successMessage) && (
                        <HStack alignItems={ 'center' } justifyContent={ 'center' }>
                            <Text fontSize={ 16 } color={ alertMessage ? 'danger.600' : 'success.600' }>
                                { alertMessage || successMessage }
                            </Text>
                        </HStack>
                    ) }
                    { isOpenBox && ( <VStack
                        ref={ menuRefs }
                        width={ '170px' }
                        position={ 'absolute' }
                        bottom={ '60px' }
                        left={ '20px' }
                        height={ '100px' }
                        shadow={ 2 }
                        background={ 'white' }
                        zIndex={ 999 }
                        borderRadius={ '6px' }
                    >
                        <HStack space={ '40px' } margin={ '24px' }>
                            <Pressable onPress={ () =>
                            {
                                setIsOpenBox( false );
                                imageInputRef?.current?.click();
                            } }>
                                <VStack space={ 3 }>
                                    <img src={ photos } height={ '32px' } width={ '32px' } />
                                            <Text>{ Loc.currentLang.postPopUp.photos }</Text>
                                </VStack>
                            </Pressable>
                            <Pressable onPress={ () => { setIsOpenBox( false ); setShowExternalLinkInput( !showExternalLinkInput ); setExternalLink( '' ); } }>
                                <VStack space={ 3 }>
                                    <img src={ Url } height={ '32px' } width={ '32px' } />
                                            <Text>{ Loc.currentLang.postPopUp.url }</Text>
                                </VStack>
                            </Pressable>
                        </HStack>
                    </VStack> ) }
                    <input
                        type='file'
                        accept='image/jpeg,image/jfif,image/png,image/webp,image/gif,video/mp4,'
                        multiple
                        hidden
                        ref={ imageInputRef }
                        onChange={ handleImages }
                    />
                    <Pressable
                        position={ 'absolute' } bottom={ 0 }
                        onPress={ () => setIsOpenBox( !isOpenBox ) }
                        _hover={ { bgColor: 'gray.100' } }
                        borderRadius={ '10px' }
                        margin={ '3px' }
                    >
                        <HStack space={ 3 } >
                            <Button
                                padding={ '15px' }
                                rounded={ 'full' }
                                _hover={ {
                                    opacity: 0.8
                                } }
                                onPress={ () => setIsOpenBox( !isOpenBox ) }
                                backgroundColor={ "AVColor.primary" }
                            >
                                { !isOpenBox ? <AddIcon size={ '15px' } color='AVColor.white' /> :
                                    <CloseIcon size={ '15px' } color='AVColor.white' /> }
                            </Button>
                            <Text alignSelf={ 'center' } fontSize={ '18px' } fontWeight={ 400 }>{ Loc.currentLang.post.addToYourPost }</Text>
                        </HStack>
                    </Pressable>
                    <HStack justifyContent={ 'flex-end' } mt={ 4 } space={ 3 } zIndex={-1}>
                        <>
                                    <Button height={ '40px' } width={ '180px' } backgroundColor={ ' rgba(238, 238, 238, 1)' } borderRadius={ "full" } onPress={ () => setShowCreatePost(false) } ><Text fontWeight={ 400 } color={ 'rgba(0, 0, 0, 0.5)' } fontSize={ '16px' }><Text fontWeight={ 400 } color={ 'rgba(0, 0, 0, 0.5)' } fontSize={ '16px' }>{ Loc.currentLang.postPopup.cancel }</Text></Text></Button>
                            <Button
                                height={ '40px' } width={ '180px' } backgroundColor={ "AVColor.primary" } borderRadius={ "full" } 
                                isDisabled={ ( ( text?.trim()?.length === 0 || text?.trim()?.length === undefined ) && images?.length === 0 && videos?.length === 0) }
                                onPress={ handleUpdatePost }
                                isLoading={ isLoading || isImageProcessing }
                                        isLoadingText={ isImageProcessing ? Loc.currentLang.postPopup.processing : Loc.currentLang.postPopup.publishingYourPost }><Text color={ 'white' } fontWeight={ 400 } fontSize={ '16px' }>{ Loc.currentLang.postPopup.publish }</Text></Button>
                        </>
                    </HStack>
                </VStack>
            </div>
                </Box> ) }
            { showExternalLinkInput && ( <Box position='fixed' bgColor={ 'AVColor.overlay2' } bottom={ 0 } right={ 0 } top={ 0 } left={ 0 } zIndex={ 99999 }>
                <div
                    className={ `create_post_popup ${ isMobile && 'create_post_popup_mobile' }` }
                    style={ {
                        width: isMobile ? dimensions.width - 15 : '565px',
                        height: isMobile ? '650px' : '240px',
                        marginTop: '12px',
                    } }
                >
                    <VStack width={ '100%' } padding={ '24px' }>
                        <HStack justifyContent={ 'space-between' } width={ '100%' } alignItems={ 'center' }
                            marginTop={ '20px' }
                            borderBottomColor={ 'AVColor.grey' }>
                            <Text fontSize={ '24px' }>{ Loc.currentLang.postPopup.addPostUrl }</Text>
                            <AVCancel callback={ () => { setShowExternalLinkInput( false ) } } color={ undefined } size={ undefined } />

                        </HStack>
                        <HStack width={ '100%' } marginTop={ '24px' }>
                            <Box width={ '95%' } justifyContent="center">
                                <AVInputtext
                                    type={ 'text' }
                                    label={ Loc.currentLang.postPopup.addLink }
                                    maxLength={ 50 }
                                    onChange={ handleExternalLink }
                                    defaultValue={ undefined }
                                    width={ undefined } value={ externalLink }
                                    height={ '20px' }
                                    minRows={ undefined }
                                    multiline={ undefined }
                                    size={ undefined }
                                    placeholder={ undefined }
                                />

                            </Box>
                        </HStack>
                        <HStack justifyContent={ 'center' } space={ '24px' } marginTop={ '24px' }>
                            <Button
                                width={ '180px' }
                                height={ '40px' }
                                backgroundColor={ 'AVColor.primary' }
                                rounded={ 'full' }
                                isDisabled={ externalLink?.trim().length === 0 }
                                isLoading={ addLinkLoad }
                                onPress={ () =>

                                    HandleAddLinkToMedia() }
                            >
                                <Text fontWeight={ 500 } color={ 'white' } >{ Loc.currentLang.menu.ok }</Text>
                            </Button>
                        </HStack>
                    </VStack>
                </div>
            </Box> ) }
        </>
    );
};

const RenderImages = ( { images, setImages, videos, setvideos, setMedia, media, links, setLinks } ) =>
{
    const Grid2styles = StyleSheet.create( {
        container: { flex: 1, flexDirection: "row", flexWrap: "wrap" },
        img1: {
            borderColor: "#fff",
            borderWidth: 1,
            width: 260,
            height: 160,
        },
    } );
    const handlecancelimage = img =>
    {
        setMedia( media.filter( f => f.id !== img.id ) );
        setImages( images.filter( f => f.id !== img.id ) );
        setvideos( videos.filter( f => f.id !== img.id ) );
        setLinks( links?.filter( f => f.id !== img.id ) );
    };

    return (
        <>
            <div className="add_pics_inside1 p0">
                { ( images.length > 0 || images.length > 0 || links?.length > 0 ) && (
                    <HStack alignContent={ "center" } alignSelf={ "center" } justifyContent={ "center" } space={ 3 }>
                        { ( images.length > 0 || videos.length > 0 || links?.length > 0 ) && (
                            <Box style={ Grid2styles.container }>
                                { images.length > 0 &&
                                    images?.slice( 0, 20 ).map( ( media, i ) => (
                                        <HStack style={ Grid2styles[ "img1" ] } key={ media.id + i }>
                                            <Pressable
                                                position={ "absolute" }
                                                background={ "rgba(151, 151, 151, 0.85)" }
                                                borderRadius={ "50px" }
                                                top={ '12px' }
                                                right={ '12px' }
                                                padding={ '10px' }
                                                zIndex={ 4 }
                                                onPress={ () =>
                                                {
                                                    handlecancelimage( media );
                                                } }>
                                                <CloseIcon color={ "black" } size={ '12px' } />
                                            </Pressable>
                                            <img width={ 260 } height={ 160 } style={ { borderRadius: 10 } } src={ Media.getURL( media ) } key={ media.id + i } alt="" />
                                        </HStack>
                                    ) ) }
                                { videos.slice( 0, 20 ).map( ( vid, i ) => (
                                    <Box key={ i }>
                                        <HStack style={ Grid2styles[ "img1" ] } key={ vid + i }>
                                            <Pressable
                                                position={ "absolute" }
                                                background={ "rgba(151, 151, 151, 0.85)" }
                                                borderRadius={ "50px" }
                                                top={ '12px' }
                                                right={ '12px' }
                                                padding={ '10px' }
                                                zIndex={ 4 }
                                                onPress={ () =>
                                                {
                                                    handlecancelimage( vid );
                                                } }>
                                                <CloseIcon color={ "black" } size={ '12px' } />
                                            </Pressable>
                                        </HStack>
                                        <HStack position={ "absolute" } top={ "35%" } left={ "35%" }>
                                            <VideoIcon size={ "60px" } color="white" />
                                        </HStack>

                                        <img width={ 260 } height={ 160 } src={ Media.getVideoPicURL( vid ) } key={ vid?.id + i } alt="" />
                                    </Box>
                                ) ) }
                                { links.slice( 0, 20 ).map( ( vid, i ) => (
                                    <Box key={ i }>
                                        <HStack style={ Grid2styles[ "img1" ] } key={ vid + i }>
                                            <Pressable
                                                position={ "absolute" }
                                                background={ "rgba(151, 151, 151, 0.85)" }
                                                borderRadius={ "50px" }
                                                top={ '12px' }
                                                right={ '12px' }
                                                padding={ '10px' }
                                                zIndex={ 4 }
                                                onPress={ () =>
                                                {
                                                    handlecancelimage( vid );
                                                } }>
                                                <CloseIcon color={ "black" } size={ '12px' } />
                                            </Pressable>
                                            <LinkPreview url={ vid.linkURL } imageHeight={ '60px' } width={ '260px' } height={ '160' } />
                                        </HStack>
                                    </Box>
                                ) ) }
                            </Box>
                        ) }
                    </HStack>
                ) }
            </div>
        </>
    );
};

export default EditPostpopup;
