import { Post, IPost, Convo } from '../AVCore/AVCore';
import * as React from 'react';
import { Person } from '../AVCore/Person';
import * as AV from '../AVCore/AVCore';
import { setIsConvoScreenOpen, setLastFeedUpdate, setisActive, setConvoOpen } from '../Redux/Actions/userActions';
import { setConvo } from '../Redux/Actions/convoActions';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { Server } from '../Platform/Server';
import { Alert } from 'AVCore/Alert';
import { setAlerts } from '../Redux/Actions/alertActions';
import _ from 'lodash';
/*import useInitSSE from '../Hooks/useInitSSE';*/
import { SSE } from './SSE';
import { store } from '../Redux/store';
import { Loc } from '../App/Loc';
import { AVBase } from 'AVCore/AVBase';
import { SignalRService } from 'AVCore/SignalR';
import useConvoList from '../Hooks/useConvoList';


const useConvoChat = () =>
{
    let textSearchRef = React.useRef();
    const dispatch = useDispatch();
    let userconvo = useSelector( ( state : RootStateOrAny ) => state.convo.convo[ 0 ] );
    const useralertList = useSelector( ( state : RootStateOrAny ) => state?.alerts.alert[ 0 ] );
    const currentUser = useSelector( ( state : RootStateOrAny ) => state?.user?.user[ 0 ] );
    const lastUpdateFeed = useSelector( ( state : RootStateOrAny ) => state.user.lastUpdateFeed );
    const [ mdlCreateConvoVisible, setMdlCreateConvoVisible ] = React.useState( false );
    const [ showFeedbackImpressions, setShowFeedbackImpressions ] = React.useState( false );
    const [ toFK, settoFK ] = React.useState<any>( [] );
    const [ subject, setSubject ] = React.useState( '' );
    const [ message, setMessage ] = React.useState( '' );
    const [ searchInput, setSearchInput ] = React.useState( '' );
    const [ filteredResults, setFilteredResults ] = React.useState<any[]>();
    const [ showSearch, setShowSearch ] = React.useState( false );
    const [ showFilePicker, setShowFilePicker ] = React.useState( false );
    const [ mdlConvoVisible, setMdlConvoVisible ] = React.useState( false );
    const [ convoSubject, setConvoSubject ] = React.useState( '' );
    const [ convoPost, setConvoPost ] = React.useState<IPost[]>( [] );
    const [ convo, setConvos ] = React.useState<any>( [] );
    const [ showConvoFilePicker, setShowConvoFilePicker ] = React.useState( false );
    const [ media, setMedia ] = React.useState<any>( [] );
    const [ gallery, setGallery ] = React.useState( [] );
    const [ chatMessage, setChatMessage ] = React.useState( '' );
    const [ value, setValue ] = React.useState<any>();
    const [ commentValue, setCommentValue ] = React.useState( '' );
    const [ showEmoji, setShowEmoji ] = React.useState( false );
    const [ videoUrls, setvideos ] = React.useState<any>( [] );
    const [ Imagesurl, setImagesurl ] = React.useState<any>( [] );
    const [ isCommentLoading, setIsCommentLoading ] = React.useState( false );
    const [ isLoading, setConvoLoading ] = React.useState( false );
    const [ Loading, setLoading ] = React.useState( false );
    const [ showAddMedia, setShowAddMedia ] = React.useState( false );
    const [ picker, setPicker ] = React.useState( false );
    const [ isImageProcessing, setIsImageProcessing ] = React.useState<any>( null );
    const [ noticeMessage, setNoticeMessage ] = React.useState<any>( '' );
    const [ images, setImages ] = React.useState<any>( [] );
    const [ isDelete, setIsdelete ] = React.useState<any>( false );
    const [ videos, setvideoss ] = React.useState( [] );
    const usernewalert = useSelector( ( state : RootStateOrAny ) => state?.alerts?.newalert[ 0 ] );
    const [ profile, Setprofile ] = React.useState<any>();
    const [ isHide, setIsHide ] = React.useState( false );
    const [ removePeople, setremovePeople ] = React.useState<any>( [] );
    const [ replyValue, setreplyValue ] = React.useState<any>( '' );
    const [ currentconvochat, setcurrentconvochat ] = React.useState<any>();
    const refConvoList : any = React.useRef( null );
    const [ itemEdit, setItemEdit ] = React.useState<any>( [] );
    const [ editingImage, setEditingImage ] = React.useState( false );
    const userisactive = useSelector( ( state : RootStateOrAny ) => state?.user?.active );
    const convoval = useSelector( ( state : RootStateOrAny ) => state.convo.convo[ 0 ] );
    const [ fetchloading, setFetchLoading ] = React.useState( false );
    const [ pendingMessages, setPendingMessages ] = React.useState<any>( [] );
    const [ alertMessage, setAlertMessage ] = React.useState( '' );
    const imageInputRef = React.useRef<any>( null );
    const [ updateConvoImage, setUpdateConvoImage ] = React.useState<any>( [] );
    const [ updateConvoVideo, setUpdateConvoVideo ] = React.useState<any>( [] );
    const [ isTyping, setIsTyping ] = React.useState( false );
    const [ typingUserName, setTypingUserName ] = React.useState( '' );
    const [ isConvo, setIsConvo ] = React.useState<any>( false );

    //const { StartSSE } = useInitSSE();

    const handleImages = async ( e : any ) =>
    {
        setAlertMessage( '' );
        const fileInput : any = imageInputRef.current;
        setLoading( true );
        let files : any = Array.from( e?.target?.files );
        const updatedFiles = files.map( ( file : any ) =>
        {
            const fileName = file.name;
            if ( fileName.toLowerCase().endsWith( '.jfif' ) )
            {
                const updatedFileName = fileName.replace( /\.\w+$/, '.jpg' );
                const updatedFile = new File( [ file ], updatedFileName, { type: "image/jpeg" } );
                return updatedFile;
            }
            else
            {
                return file;
            }
        } );
        files = updatedFiles;
        let m = '';
        console.log( files );
        if ( files.length > 20 )
        {
            m =
                Loc.currentLang?.alertMessages?.maxUploadFiles ||
                'You cannot upload more than 20 files per post';
            setAlertMessage( m );
            return;
        }
        setShowAddMedia( false );
        setPicker( false );
        files.forEach( ( img : any ) =>
        {
            if (
                img.type !== 'image/jpeg' &&
                img.type !== 'image/jfif' &&
                img.type !== 'image/png' &&
                img.type !== 'image/webp' &&
                img.type !== 'image/gif' &&
                img.type !== 'video/mp4' &&
                img.type !== 'video/quicktime'
            )
            {
                m =
                    Loc.currentLang.alertMessages.supportedFormats ||
                    'format is unsupported ! only Jpeg,Jfif, Png, Webp, Gif are allowed.';
                files = files.filter( ( item : any ) => item.name !== img.name );
                return;
            } else
            {
                const reader = new FileReader();
                reader.readAsDataURL( img );
                reader.onload = ( readerEvent : any ) =>
                {
                    setImages( ( images ) => [ ...images, readerEvent.target.result ] );
                }
            }
        } );
        setIsImageProcessing( true );
        setNoticeMessage( 'Please wait while we process your images...' );
        const data = await AV.Media.saveFiles( AV.Media.getFormData( files ), currentUser?.region );
        setMedia( ( prev ) => [ ...prev, ...data ] );
        data?.forEach( ( img ) =>
        {
            if ( img.type.startsWith( 'image' ) )
            {
                setImagesurl( ( imagesurl ) => [ ...imagesurl, img ] );
                setUpdateConvoImage( ( imagesurl ) => [ ...imagesurl, img ] )
            }

            else
            {
                setvideos( ( videos : any ) => [ ...videos, img ] );
                setUpdateConvoVideo( ( videos : any ) => [ ...videos, img ] )
            }
        } );
        setNoticeMessage( null );
        setLoading( false );
        setIsImageProcessing( false );
        // Reset the input value
        fileInput.value = null;
    };

    const handleCreateModel = async () =>
    {
        setValue( '' );
        settoFK( [] );
        setSearchInput( '' );
        setSubject( '' );
        setIsHide( false );
        setMdlCreateConvoVisible( !mdlCreateConvoVisible );
        setShowConvoFilePicker( false )
        setMdlConvoVisible( false );
    };

    const searchItems = async ( searchValue ) =>
    {
        setSearchInput( searchValue );


        if ( searchValue !== '' )
        {
            var searchData = currentUser?.follows;
            for ( var i = 0; i < toFK?.length; i++ )
            {
                searchData = searchData.filter( ( x ) => x?.id !== toFK[ i ]?.id );
            }
            setFilteredResults(
                searchValue?.trim()?.length > 0
                    ? searchData?.filter(
                        ( x ) =>
                            x.type === 'Person' &&
                            x?.id !== currentUser?.id &&

                            x?.title?.toLocaleLowerCase().includes( searchValue.toLocaleLowerCase() )
                    )
                    : searchData
            );
            setShowSearch( true );
        } else
        {
            setFilteredResults( [] );
            setShowSearch( false );
        }
    };

    const onEmojiClick = ( emoji : any ) =>
    {
        const newVal = value === undefined ? emoji : value + emoji;
        setValue( newVal );
        setMessage( message + newVal );
        setShowEmoji( false );
        setValue( '' );
    };

    const handleSentToProfile = ( item ) =>
    {
        setShowSearch( false );
        setSearchInput( '' );
        settoFK( ( toFK ) => [ ...toFK, item ] );
    };

    const handleSubject = ( e ) =>
    {
        e.preventDefault();
        setSubject( e.target.value );
    };

    const handleMessage = ( e ) =>
    {
        setValue( e.target.value );
        setMessage( e.target.value );
    };

    const handleRemoveItem = ( item ) =>
    {
        settoFK( toFK.filter( ( x ) => x.id !== item.id ) );
        if ( convo )
        {
            let remove = convo?.people?.filter( ( x ) => x.id == item.id );
            if ( remove?.length > 0 ) setremovePeople( ( prev ) => [ ...( prev || [] ), ...( remove || [] ) ] );
        }
    };

    const handlePublish = async () =>
    {
        setConvoLoading( true );
        let people : any = toFK?.filter(
            ( item1 : { id : any } ) => !convo?.people?.some( ( item2 : { id : any } ) => item2?.id === item1?.id )
        );
        let returnvalue : any;
        if ( removePeople?.length > 0 )
        {
            returnvalue = await AV.Convo.removePeople( convo, removePeople );
        }
        if ( people?.length > 0 )
        {
            returnvalue = await AV.Convo.addPeople( convo, people );
        }
        if ( convo?.subject !== subject )
        {
            convo.subject = subject
            var test : any = AVBase.getFk( convo );
            test.subject = subject;
            returnvalue = await AV.Convo.update( test );
            setConvoSubject( test?.subject );
        }
        if ( returnvalue?.people )
        {
            const updatedConvoList = userconvo.map( ( conversation ) =>
            {
                if ( conversation?.id === returnvalue?.id )
                {
                    conversation.people = returnvalue?.people;
                    conversation.lastUpdated = returnvalue.lastUpdated
                    return {
                        ...conversation
                    };
                }
                return conversation;
            } );
            dispatch( setConvo( updatedConvoList ) );
        }
        else
        {
            setIsHide( false );
            setMdlConvoVisible( true );
            setConvoLoading( false );
            return;
        }
        setConvos( returnvalue )
        setremovePeople( [] )
        setConvoLoading( false );
        setIsHide( false );
        setMdlConvoVisible( true );


    };

    const getImageGalleryFromFilePickerCreateConvo = async ( filePickerData : any ) =>
    {
        if ( filePickerData.length > 0 )
        {
            if ( filePickerData.length > 20 )
            {
                setMessage( 'You cannot upload more than 20 files per post!' );
                return;
            }
            const data = await AV.Media.saveFiles(
                AV.Media.getFormData( filePickerData ),
                currentUser?.region
            );
            if ( data != null ) setMedia( data );
            data?.forEach( ( img ) =>
            {
                if ( img.type.startsWith( 'image' ) ) setImages( ( images ) => [ ...images, img ] );
                else setvideos( ( videos ) => [ ...videos, img ] );
            } );
            setMedia( [] );
            setImages( [] );
        }
    };

    const handleSendMessage = async () =>
    {
        setConvoLoading( true );
        let people : any = [];
        let self = Person.self();
        people = toFK;
        let convo : any = {
            subject: subject,
            ownerType: 'Person',
            type: 'Convo',
            people: people,
        };
        var excitingconvo = userconvo?.filter(
            ( x ) =>
                x?.people?.length === 2 &&
                ( x?.people[ 0 ]?.id == toFK[ 0 ]?.id || x?.people[ 1 ]?.id == toFK[ 0 ]?.id )
        );
        if ( !subject && excitingconvo?.length > 0 )
        {
            convo = excitingconvo[ 0 ];
        } else
        {
            convo = await Convo.save( convo );
        }


        let post : any = Convo.createConvoPost( convo );
        post.owner = AV.AVEntity.getFk( convo );
        post.text = value;
        if ( media && media.length === 0 )
        {
            post = await AV.Post.saveConvo( post );
        }
        if ( media && media.length === 1 )
        {
            post.media = media[ 0 ];
            post = await AV.Post.saveConvo( post );
        }
        if ( media && media.length > 1 )
        {
            let galleryArray : string[] = [];
            let galleryPost : any = [];
            let gallery = { layout: null, Items: [] };
            post.gallery = gallery;
            const newGuid = Server.generateUUID();
            post.id = newGuid;
            let parentPostId = newGuid;
            for ( var i = 0; i < media?.length; i++ )
            {
                let post1 = AV.Convo.createConvoPost( convo );
                post1.owner = AV.AVEntity.getFk( convo );
                post1.parentPost = parentPostId;
                post1.media = media[ i ];
                post1.ptype = "G";
                let mediapost : any = await AV.Post.saveConvo( post1 );
                dispatch( setisActive( true ) );
                galleryPost.push( mediapost );
                galleryArray.push( mediapost.id );
            }
            post.status = 'New';
            post.gallery.items = galleryArray;
            post = await Post.saveConvo( post );
            dispatch( setisActive( true ) );
            post.galleryPost = galleryPost;
            post = post;
        }
        if ( post )
        {
            let postdata = userconvo?.filter( ( x ) => x?.id == post?.pk );
            if ( postdata?.length > 0 )
            {
                postdata[ 0 ].posts.push( post );
                postdata[ 0 ].lastUpdated = post.lastUpdated;
                let userconv = userconvo.filter( ( x ) => x.id != post.pk );
                userconv.unshift( postdata[ 0 ] );
                console.log( "setconvo sse4" )
                dispatch( setConvo( userconv ) );
            } else
            {
                let usercon : any = [];
                if ( userconvo?.length > 0 ) usercon = userconvo;
                convo.posts = [];
                convo.posts?.push( post );
                usercon.unshift( convo );
                console.log( "setconvo useconvo 89" )
                dispatch( setConvo( usercon ) );
            }
        }
        setValue( '' );
        setSearchInput( '' );
        setImages( [] );
        setGallery( [] );
        setMedia( [] );
        setImagesurl( [] );
        setConvoLoading( false );
        handleGetConvo( convo );
        setShowConvoFilePicker( false );

    };
    const handleSendSingleChat = async ( toFK ) =>
    {
        setConvoLoading( true );
        let people : any = [];
        let self = Person.self();
        people = toFK;
        let convo : any = {
            subject: subject,
            ownerType: 'Person',
            type: 'Convo',
            people: people,
        };
        var excitingconvo = userconvo?.filter(
            ( x ) =>
                x?.people?.length === 2 &&
                ( x?.people[ 0 ]?.id == toFK[ 0 ]?.id || x?.people[ 1 ]?.id == toFK[ 0 ]?.id )
        );
        if ( !subject && excitingconvo?.length > 0 )
        {
            convo = excitingconvo[ 0 ];
        } else
        {
            convo = await Convo.save( convo );
        }
        if ( excitingconvo?.length == 0 )
        {
            let post : any = Convo.createConvoPost( convo );
            //post.owner = AV.AVEntity.getFk( convo );
            // post.text = "Hello";
            post = await AV.Post.saveConvo( post );
            if ( post )
            {
                let postdata = userconvo?.filter( ( x ) => x?.id == post?.pk );
                if ( postdata?.length > 0 )
                {
                    postdata[ 0 ].posts.push( post );
                    postdata[ 0 ].lastUpdated = post.lastUpdated;
                    let userconv = userconvo.filter( ( x ) => x.id != post.pk );
                    userconv.unshift( postdata[ 0 ] );
                    dispatch( setConvo( userconv ) );
                } else
                {
                    let usercon : any = [];
                    if ( userconvo?.length > 0 ) usercon = userconvo;
                    convo.posts = [];
                    convo.posts?.push( post );
                    usercon.unshift( convo );
                    dispatch( setConvo( usercon ) );
                }
            }
        }
        setValue( '' );
        setSearchInput( '' );
        setImages( [] );
        setGallery( [] );
        setMedia( [] );
        setImagesurl( [] );
        setConvoLoading( false );
        handleGetConvo( convo );
        setShowConvoFilePicker( false );

    };
    async function updateUnreadMessages ( item )
    {
        let id = item?.data?.feed?.id ? item.data.feed.id : item?.id;
        const UnReadChatMessage = useralertList?.filter( ( x : any ) => x?.actionTaken == undefined && x?.data?.feed?.id === id && ( x?.alertType === "NewDM" || x?.alertType === "ReplyDM" ) );

        if ( UnReadChatMessage?.length > 0 )
        {
            for ( const message of useralertList )
            {
                if ( message?.data?.feed?.id === id && message?.actionTaken !== "Viewed" && ( message?.alertType === "NewDM" || message?.alertType === "ReplyDM" ) )
                {
                    message.actionTaken = 'Viewed';
                }
            }
            dispatch( setAlerts( useralertList ) );
            for ( let i = 0; i < UnReadChatMessage.length; i++ )
            {
                await Alert.Save( UnReadChatMessage[ i ] );
            }
        }
    }

    const handleGetConvo = async ( item ) =>
    {
        // setup the signalRconnection after the chat screen opened
        setupSignalRConnection( item?.id );
        //(await SSE()).SSEupdate()
        setConvoLoading( false );
        setLoading( false );
        setIsImageProcessing( false );
        setMdlConvoVisible( true );
        setMedia( [] );
        let convoPosts : any = '';
        setImages( [] );
        setShowConvoFilePicker( false )
        updateUnreadMessages( item );
        setIsHide( false );
        setreplyValue( '' );

        if ( item?.data?.feed?.id )
        {
            convoPosts = userconvo?.filter( ( x ) => x?.id == item?.data?.feed?.id )[ 0 ];
            setcurrentconvochat( item?.data?.feed );
        } else
        {
            convoPosts = userconvo?.filter( ( x ) => x?.id == item?.pk )[ 0 ];
            setcurrentconvochat( item );
        }

        if ( !convoPosts ) convoPosts = item;
        setConvos( convoPosts );

        let post = convoPosts?.posts?.sort( ( a, b ) =>
        {
            return ( new Date( b.createDate ) as any ) - ( new Date( a.createDate ) as any );
        } );
        setConvoPost( list_to_tree( post ) );
        setConvoSubject( convoPosts?.subject );
        const feedParam : AV.IFeedParam = {
            feed: { id: item?.id, pk: item?.id, region: currentUser?.region, type: "Convo" },
            startDate: new Date( 0 ),
            forward: true,
        };
        if ( item?.type !== 'GroupConvo' )
        {
            var userPeople = convoPosts?.people?.filter(
                ( x ) => convoPosts?.people?.length === 2 && x.id !== currentUser?.id
            );
            Setprofile( userPeople[ 0 ] );
            let profilename = convoPosts?.posts?.filter( ( x ) => x?.person?.id == userPeople[ 0 ]?.id );
            profilename = profilename?.sort( function ( a, b )
            {
                let c : any = new Date( b.lastUpdated );
                let d : any = new Date( a.lastUpdated );
                return c - d;
            } );
            Setprofile( profilename[ 0 ]?.person ? profilename[ 0 ]?.person : userPeople[ 0 ] );
        }
        setMdlCreateConvoVisible( true );
        //Start SSE on convo screen open
        console.log( "Call Start SSE function..." );
        //if ( !Server.SSE )
        //{
        //    console.log( "Start New SSE..." );
        //    await ( await SSE() ).SSEupdate();
        //}
        let setConvoScreenOpen = { isOpen: true, convoId: item.pk ? item.pk : item?.data?.feed?.pk }
        dispatch( setIsConvoScreenOpen( setConvoScreenOpen ) );
        setMdlConvoVisible( true );
        setMedia( [] );
    };

    const HandleMessaging = () =>
    {
        setIsConvo( true )
    }

    const handleGetGroupConvo = async ( item ) =>
    {
        dispatch( setConvoOpen( true ) )
        //setShowConvoListModal( !showConvoListModal )
        // setup the signalRconnection after the chat screen opened
        setupSignalRConnection( item?.id );
        //(await SSE()).SSEupdate()
        setConvoLoading( false );
        setLoading( false );
        setIsImageProcessing( false );
        setMdlConvoVisible( true );
        setMedia( [] );
        let convoPosts : any = '';
        setImages( [] );
        setShowConvoFilePicker( false )
        updateUnreadMessages( item );
        setIsHide( false );
        setreplyValue( '' );
        let isAlive = userconvo?.length > 0 && userconvo?.find( x => x?.id == item?.id ) ? true : false
        if ( !isAlive )
        {
            if ( userconvo?.length > 0 )
            {
                userconvo?.unshift( item )
            }
            else
            {
                userconvo = [];
                userconvo?.unshift( item )
                console.log( "setConvo useconvochat 66" )
                dispatch( setConvo( userconvo ) );
                setIsConvo( true )

            }
        }
        convoPosts = userconvo?.filter( ( x ) => x?.id == item?.id )[ 0 ];
        setcurrentconvochat( item );
        if ( !convoPosts )
            convoPosts = item;
        setConvos( convoPosts );
        setConvoSubject( convoPosts?.subject );

        var target : any = AV.AVEntity.getFk( item );
        Setprofile( target );
        setMdlCreateConvoVisible( true );
        //Start SSE on convo screen open
        console.log( "Call Start SSE function..." );
        if ( !Server.SSE )
        {
            console.log( "Start New SSE..." );
            await ( await SSE() ).SSEupdate();
        }
        let setConvoScreenOpen = { isOpen: true, convoId: item.pk ? item.pk : item?.data?.feed?.pk }
        dispatch( setIsConvoScreenOpen( setConvoScreenOpen ) );
        setMdlConvoVisible( true );
        setMedia( [] );
    };

    const scrollToMessageWithDelay = ( item, convoPosts ) =>
    {
        setTimeout( () => scrollToMessageById( item?.target?.id, convoPosts?.posts ), 200 );
    };

    const scrollToMessageById = ( messageId, post ) =>
    {
        if ( messageId && post?.length > 0 )
        {
            const index = post?.findIndex( ( item ) => item.id === messageId );
            try
            {
                if ( index !== -1 && refConvoList.current )
                {
                    // Use type assertion here
                    ( refConvoList.current as any ).scrollToIndex( { index, animated: true } );
                }
            } catch ( error )
            {
                console.error( "An error occurred while scrolling:", error );
            }
        }
    };

    const list_to_tree = ( list ) =>
    {
        var map = {},
            node : any,
            roots : any = [],
            i;

        for ( i = 0; i < list?.length; i += 1 )
        {
            map[ list[ i ]?.id ] = i; // initialize the map
            if ( list[ i ]?.children?.length === 0 || !list[ i ]?.children ) list[ i ][ 'children' ] = []; // initialize the children
            if ( list[ i ]?.galleryPost?.length === 0 || !list[ i ]?.galleryPost ) list[ i ][ 'galleryPost' ] = [];
            list[ i ][ 'ReplyPost' ] = [];
        }

        for ( i = 0; i < list?.length; i += 1 )
        {
            node = list[ i ];
            if ( node.parentPost !== undefined )
            {
                if (
                    list[ map[ node.parentPost ] ]?.gallery?.items?.filter( ( x ) => x === node.id )?.length > 0 &&
                    node.gallery == undefined
                )
                {
                    let excitingpost = list[ map[ node.parentPost ] ]?.galleryPost?.filter(
                        ( x ) => x?.id === node?.id
                    );
                    if ( excitingpost?.length === 0 || excitingpost === undefined )
                        list[ map[ node.parentPost ] ]?.galleryPost.push( node );
                } else
                {
                    let PPost = list.filter( ( x ) => x?.id === node?.parentPost );
                    node.ReplyPost.push( PPost[ 0 ] );
                    roots.push( node );
                }
            } else
            {
                roots.push( node );
            }
        }
        return roots;
    };

    const HandleEditSet = ( item ) =>
    {
        setEditingImage( true )
        setValue( item?.text );
        setItemEdit( item )
        if ( item?.media?.type?.trim()?.length > 0 || item?.galleryPost?.length > 0 )
        {
            setShowConvoFilePicker( true )
        }
    }

    const handleUpdatePost = async ( itemEdit ) =>
    {
        setConvoLoading( true );
        let media : any = [];
        updateConvoImage?.forEach( ( ele : any ) => { media.push( ele ); } );
        updateConvoVideo?.forEach( ( ele : any ) => { media.push( ele ); } );
        let gallery = { layout: null, Items: [] };
        let galleryArray : string[] = [];
        itemEdit.gallery = gallery;
        itemEdit.text = value;
        itemEdit.media = null;
        if ( media && media.length === 1 )
        {
            itemEdit.media = media[ 0 ];
        }
        let galleryPost : any = [];
        if ( media && media?.length > 1 )
        {
            for ( var i = 0; i < media?.length; i++ )
            {
                let galleryPostlist = itemEdit?.galleryPost?.filter( ( x : any ) => x?.media?.id == media[ i ]?.id );
                if ( galleryPostlist?.length === 0 || galleryPostlist === undefined )
                {
                    let param1 : AV.IFeedParam = {
                        feed: AV.AVBase.getFk( itemEdit.owner ),
                        startDate: new Date( 0 ), forward: true
                    };
                    if ( param1?.feed?.id )
                        var post1 : any = { pk: param1?.feed?.id, region: param1?.feed?.region };
                    post1.parentPost = itemEdit.id;
                    post1.owner = itemEdit.owner;
                    post1.media = media[ i ];
                    post1.ptype = "G";
                    let childresponse : any = await Post.saveConvo( post1 );

                    galleryArray.push( childresponse?.id );
                    galleryPost.push( childresponse );
                }
                else
                {
                    let val = itemEdit?.galleryPost?.filter( ( x : any ) => x?.media?.id == media[ i ].id );
                    if ( val?.length > 0 )
                    {
                        galleryArray.push( val[ 0 ]?.id );
                        galleryPost.push( val[ 0 ] )
                    }
                }
            }
            itemEdit.gallery.Items = galleryArray;
        }
        let val : any = await Post.save( itemEdit );
        if ( val?.id )
        {
            if ( galleryPost?.length > 0 ) val.galleryPost = galleryPost;
            if ( itemEdit?.children?.length > 0 )
                val.children = itemEdit.children;
            if ( itemEdit?.impressions?.length > 0 )
                val.impressions = itemEdit.impressions;
            itemEdit.galleryPost = galleryPost;
            let postdata = userconvo?.find( ( x : any ) => x?.id === val?.pk );
            let findIndex = postdata.posts?.findIndex( ( x ) => x?.id == val?.id )
            postdata.posts[ findIndex ] = val
            postdata.lastUpdated = val?.lastUpdated;
            let userconv = [ postdata, ...userconvo?.filter( ( x : any ) => x?.id !== val?.pk ) ];
            console.log( "setconvo convo 21" )
            dispatch( setConvo( userconv ) );
        }
        setItemEdit( [] )
        if ( showFilePicker ) setShowFilePicker( !showFilePicker );
        if ( showEmoji ) setShowEmoji( !showEmoji );
        setValue( '' )
        setMedia( [] );
        setvideos( [] );
        setImages( [] );
        setMessage( '' );
        setCommentValue( '' );
        handleGetConvo( itemEdit );
        setEditingImage( false )
        setConvoLoading( false );
        setEditingImage( false )
    };

    const handleconvoApiUpdate = async ( post : any, temporaryId : any ) =>
    {
        dispatch( setisActive( true ) );
        post = await AV.Post.saveConvo( post );
        // Update the UI and Redux with the actual response
        if ( post )
        {
            // Remove the processed message from pendingMessages array
            setPendingMessages( prevPendingMessages =>
                prevPendingMessages.filter( ( msg : any ) => msg.temporaryId !== temporaryId )
            );

            let postdata = userconvo.find( x => x.id == post.pk );
            if ( postdata.posts?.length == 0 || postdata.posts?.length == undefined )
            {
                postdata.posts = [];
                postdata.posts?.unshift( post );
            }
            else
            {
                postdata.posts = postdata.posts.map( p =>
                    p.temporaryId === temporaryId ? post : p
                );
                postdata.lastUpdated = post.lastUpdated;
            }
            let userconv = userconvo.filter( x => x.id != post.pk );
            userconv.unshift( postdata );
            handleGetConvo( postdata );
            setConvoPost( postdata.posts );
            console.log( "setConvo useconvochat 13" )
            dispatch( setConvo( userconv ) );
        }
    }

    const handleReduxUpdate = async ( post : any, newPostData : any ) =>
    {
        // Immediately update UI with the new message (placeholder)
        let postdata = userconvo.find( x => x.id == post.pk );
        if ( postdata.posts?.length == 0 || postdata.posts?.length == undefined )
            postdata.posts = [];
        postdata.posts.unshift( newPostData );
        postdata.lastUpdated = new Date().toISOString();
        let userconv = userconvo.filter( x => x.id != post.pk );
        userconv.unshift( postdata );
        setConvoPost( postdata.posts );
        console.log( "setConvo useconvochat 11" )
        dispatch( setConvo( userconv ) );
        dispatch( setisActive( true ) );

    }

    const handleSendConvoMessage = async ( item ) =>
    {
        setConvoLoading( true );
        // Scroll to the bottom of the FlatList
        if ( refConvoList.current )
        {
            refConvoList.current.scrollToOffset( { animated: true, offset: 0 } );
        }
        let post : any = Convo.createConvoPost( item );
        post.owner = AV.AVEntity.getFk( item );
        post.text = value;
        if ( replyValue?.id )
            post.parentPost = replyValue.id;
        post.person = AV.AVEntity.getFk( currentUser );
        var clientPost = post;
        if ( media && media.length === 0 && !replyValue?.id )
        {
            const temporaryId = new Date().getTime() + Math.random();
            const newPostData = {
                ...post,
                status: 'pending',
                temporaryId: temporaryId, // Temporary ID to track the post
            };
            setPendingMessages( prevPendingMessages => [ ...prevPendingMessages, newPostData ] );
            handleconvoApiUpdate( post, temporaryId );
            handleReduxUpdate( post, newPostData );
            dispatch( setisActive( true ) );

        }
        if ( media && media.length === 0 && replyValue?.id )
        {
            //post = await AV.Post.saveConvo(post);
            //if (post) {
            //    let postdata = userconvo.filter((x) => x.id == post.pk)[ 0 ];
            //    postdata.posts.push(post);
            //    postdata.lastUpdated = post.lastUpdated;
            //    let userconv = userconvo.filter((x) => x.id != post.pk);
            //    userconv.unshift(postdata);
            //    setConvoPost(postdata.posts)
            //    dispatch(setConvo(userconv));
            //}
            //dispatch(setisActive(true));
            const temporaryId = new Date().getTime() + Math.random();
            const newPostData = {
                ...post,
                status: 'pending',
                temporaryId: temporaryId, // Temporary ID to track the post
            };
            setPendingMessages( prevPendingMessages => [ ...prevPendingMessages, newPostData ] );
            handleconvoApiUpdate( post, temporaryId );
            handleReduxUpdate( post, newPostData );
            dispatch( setisActive( true ) );
        }

        if ( media && media.length == 1 )
        {
            post.media = media[ 0 ];
            const temporaryId = new Date().getTime() + Math.random();
            const newPostData = {
                ...post,
                status: 'pending',
                temporaryId: temporaryId, // Temporary ID to track the post
            };
            setPendingMessages( prevPendingMessages => [ ...prevPendingMessages, newPostData ] );
            handleconvoApiUpdate( post, temporaryId );
            handleReduxUpdate( post, newPostData );
            dispatch( setisActive( true ) );
        }
        if ( media && media.length > 1 )
        {
            let galleryArray : string[] = [];
            let galleryPost : any = [];
            let gallery = { layout: null, Items: [] };
            post.gallery = gallery;
            const newGuid = Server.generateUUID();
            post.id = newGuid;
            let parentPostId = newGuid;

            const mediaPromises = media.map( ( mediaItem ) =>
            {
                let post1 = AV.Convo.createConvoPost( item );
                post1.owner = AV.AVEntity.getFk( item );
                post1.parentPost = parentPostId;
                post1.media = mediaItem;
                post1.ptype = "G";
                return AV.Post.saveConvo( post1 ).then( ( mediapost : any ) =>
                {
                    dispatch( setisActive( true ) );
                    galleryPost.push( mediapost );
                    galleryArray.push( mediapost.id );
                } );
            } );

            await Promise.all( mediaPromises );
            post.status = 'New';
            post.gallery.items = galleryArray;
            const savedPost = await Post.saveConvo( post ); // Handle the final post save with a promise
            dispatch( setisActive( true ) );
            post = savedPost;
            post.galleryPost = galleryPost;
            if ( post?.id )
            {
                let postdata = userconvo?.find( ( x : any ) => x?.id === post?.pk );
                if ( postdata?.id )
                {
                    postdata.posts = [ post, ...postdata.posts ];
                    postdata.lastUpdated = post?.lastUpdated;
                    let userconvCopy = [ ...userconvo ];
                    const index = userconvCopy?.findIndex( ( x : any ) => x?.id === post?.pk );
                    if ( index !== -1 )
                    {
                        userconvCopy[ index ] = { ...userconvCopy[ index ], posts: postdata.posts, lastUpdated: post?.lastUpdated };
                        console.log( "setConvo useconvochat 10" )
                        dispatch( setConvo( userconvCopy ) );
                    }
                }
            }
        }
        setreplyValue( '' );
        setGallery( [] );
        setMedia( [] );
        setImages( [] );
        setImagesurl( [] );
        setChatMessage( '' );
        setValue( '' );
        setMedia( '' );
        handleGetConvo( item );
        setShowConvoFilePicker( false );
        setConvoLoading( false );
    };


    const handleChatMessage = ( e ) =>
    {
        // If the user is typing then don't invoke the typing event
        if ( !isTyping )
        {
            SignalRService.sendTyping( currentconvochat?.id )
        }
        console.log( e.target.value )
        setValue( e.target.value );
        setChatMessage( e.target.value );
    };


    //const handleChatMessagse = useCallback( ( e ) =>
    //{
    //    if ( !isTyping )
    //    {
    //        SignalRService.sendTyping( currentconvochat?.id );
    //    }
    //    setValue( e.target.value );
    //    setChatMessage( e.target.value );
    //}, [ isTyping, currentconvochat?.id ] );


    const getImageGalleryFromFilePickerConvoChat = async ( filePickerData : any ) =>
    {
        if ( filePickerData.length > 0 )
        {
            if ( filePickerData.length > 20 )
            {
                return;
            }
            const data = await AV.Media.saveFiles(
                AV.Media.getFormData( filePickerData ),
                currentUser?.region
            );
            if ( data != null ) setMedia( ( prev ) => [ ...prev, ...data ] );
            data?.forEach( ( img ) =>
            {
                if ( img.type.startsWith( 'image' ) ) setImages( ( images ) => [ ...images, img ] );
                else setvideos( ( videos ) => [ ...videos, img ] );
            } );
        }
    };

    const handleFilePicker = () =>
    {
        setShowConvoFilePicker( !showConvoFilePicker );
        setEditingImage( !editingImage );
        setItemEdit( '' )
        setMedia( [] );
        setImages( [] );
        setImagesurl( [] );
    };

    const onRefresh = () =>
    {
        if ( convo?.privateIds?.length !== convo?.posts?.length && convo?.privateIds?.length > 0 )
        {

            let post = userconvo?.find( ( x : any ) => x?.id === convo?.id );
            let userPost = post.posts;
            let LastPostDate = userPost?.length > 0 ? userPost[ userPost.length - 1 ]?.lastUpdated : 0

            getMyFeeds( LastPostDate ).then( ( result : any ) =>
            {

                let allFeeds = userPost.concat( result );
                allFeeds = _.uniqBy( allFeeds, 'id' );
                allFeeds = allFeeds.sort( ( a : any, b : any ) =>
                {
                    return ( new Date( b.createDate ) as any ) - ( new Date( a.createDate ) as any );
                } )
                allFeeds = list_to_tree( allFeeds );
                post.posts = allFeeds
                setConvos( post );
                userconvo[ userconvo.indexOf( post ) ] = post
                console.log( "setConvo useconvochat 9" )
                dispatch( setConvo( userconvo ) );
                handleGetConvo( convo );
                setFetchLoading( false ); // Hide the loader
            } ).catch( () =>
            {
                setFetchLoading( false ); // Hide the loader in case of error
            } );
        }
    };

    const getMyFeeds = async ( lastUpdated : any ) =>
    {
        convo.privateIds = convo.privateIds?.filter( ( x : any ) => x?.date < lastUpdated );
        let PostList = await AV.Feed.getPostList( convo );
        if ( PostList?.length > 0 )
        {
            setFetchLoading( true );
            let Post : any = await AV.Feed.getPosts( AV.AVEntity.getFk( convo ), PostList.slice( 0, 25 ) );
            Post.sort( ( a : any, b : any ) =>
            {
                const createDateA = new Date( a.createDate ) as any;
                const createDateB = new Date( b.createDate ) as any;
                return createDateB - createDateA;
            } );
            for ( let i = 0; i < Post?.length; i++ )
            {
                if ( Post[ i ].posts?.length > 0 )
                {
                    var cmdPost : any = await AV.Feed.getPosts( AV.AVEntity.getFk( Post[ i ] ), Post[ i ]?.posts?.slice( 0, 2 ) );
                    if ( cmdPost?.length > 0 )
                        Post[ i ].children.push( cmdPost );
                }
            }
            return Post || [];
        }
    };
    // Function to set up the SignalR connection
    const setupSignalRConnection = async ( convoId ) =>
    {
        const connection = SignalRService.getConnection();
        if ( connection )
        {
            console.log( 'SignalR already connected..' );
            //return;
        }
        //const convoId = currentconvochat?.id;

        console.log( 'Join Group ConvoId:  ' + convoId );
        // Create a signalRConnection
        await SignalRService.createConnection();

        // Start the connection that have created
        await SignalRService.startSignalRConnection();

        // Add a group with the convoId and add the current user to the group
        await SignalRService.joinGroup( convoId );

        // Register the typing event handler
        if ( SignalRService.getConnection() )
        {
            SignalRService.getConnection()!.on( 'typing', ( receivedConvoId, userName ) =>
            {
                console.log( "Typing event received:", { receivedConvoId, userName } );

                // Update typing indicator if the event is for the current conversation and from a different user
                if ( receivedConvoId === convoId && currentUser.name !== userName )
                {
                    setTypingUserName( userName + 'is typing...' );
                    setIsTyping( true );
                    console.log( `${ userName } is typing in conversation ${ receivedConvoId }...` );

                    // Clear the typing indicator after 3 seconds
                    setTimeout( () =>
                    {
                        setTypingUserName( "" );
                        setIsTyping( false );
                    }, 6000 );
                }
            } );
            console.log( "SignalR is already connected. Registering 'Impression' event handler..." );

            SignalRService.getConnection()!.on( 'Impression', async ( data, alertType ) =>
            {
                console.log( "Impression event received:", data, alertType );
                await handleImpressionAlerts( data, alertType );
            } );

            SignalRService.getConnection()!.on( 'NewDM', async ( data, alertType ) =>
            {
                console.log( "NewDM event received", data, alertType );
                await handleNewDMAlertforSing( data, alertType )

            } );
            SignalRService.getConnection()!.on( 'ReplyDM', async ( data, alertType ) =>
            {
                console.log( "ReplyDM event received", data, alertType );
                await handleNewDMAlertforSing( data, alertType )

            } );
            SignalRService.getConnection()!.on( 'DeleteDM', async ( data, alertType ) =>
            {
                console.log( "Delete DM event received", data, alertType );
                await handleDeleteDMAlertforSigR( data, alertType )

            } );
        }
    };

    async function handleNewDMAlertforSing ( target, item )
    {
        console.log( "processing newDM alert in SSE....." )
        const reduxState : RootStateOrAny = store.getState();
        const { user, convo } = reduxState;
        const userconvo = convo.convo[ 0 ];
        const lastUpdateFeed = user.lastUpdateFeed;
        const feedId = item?.feed?.id;
        const postdata = userconvo?.find( ( x ) => x?.id === feedId );

        //Temp code

        const post = postdata?.posts?.find( ( x ) => x?.id === target?.id );

        let PostList : any = [];
        PostList[ 0 ] = target?.id;
        let response = userconvo?.find( ( x : any ) => x?.id === item?.feed?.id )
        if ( response )
        {
            await AV.Feed.getPosts( AV.AVEntity.getFk( response ), PostList?.slice( 0, 50 ) ).then( ( x : any ) =>
            {
                if ( x?.length > 0 )
                {
                    var usercon = userconvo || [];
                    let Index = response.posts?.findIndex( ( y ) => y.id == x[ 0 ]?.id )
                    if ( Index !== -1 )
                    {
                        // response.posts[ Index ] = x[0];
                        response.posts = response.posts?.filter( ( y ) => y.id !== x[ 0 ]?.id )
                        response.posts = list_to_tree( response?.posts )
                        response?.posts?.sort( ( a, b ) =>
                        {
                            return ( new Date( b.createDate ) as any ) - ( new Date( a.createDate ) as any );
                        } );
                        usercon = userconvo?.filter( ( x : any ) => x?.id != item?.data?.feed?.id )
                        usercon.unshift( response );
                        usercon = _.uniqBy( usercon, 'id' );
                        console.log( "handleNewDMAlertforSing 1" )
                        dispatch( setConvo( usercon ) );

                        //unshift the edited one
                        response.posts?.unshift( x[ 0 ] );
                        response.posts = list_to_tree( response?.posts )
                        response?.posts?.sort( ( a, b ) =>
                        {
                            return ( new Date( b.createDate ) as any ) - ( new Date( a.createDate ) as any );
                        } );
                        usercon = userconvo?.filter( ( x : any ) => x?.id != item?.feed?.id )
                        usercon.unshift( response );
                        usercon = _.uniqBy( usercon, 'id' );
                    }
                    else
                    {
                        response.posts?.unshift( x[ 0 ] );
                        response.posts = _.uniqBy( response?.posts, 'id' );
                        //response.lastUpdated = x[ 0 ].lastUpdated;
                        response.posts = list_to_tree( response?.posts )
                        response?.posts?.sort( ( a, b ) =>
                        {
                            return ( new Date( b.createDate ) as any ) - ( new Date( a.createDate ) as any );
                        } );
                        usercon = userconvo?.filter( ( x : any ) => x?.id != item?.feed?.id )
                        usercon.unshift( response );
                        console.log( "handleNewDMAlertforSing 2" )
                        usercon = _.uniqBy( usercon, 'id' );
                    }
                }
                dispatch( setConvo( usercon ) );
            } );
        }
        else
        {
            const feedParam : AV.IFeedParam = {
                feed: item?.feed,
                startDate: postdata?.posts?.length > 0 ? postdata?.lastUpdated : new Date( 0 ), forward: true
            };
            await AV.Feed.getFeed( feedParam ).then( async ( response : any ) =>
            {
                if ( response?.id )
                {
                    let PostList = await AV.Feed.getPostList( response );
                    PostList = PostList?.filter( item => item !== null );
                    if ( PostList.length > 0 )
                    {
                        await AV.Feed.getPosts( AV.AVEntity.getFk( response ), PostList ).then( ( x : any ) =>
                        {
                            if ( x?.length > 0 )
                            {
                                response.posts = x;
                                response.posts = list_to_tree( response?.posts )
                                var usercon = userconvo || [];
                                const existingconvo = usercon.find( ( x : any ) => x?.id === response?.id );
                                if ( !existingconvo )
                                {
                                    usercon.unshift( response );
                                }
                                console.log( "handleNewDMAlertforSing 3" )
                                dispatch( setConvo( usercon ) );
                            }
                        } );
                    }
                }
            } );
        }

        //Temporary code to update feeds in background
        const feedParam : AV.IFeedParam = {
            feed: item?.feed,
            startDate: postdata?.posts?.length > 0 ? postdata?.lastUpdated : new Date( 0 ), forward: true
        };
        await AV.Feed.getFeed( feedParam ).then( async ( response : any ) =>
        {
            if ( response?.id )
            {
                //set last successful get feed
                let data : any = {
                    Ifeed: feedParam,
                    lasttime: new Date()
                };
                let Index = lastUpdateFeed?.findIndex( ( x ) => x?.Ifeed?.feed?.id == item?.feed?.id )
                if ( Index !== -1 )
                {
                    lastUpdateFeed[ Index ] = data
                }
                dispatch( setLastFeedUpdate( lastUpdateFeed ) );
                let PostList = await AV.Feed.getPostList( response );
                PostList = PostList?.filter( item => item !== null );
                if ( PostList.length > 0 )
                {
                    await AV.Feed.getPosts( AV.AVEntity.getFk( response ), PostList ).then( ( x : any ) =>
                    {
                        if ( x?.length > 0 )
                        {
                            response.posts = x;
                            var usercon = userconvo || [];
                            const existingconvo = usercon.find( ( x : any ) => x?.id === response?.id );
                            if ( !existingconvo )
                            {
                                let user : any = AV.Person._self;
                                if ( !existingconvo )
                                {
                                    usercon.unshift( response );
                                }
                                else
                                    return;
                            } else
                            {
                                let updatedFeeds = [ ...( existingconvo?.posts || [] ) ];
                                response?.posts.forEach( ( newconvo : any ) =>
                                {
                                    let existingFeed = existingconvo?.posts?.find( ( x : any ) => x?.id === newconvo?.id );
                                    if ( !existingFeed )
                                    {
                                        updatedFeeds.unshift( newconvo );
                                    } else
                                    {
                                        existingFeed.impressions = newconvo.impressions;
                                        existingFeed.text = newconvo.text;
                                    }
                                } );
                                existingconvo.posts = _.uniqBy( [ ...updatedFeeds ], 'id' );
                                //existingconvo.lastUpdated = response.lastUpdated;
                                existingconvo.people = response.people;
                                usercon.unshift( existingconvo );
                                usercon = _.uniqBy( usercon, 'id' );
                            }
                            console.log( "handleNewDMAlertforSing 5" )
                            dispatch( setConvo( usercon ) );
                        }
                    } );
                }
            }
            if (
                mdlCreateConvoVisible &&
                mdlConvoVisible &&
                currentconvochat?.id === item?.feed?.id
            )
            {
                handleGetConvo( item?.feed );
            }
        } );

        if (
            mdlCreateConvoVisible &&
            mdlConvoVisible &&
            currentconvochat?.id === item?.feed?.id
        )
        {
            handleGetConvo( item?.feed );
        }
    }


    async function handleDeleteDMAlertforSigR ( target, alert )
    {
        var currentChat = userconvo?.find( ( x : any ) => x?.id === alert.feed?.id );
        if ( currentChat )
        {
            currentChat.posts = currentChat?.posts?.filter( ( x : any ) => x?.id !== target?.id );
            userconvo[ userconvo.indexOf( currentChat ) ] = currentChat
            console.log( "setConvo useconvochat 3" )
            dispatch( setConvo( userconvo ) );
            handleGetConvo( currentChat );
        }
    }

    async function handleNewDMAlert ( item )
    {

        console.log( "processing newDM alert in SSE....." )
        const reduxState : RootStateOrAny = store.getState();
        const { user, convo } = reduxState;
        const userconvo = convo.convo[ 0 ];
        const lastUpdateFeed = user.lastUpdateFeed;
        const feedId = item?.data?.feed?.id;
        const postdata = userconvo?.find( ( x ) => x?.id === feedId );

        //Temp code

        const post = postdata?.posts?.find( ( x ) => x?.id === item?.target?.id );

        let PostList : any = [];
        PostList[ 0 ] = item?.target?.id;
        let response = userconvo?.find( ( x : any ) => x?.id === item?.data?.feed?.id )
        if ( response )
        {
            await AV.Feed.getPosts( AV.AVEntity.getFk( response ), PostList?.slice( 0, 50 ) ).then( ( x : any ) =>
            {
                if ( x?.length > 0 )
                {
                    var usercon = userconvo || [];
                    let Index = response.posts?.findIndex( ( y ) => y.id == x[ 0 ]?.id )
                    if ( Index !== -1 )
                    {

                        // response.posts[ Index ] = x[0];
                        response.posts = response.posts?.filter( ( y ) => y.id !== x[ 0 ]?.id )
                        response.posts = list_to_tree( response?.posts )
                        response?.posts?.sort( ( a, b ) =>
                        {
                            return ( new Date( b.createDate ) as any ) - ( new Date( a.createDate ) as any );
                        } );
                        usercon = userconvo?.filter( ( x : any ) => x?.id != item?.data?.feed?.id )
                        usercon.unshift( response );
                        usercon = _.uniqBy( usercon, 'id' );
                        dispatch( setConvo( usercon ) );

                        //unshift the edited one
                        response.posts?.unshift( x[ 0 ] );
                        response.posts = list_to_tree( response?.posts )
                        response?.posts?.sort( ( a, b ) =>
                        {
                            return ( new Date( b.createDate ) as any ) - ( new Date( a.createDate ) as any );
                        } );
                        usercon = userconvo?.filter( ( x : any ) => x?.id != item?.data?.feed?.id )
                        usercon.unshift( response );
                        usercon = _.uniqBy( usercon, 'id' );
                    }
                    else
                    {
                        response.posts?.unshift( x[ 0 ] );
                        response.posts = _.uniqBy( response?.posts, 'id' );
                        //response.lastUpdated = x[ 0 ].lastUpdated;
                        response.posts = list_to_tree( response?.posts )
                        response?.posts?.sort( ( a, b ) =>
                        {
                            return ( new Date( b.createDate ) as any ) - ( new Date( a.createDate ) as any );
                        } );
                        usercon = userconvo?.filter( ( x : any ) => x?.id != item?.data?.feed?.id )
                        usercon.unshift( response );
                        usercon = _.uniqBy( usercon, 'id' );
                    }
                }
                dispatch( setConvo( usercon ) );
            } );
        }
        else
        {
            const feedParam : AV.IFeedParam = {
                feed: item?.data?.feed,
                startDate: postdata?.posts?.length > 0 ? postdata?.lastUpdated : new Date( 0 ), forward: true
            };
            await AV.Feed.getFeed( feedParam ).then( async ( response : any ) =>
            {
                if ( response?.id )
                {
                    let PostList = await AV.Feed.getPostList( response );
                    PostList = PostList?.filter( item => item !== null );
                    if ( PostList.length > 0 )
                    {
                        await AV.Feed.getPosts( AV.AVEntity.getFk( response ), PostList ).then( ( x : any ) =>
                        {
                            if ( x?.length > 0 )
                            {
                                response.posts = x;
                                response.posts = list_to_tree( response?.posts )
                                var usercon = userconvo || [];
                                const existingconvo = usercon.find( ( x : any ) => x?.id === response?.id );
                                if ( !existingconvo )
                                {
                                    usercon.unshift( response );
                                }
                                dispatch( setConvo( usercon ) );
                            }
                        } );
                    }
                }
            } );
        }

        //Temporary code to update feeds in background
        const feedParam : AV.IFeedParam = {
            feed: item?.data?.feed,
            startDate: postdata?.posts?.length > 0 ? postdata?.lastUpdated : new Date( 0 ), forward: true
        };
        await AV.Feed.getFeed( feedParam ).then( async ( response : any ) =>
        {
            if ( response?.id )
            {

                //set last successful get feed
                let data : any = {
                    Ifeed: feedParam,
                    lasttime: new Date()
                };
                let Index = lastUpdateFeed?.findIndex( ( x ) => x?.Ifeed?.feed?.id == item?.feed?.id )
                if ( Index !== -1 )
                {
                    lastUpdateFeed[ Index ] = data
                }
                dispatch( setLastFeedUpdate( lastUpdateFeed ) );

                let PostList = await AV.Feed.getPostList( response );
                PostList = PostList?.filter( item => item !== null );
                if ( PostList.length > 0 )
                {
                    await AV.Feed.getPosts( AV.AVEntity.getFk( response ), PostList ).then( ( x : any ) =>
                    {
                        if ( x?.length > 0 )
                        {
                            response.posts = x;
                            var usercon = userconvo || [];
                            const existingconvo = usercon.find( ( x : any ) => x?.id === response?.id );
                            if ( !existingconvo )
                            {
                                let user : any = AV.Person._self;
                                if ( !existingconvo )
                                {
                                    usercon.unshift( response );
                                }
                                else
                                    return;
                            } else
                            {
                                let updatedFeeds = [ ...( existingconvo?.posts || [] ) ];
                                response?.posts.forEach( ( newconvo : any ) =>
                                {
                                    let existingFeed = existingconvo?.posts?.find( ( x : any ) => x?.id === newconvo?.id );
                                    if ( !existingFeed )
                                    {
                                        updatedFeeds.unshift( newconvo );
                                    } else
                                    {
                                        existingFeed.impressions = newconvo.impressions;
                                        existingFeed.text = newconvo.text;
                                    }
                                } );
                                existingconvo.posts = _.uniqBy( [ ...updatedFeeds ], 'id' );
                                //existingconvo.lastUpdated = response.lastUpdated;
                                existingconvo.people = response.people;
                                usercon.unshift( existingconvo );
                                usercon = _.uniqBy( usercon, 'id' );
                            }
                            dispatch( setConvo( usercon ) );
                        }
                    } );
                }
            }
            if (
                mdlCreateConvoVisible &&
                mdlConvoVisible &&
                currentconvochat?.id === usernewalert?.data.feed?.id
            )
            {
                handleGetConvo( usernewalert?.data?.feed );
            }
        } );

        if (
            mdlCreateConvoVisible &&
            mdlConvoVisible &&
            currentconvochat?.id === item?.data.feed?.id
        )
        {
            handleGetConvo( item?.data?.feed );
        }
    }


    const handleImpressionAlerts = async ( data, alertData ) =>
    {
        const userFeeds = userconvo?.find( ( feed ) => feed.id === data.pk );

        if ( userFeeds )
        {
            const userFeedsImpression = userFeeds.posts?.find(
                ( post ) => post.id === data.id
            );

            if ( userFeedsImpression )
            {
                if ( userFeedsImpression.impressions == undefined )
                {
                    userFeedsImpression.impressions = [];
                }
                // const Impression = data.impressions;
                let Impression : any = { ...await AV.AVEntity.getFk( data?.impressions?.[ 0 ] ) };
                if ( Impression !== undefined && Impression?.id?.trim()?.length > 0 )
                {
                    Impression.impression = data?.impressions?.[ 0 ]?.impression;
                    userFeedsImpression.impressions = userFeedsImpression.impressions?.filter(
                        ( impression ) => impression.id !== Impression.id );
                    userFeedsImpression.impressions.unshift( Impression );
                }
                else
                {
                    Impression = null;
                    userFeedsImpression.impressions = Impression;
                }


                const postIndex = userFeeds.posts.indexOf( userFeedsImpression );
                userFeeds.posts[ postIndex ] = userFeedsImpression;

                const convoIndex = userconvo?.indexOf( userFeeds );
                userconvo[ convoIndex ] = userFeeds;
                console.log( "setConvo useconvochat 2" )
                dispatch( setConvo( userconvo ) );

                //if ( mdlCreateConvoVisible && mdlConvoVisible && currentconvochat?.id === userFeeds.id )
                //{
                handleGetConvo( userFeeds );
                //}
            }
        }

    };

    React.useEffect( () =>
    {
        async function fetchData ()
        {
            const alertType = usernewalert?.alertType;
            if ( alertType === 'NewDM' || alertType === 'NewDM-Ward' || alertType === 'ReplyDM' || alertType === 'ReplyDM-Ward' )
            {
            } else if ( alertType === 'EntityUpdate' && usernewalert?.data === 'SelfConvoPost' )
                if ( userisactive )
                {
                    dispatch( setisActive( false ) );
                }
                else
                {
                    handleConvoUpdate();
                } else if ( alertType === 'Impression' )
                {
                    //await handleImpressionAlert();
                }
        }

        const handleConvoUpdate = async () =>
        {
            const lastConvo = userconvo?.find( ( x ) => x?.id === usernewalert?.target?.id );
            const feedParam : AV.IFeedParam = {
                feed: AV.AVEntity.getFk( usernewalert?.target ),
                startDate: lastConvo?.posts?.length > 0 ? lastConvo?.posts[ 0 ]?.lastUpdated : new Date( 0 ), forward: true,
            };
            await AV.Feed.getFeed( feedParam ).then( ( response : any ) =>
            {
                var usercon = userconvo || [];
                const excitingconvo = usercon.find( ( x ) => x?.id === response?.id );
                if ( !excitingconvo )
                {
                    usercon.unshift( response );
                } else
                {
                    excitingconvo.posts = _.uniqBy( [ ...excitingconvo?.posts, ...response?.posts ], 'id' );
                    excitingconvo.lastUpdated = response.lastUpdated;
                    usercon.unshift( excitingconvo );
                    usercon = _.uniqBy( usercon, 'id' );
                }
                console.log( "setConvo useconvochat 1" )
                dispatch( setConvo( usercon ) );
                if (
                    mdlCreateConvoVisible &&
                    mdlConvoVisible &&
                    currentconvochat?.id === usernewalert?.target?.id
                )
                {
                    handleGetConvo( usernewalert?.target );
                }
            } );
        };
        //async function handleImpressionAlert () {
        //    const userFeeds = userconvo?.find((feed) => feed.id === usernewalert?.target?.pk);

        //    if (userFeeds) {
        //        const userFeedsImpression = userFeeds.posts?.find(
        //            (post) => post.id === usernewalert?.target?.id
        //        );

        //        if (userFeedsImpression) {
        //            const Impression = { ...usernewalert?.data?.source };
        //            Impression.impression = usernewalert?.data?.type
        //            userFeedsImpression.impressions = userFeedsImpression.impressions?.filter(
        //                (impression) => impression.id !== Impression.id
        //            );
        //            userFeedsImpression.impressions.unshift(Impression);

        //            const postIndex = userFeeds.posts.indexOf(userFeedsImpression);
        //            userFeeds.posts[ postIndex ] = userFeedsImpression;

        //            const convoIndex = userconvo?.indexOf(userFeeds);
        //            userconvo[ convoIndex ] = userFeeds;

        //            dispatch(setConvo(userconvo));

        //            if (mdlCreateConvoVisible && mdlConvoVisible && currentconvochat?.id === userFeeds.id) {
        //                handleGetConvo(userFeeds);
        //            }
        //        }
        //    }
        //}

        fetchData();
    }, [ usernewalert ] );

    return {
        isImageProcessing,
        handleNewDMAlert,
        setMedia,
        textSearchRef,
        mdlCreateConvoVisible,
        setMdlCreateConvoVisible,
        toFK,
        searchInput,
        filteredResults,
        showSearch,
        showFilePicker,
        setShowFilePicker,
        handleCreateModel,
        searchItems,
        handleSentToProfile,
        handleSendMessage,
        handleSubject,
        handleMessage,
        handleRemoveItem,
        getImageGalleryFromFilePickerCreateConvo,
        mdlConvoVisible,
        setMdlConvoVisible,
        convoSubject,
        convoPost, setConvoPost,
        convo,
        profile,
        Setprofile,
        showConvoFilePicker,
        setShowConvoFilePicker,
        handleGetConvo,
        handleSendConvoMessage,
        handleChatMessage,
        getImageGalleryFromFilePickerConvoChat,
        value,
        setValue,
        onEmojiClick,
        showEmoji,
        handleImages,
        message,
        setMessage,
        setShowEmoji,
        isLoading,
        handleFilePicker,
        Loading,
        setLoading,
        images,
        videoUrls,
        setvideos,
        setImages,
        media,
        onRefresh,
        replyValue,
        setreplyValue,
        showFeedbackImpressions,
        setShowFeedbackImpressions,
        setSubject,
        settoFK,
        handlePublish,
        setIsHide,
        isHide,
        subject,
        refConvoList,
        setConvoLoading,
        HandleEditSet,
        handleUpdatePost,
        itemEdit,
        editingImage,
        setEditingImage,
        scrollToMessageWithDelay,
        fetchloading,
        setSearchInput,
        setShowSearch,
        imageInputRef,
        updateUnreadMessages,
        isDelete, setIsdelete,
        updateConvoImage, setUpdateConvoImage,
        updateConvoVideo, setUpdateConvoVideo,
        handleGetGroupConvo,
        handleSendSingleChat,
        isTyping,
        setConvoSubject,
        setConvos,
        typingUserName
    };
};

export default useConvoChat;
