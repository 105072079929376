import React from "react";
import Card from "../../components/posts/Card";
import { Box, Button, Center, Image, VStack, HStack } from "native-base";
import useGetFeeds from "../../Hooks/useGetFeeds";
import InfiniteScroll from "react-infinite-scroller";
import msg from "../../assets/images/create-message.png";
import CreatePostpopup from "Screens/Home/CreatePostpopup";
import { FeedsLoader, Loader } from "components/loaders";
import { useWindowDimension } from "Utility/WindowDimensions";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { setFeedOwners, setFeeds, setFetchProcess, setPostList, setRemainingPosts, setFeedsScrollUpdate, setFeedsUpdate, setRemainingEntities, setProcessedEntityIds } from '../../Redux/Actions/feedsAction';
import { Loc } from "../../App/Loc";
import { useParams } from "react-router-dom";
import { Feed } from "../../AVCore/Feed";
import { AVEntity } from "../../AVCore/AVEntity";
import _ from "lodash";
import AVSelectDropdown from "../../components/common/AVSelectDropdown";
import Offline from "react-offline";
function Feeds(props) {
    const [showModal, setShowModal] = React.useState(false);
    const feedsUpdate = useSelector((state: RootStateOrAny) => state.feeds.feedsUpdate);
    let remainingPosts = useSelector((state: RootStateOrAny) => state.feeds.remainingPosts);
    const feedsLoad = useSelector((state: RootStateOrAny) => state.user.feedsload);
    let userfeeds = useSelector((state: RootStateOrAny) => state.feeds.feeds[0]);
    const currentUser = useSelector((state: RootStateOrAny) => state.user.user[0]);
    const params = useParams();
    const userId = params?.userId
    const entityId = params?.entityId;
    const followersdatalist = useSelector((state: RootStateOrAny) => state?.user?.followersdatalist);
    const [currentstatus, setcurrentStatus] = React.useState(Loc.currentLang.menu.all)
    const [isActives, setIsActives] = React.useState(false);
    const isMember = props?.userInfo?.members?.some((x: any) => x?.id === currentUser?.id);
    const isStaffOfEntity = props?.userInfo?.staff?.some((x: any) => x?.id === currentUser?.id);
    const isPlayerOfEntity = props?.userInfo?.players?.some((x: any) => x?.id === currentUser?.id);
    const isFriends = currentUser?.members?.filter(x => x?.id === props?.id)?.length > 0 ? true : false;
    const [isAllowMemberPost, setisAllowMemberPost] = React.useState(isMember && props?.userInfo?.preferences?.memberPost != "N");
    const [isAllowPlayerPost, setisAllowPlayerPost] = React.useState(isPlayerOfEntity && props?.userInfo?.preferences?.playerPost != "N");
    const postList = useSelector((state: RootStateOrAny) => state.feeds.postList?.length > 0 ? state.feeds.postList[0] : []);
    const fetchProcess = useSelector((state: RootStateOrAny) => state.feeds.fetchProcess?.length > 0 ? state.feeds.fetchProcess[0] : false);
    const { data, isLoading, fetchFollowerFeeds, lastUpdated, onRefresh } = useGetFeeds(props?.page, props?.id, props?.isOwner, props?.isFollowing, props?.userInfo);
    const [postRecords, setPostRecords] = React.useState<any[]>([]);
    const getFeedsList = useSelector((state: RootStateOrAny) => state.feeds.feedowners[0]);
    const [isgetfeedProcess, setisgetfeedProcess] = React.useState(false);
    const feedsScrollUpdate = useSelector((state: RootStateOrAny) => state.feeds.feedsScrollUpdate);
    let processedEntityIds: any = useSelector((state: RootStateOrAny) => state.feeds.processedEntityIds);
    let remainingEntities: any = useSelector((state: RootStateOrAny) => state.feeds.remainingEntities);
    let feedsUpdating = false;
    let allFeedsData = userfeeds?.concat(data);
    const visibilityPermission = isMember ? (props?.userInfo?.preferences?.memberPost) : isPlayerOfEntity ? props?.userInfo?.preferences?.playerPost : "F";
    var feeds = (props?.page === 'Profile' && data?.length > 0) ? allFeedsData : allFeedsData;
    if (props?.page === 'Profile' && props?.id) {
        let locfeed = userfeeds?.filter(x => x?.pk === props?.id);
        feeds = feeds?.filter(x => x?.pk === props?.id);
        if (locfeed?.length > feeds?.length) {
            feeds = feeds?.filter(item1 => !locfeed?.some(item2 => item2?.id === item1?.id));
            feeds = feeds.concat(locfeed);
        }
    }
    feeds = _.uniqBy(feeds, 'id');
    var userPostFeeds : any = props?.page === "Home" ? feeds : feeds?.filter( x => x?.owner?.id === props?.id );

    if (props?.setisFeedCount) {
        props.setisFeedCount(userPostFeeds?.length ? userPostFeeds?.length : 0);
    }

    const dispatch = useDispatch();
    const dimensions = useWindowDimension();
    const isMobile = dimensions?.width <= 500 || false;
    const user = useSelector((state: RootStateOrAny) => state?.user?.user[0]);
    const member = props?.userInfo?.members?.filter(x => x?.id === user?.id);

    const allowpost = (props?.userInfo?.preferences?.allowFollowersPosts && (props?.isFollowing || member?.length > 0)) ? true : false;;
    const [posterFilter, setpostFilterData] = React.useState(userPostFeeds);

    const showItems = (posts: any) => {
        if (!posts) return;
        var items: any = [];
        for (var i = 0; i < records; i++) {
            if (posts[i]) {
                items.push(
                    <div key={` ${posts[i].id}+${i}`}>
                        <Card key={` ${posts[i].id}+${i}`} iscommentAccess={props?.iscommentAccess} data={posts[i]} {...props} previewdata={props?.previewdata} userInfo={props?.page === "Home" ? user : props?.userInfo} />
                    </div>
                );
            }
        }
        return items;
    };

    //Hide for now, will take it later


    // const Handleall = () => {
    //     setcurrentStatus(Loc.currentLang.menu.all)
    //     setIsActives(!isActives);
    //     hanldeFilter(Loc.currentLang.menu.all)
    // }

    // const HandlePrivate = () => {
    //     setcurrentStatus(Loc.currentLang.menu.private)
    //     setIsActives(!isActives);
    //     hanldeFilter(Loc.currentLang.menu.private)
    // }

    // const HandlePublic = () => {
    //     setcurrentStatus(Loc.currentLang.menu.public)
    //     setIsActives(!isActives);
    //     hanldeFilter(Loc.currentLang.menu.public)
    // }

    // const HandleFollowes = () => {

    //     setcurrentStatus(Loc.currentLang.menu.followers)
    //     setIsActives(!isActives);
    //     hanldeFilter(Loc.currentLang.menu.followers)
    // }

    // const followerIds = currentUser?.follows?.map(x => x.id);

    // const dropdownValues = [
    //     { id: 1, name: Loc.currentLang.menu.all, flag: data, fn: Handleall },
    //     ...((userId == currentUser?.id || isMember || isStaffOfEntity || isPlayerOfEntity || entityId === "78b5b063-fd3e-4925-92bd-2a1888c6a46a" || entityId === "b495d4e0-bf52-4690-8dc0-677db954aa7f" || entityId === "b495d4e0-bf52-4690-8dc0-677db954aa71" || entityId === "b495d4e0-bf52-4690-8dc0-677db954aa72" || props.isOwner) ? [{ id: 2, name: Loc.currentLang.menu.private, flag: data, fn: HandlePrivate }] : []),
    //     ...((isStaffOfEntity || userId == currentUser?.id) ? [{ id: 3, name: Loc.currentLang.menu.public, flag: data, fn: HandlePublic }] : []),
    //     { id: 4, name: Loc.currentLang.menu.followers, flag: data, fn: HandleFollowes },
    // ]

    const hanldeFilter = async (item: any) => {
        switch (item) {
            case Loc.currentLang.menu.private:
                setpostFilterData(userPostFeeds?.filter((x: any) => x?.visibility == "Private"));
                break;
            case Loc.currentLang.menu.public:
                setpostFilterData(userPostFeeds?.filter((x: any) => x?.visibility == "Public"));
                break;
            case Loc.currentLang.menu.followers:
                setpostFilterData(userPostFeeds?.filter((x: any) => x?.visibility == "Follow"));
                break;
            default:
                setpostFilterData(props?.isOwner ? userPostFeeds : userPostFeeds?.filter((x: any) => x?.visibility != "Private"));
        }
    }

    const handleGetPostList = async (item) => {
        var retval: string[] = [];
        for (var i = 0; item && i < item.length; i++) {
            retval.push(item[i].id);
            //@ts-ignore
            for (var g = 0; item[i].gallery && g < item[i].gallery.length; g++) {
                //@ts-ignore
                retval.push(item[i].gallery[g]);
            }
        }
        return retval;
    }

    const getData = async (postlists) => {
        setHasMore( true );
        setisgetfeedProcess(true);
        let userPostList = postlists;
        userPostList = userPostList?.filter((item1: { id: any; }) => !userfeeds?.some((item2: { id: any; }) => item2?.id === item1?.id));
        console.log("Sorting on Get Data on Feeds.tsx")
        // Define the sort priorities
        const sortOrder = {
            'Event': 1,
            'Team': 2,
            'LeagueTeam': 3,
            'Club': 4,
            'TeamClub': 5,
            'Person': 6
        };

        // Sort usersfeeds by owner.type using sortOrder for priority
        let userfeedsList = userPostList.sort((a, b) => {
            return sortOrder[a.owner.type] - sortOrder[b.owner.type];
        });

        userPostList?.sort((a: any, b: any) => {
            const createDateA = new Date(a.createDate) as any;
            const createDateB = new Date(b.createDate) as any;
            return createDateB - createDateA;
        });
        let lastUpdated = userfeedsList[userfeedsList?.length - 1]?.lastUpdated;

        if (props?.page === 'Profile') {
            let userPost = userPostList?.filter((x: any) => x?.pk === props?.id);
            lastUpdated = userPost[userPost?.length - 1]?.lastUpdated;
            userPostList = postlists?.filter((x: any) => x?.owner == props?.id);
        }
        //userPostList = userPostList?.filter( ( x : any ) => x?.date == lastUpdated || x?.date < lastUpdated );

        let latestPost: any = [];
        userPostList = userPostList?.slice(0, 20);
        // Remove duplicate
        //userPostList = userPostList?.filter((item1 : { id : any; }) => !userfeedsList?.some((item2 : { id : any; }) => item2?.id === item1?.id));
        //setHasMore(false);
        // Group posts by owner ID
        const postsByOwner = {};
        for ( let i = 0; i < userPostList.length; i++ )
        {
            const post = userPostList[ i ];
            const ownerId = post?.owner;
            if ( !postsByOwner[ ownerId ] )
            {
                postsByOwner[ ownerId ] = [];
            }
            postsByOwner[ ownerId ].push( post );
        }

        // Iterate through each owner's posts
        for (const ownerId in postsByOwner) {
            if (postsByOwner.hasOwnProperty(ownerId)) {
                // Find the owner in the response
                const owner = getFeedsList.find(x => x?.id === ownerId);
                if (owner) {
                    const postIds = postsByOwner[ownerId];
                    // Fetch owner's posts asynchronously
                    const ownerPosts = await handleGetPostList(postIds);
                    console.log('Owner Posts:::::', ownerPosts);
                    let fetchedPosts;
                    if (ownerPosts?.length > 0) {
                        // Fetch additional posts for each post
                        fetchedPosts = await fetchAdditionalPosts(owner, ownerPosts);
                    }
                    // Combine fetched posts with Today's Posts
                    latestPost = _.uniqBy([...(latestPost || []), ...(fetchedPosts || [])], 'id');
                }
            }
        }
        // Convert the list of posts into a tree structure and sort by createDate
        let formattedPosts = list_to_tree(latestPost);
        //formattedPosts = formattedPosts?.sort( ( a, b ) =>
        //{
        //    const createDateA = new Date( a.createDate ) as any;
        //    const createDateB = new Date( b.createDate ) as any;
        //    return createDateB - createDateA;
        //} );
        // Update user feeds with the formatted posts     
        userPostFeeds = await Feedsupdate(userfeeds, formattedPosts);
        setrecords(records + formattedPosts?.length);
        dispatch(setFetchProcess(false));

        if (remainingEntities.length == 0 && remainingPosts.length == 0) {
            setHasMore(false);
        }
    }

    // Function to fetch additional posts for each post
    const fetchAdditionalPosts = async (owner, posts) => {
        if (posts?.length > 0) {
            let Post: any = await Feed.getPosts(AVEntity.getFk(owner), posts);
            for (let i = 0; i < Post?.length; i++) {
                if (Post[i].posts?.length > 0) {
                    var cmdPost: any = await Feed.getPosts(AVEntity.getFk(Post[i]), Post[i]?.posts?.slice(0, 2));
                    if (cmdPost?.length > 0) {
                        Post[i].children = cmdPost;
                    }
                }
            }
            feedsUpdating = false;
            return Post;
        }
        return [];
    }

    const Feedsupdate = async (feedsval, feeds) => {
        let updatedFeeds = [...(feedsval || [])];
        feeds.forEach(feed => {
            let existingFeed = feedsval?.find(x => x?.id === feed?.id);
            if (!existingFeed) {
                updatedFeeds.push(feed);
            } else {
                existingFeed.impressions = feed.impressions;
                existingFeed.text = feed.text;
            }
        });
        updatedFeeds = _.uniqBy(updatedFeeds, 'id');
        let usersfeeds = list_to_tree(updatedFeeds);
        usersfeeds = _.uniqBy(usersfeeds, 'id');
        //usersfeeds?.sort( ( a : any, b : any ) =>
        //{
        //    const createDateA = new Date( a.createDate ) as any;
        //    const createDateB = new Date( b.createDate ) as any;
        //    return createDateB - createDateA;
        //} );
        dispatch(setFeeds(usersfeeds)); setisgetfeedProcess(false);
        //  setHasMore(false);
        return usersfeeds;
    };

    const list_to_tree = (list) => {
        var map = {}, node: any, roots: any = [], i;

        for (i = 0; i < list?.length; i += 1) {
            map[list[i]?.id] = i; // initialize the map
            if (list[i]?.children?.length === 0 || !list[i]?.children)
                list[i]["children"] = []; // initialize the children
            if (list[i]?.galleryPost?.length === 0 || !list[i]?.galleryPost)
                list[i]["galleryPost"] = [];
        }

        for (i = 0; i < list?.length; i += 1) {
            node = list[i];
            if (node.parentPost !== undefined) {
                if (list[map[node.parentPost]]?.gallery?.items?.filter((x) => x === node.id)?.length > 0 && node.gallery == undefined) {
                    let excitingpost = list[map[node.parentPost]]?.galleryPost?.filter(x => x?.id === node?.id);
                    if (excitingpost?.length === 0 || excitingpost === undefined)
                        list[map[node.parentPost]]?.galleryPost.push(node);
                } else {
                    let excitingcomment = list[map[node.parentPost]]?.children?.filter(x => x?.id === node?.id);
                    if (excitingcomment?.length === 0 || excitingcomment === undefined)
                        list[map[node.parentPost]]?.children.push(node);
                }
            } else {
                roots.push(node);
            }
        }
        return roots;
    }

    const itemsPerPage = 20;

    const [ hasMore, setHasMore ] = React.useState( true );

    const [ records, setrecords ] = React.useState( itemsPerPage );

    const loadMore = async () => {
        console.log( "Page:::", props.page )
        if ( !fetchProcess && !isgetfeedProcess )
        {
            await loadingMoreData();
        }
    };

    const loadingMoreData = async () =>
    {
        dispatch( setFetchProcess( true ) );
        console.log( "Remaining Posts List on scroll:::::::::::::::::::::::::::", remainingPosts );
        if ( remainingPosts.length > 0 )
        {
            let postIdstoFetch : any[] = [];
            if ( props.page == "Profile" )
            {
                postIdstoFetch = remainingPosts.filter( x => x?.owner?.id == props.id ).slice( 0, 20 );
                let RemainUserFeed = userfeeds.filter( x => x?.owner?.id == props.id ).slice( 0, 20 );
                postIdstoFetch = [ ...postIdstoFetch, ...RemainUserFeed ]
            }
            else
            {
                // Slice the first 20 IDs to fetch and remove them from remainingPosts
                postIdstoFetch = remainingPosts.slice( 0, 20 );
            }
            // Group posts by owner
            let postsByOwner : { [ ownerId : string ] : any[] } = postIdstoFetch.reduce( ( group : any, post : any ) =>
            {
                const ownerId = post.owner;
                if ( !group[ ownerId ] )
                {
                    group[ ownerId ] = [];
                }
                group[ ownerId ].push( post );
                return group;
            }, {} );

            // Initialize PostList to accumulate results
            let allPostLists : any[] = [];

            for ( const ownerId in postsByOwner )
            {
                if ( postsByOwner.hasOwnProperty( ownerId ) )
                {
                    // Dynamically set fk with the current ownerId
                    let fk = { id: ownerId, pk: ownerId, region: currentUser.region, type: "Feed" };
                    console.log( "fk for owner:::::::::::::::::::", fk );

                    // Map the post IDs from this group
                    let postIds : string[] = postsByOwner[ ownerId ].map( ( post : any ) => post.id );
                    if ( postIds?.length !== postIdstoFetch?.length && props.page == "Profile" )
                    {
                        let PostList : any = await Feed.getPosts( fk, postIds.slice( 0, 20 ) );
                        console.log( "RemainingPosts for owner", PostList );
                        for ( let i = 0; i < PostList?.length; i++ )
                        {
                            if ( PostList[ i ].posts?.length > 0 )
                            {
                                var cmdPost : any = await Feed.getPosts( AVEntity.getFk( PostList[ i ] ), PostList[ i ]?.posts?.slice( 0, 2 ) );
                                if ( cmdPost?.length > 0 )
                                {
                                    PostList[ i ].children = cmdPost;
                                }
                            }
                        }
                        allPostLists = allPostLists.concat( PostList );
                    }
                    // Accumulate PostList results from all owners
                    else
                    {
                        dispatch( setFetchProcess( false ) );
                        setHasMore( false );
                        setisgetfeedProcess( false );
                        return;
                    }

                }
            }

            // Remove the first 20 IDs from remainingPosts by slicing the rest
            let test = remainingPosts.slice( 20 );
            // Set the remaining posts back into the state
            remainingPosts = test;
            let FinalRemaining = [ ...remainingPosts, ...remainingPosts ]
            FinalRemaining = _.uniqBy( [ ...( FinalRemaining || [] ) ], 'id' );
            dispatch( setRemainingPosts( FinalRemaining ) );

            // Pass the accumulated PostList to getData
            await getData( allPostLists?.length > 0 ? allPostLists : postIdstoFetch );
        }
        else if ( remainingEntities.length > 0 && props.page !== "Profile" )
        {
            setHasMore( true );
            const currentDate = new Date();
            const past7DaysDate = new Date();
            past7DaysDate.setDate( currentDate.getDate() - 7 );
            let allPosts : any = [];

            console.log( "remaining Entities from scroll:::::", remainingEntities );
            for ( let i = 0; i < remainingEntities?.length; i++ )
            {
                let currentEntity = remainingEntities[ i ];
                let firstEntity = currentEntity;
                // Check the entity is already processed or not
                if ( processedEntityIds.includes( firstEntity?.id ) )
                {
                    // Skip this iteration and continue with the next one
                    continue;
                }
                const entity = firstEntity;
                const feedParam = { feed: { id: entity?.id, pk: entity?.id, type: "Feed" }, startDate: lastUpdated, forward: true, checkArchive: false };
                let feed = await Feed.getFeed( feedParam );
                let response : any = [];
                if ( feed?.id )
                {
                    processedEntityIds.push( firstEntity );
                    //processedEntityIds.push( entityId );
                    dispatch( setProcessedEntityIds( processedEntityIds ) );
                    response.push( feed );
                    let allFeedOwners = _.uniqBy( [ ...( getFeedsList ? getFeedsList : [] ), ...( response || [] ) ], 'id' );
                    allFeedOwners = allFeedOwners.filter( item => item !== undefined );
                    dispatch( setFeedOwners( allFeedOwners ) );

                    feed.followIds = feed?.followIds?.map( ( post ) => ( { ...post, owner: feed?.id } ) )
                    feed.privateIds = feed?.privateIds?.map( ( post ) => ( { ...post, owner: feed?.id } ) )
                    feed.publicIds = feed?.publicIds?.map( ( post ) => ( { ...post, owner: feed?.id } ) )
                    let currentEntityPostIds = _.uniqBy( [ ...( feed?.followIds || [] ), ...( feed?.privateIds || [] ), ...( feed?.publicIds || [] ) ], 'id' );
                    let remainsPosts = currentEntityPostIds.filter( x => new Date( x.date ) < past7DaysDate );
                    //remainingPosts = remainingPosts.concat( remainsPosts );
                    //currentEntityPostIds = currentEntityPostIds.filter( x => new Date( x.date ) > past7DaysDate )
                    allPosts = _.uniqBy( [ ...( allPosts || [] ), ...( currentEntityPostIds ) ], 'id' );
                    let FinalRemaining = [ ...remainingPosts, ...allPosts ]
                    FinalRemaining = _.uniqBy( [ ...( FinalRemaining || [] ) ], 'id' );
                    dispatch( setRemainingPosts( FinalRemaining ) );
                }
                if ( allPosts.length > 20 )
                {
                    const getRemainingEntities = ( remainingEntities : any[], processedEntityIds : any[][] ) =>
                    {
                        // Flatten the processedEntityIds to make comparison easier
                        const processedIds = processedEntityIds.flat().map( entity => entity.id );

                        return remainingEntities.filter( ( entity : any ) => !processedIds.includes( entity.id ) );
                    };
                    var newRemainingEnities = getRemainingEntities( remainingEntities, processedEntityIds );
                    if ( newRemainingEnities.length > 0 )
                    {
                        remainingEntities.unshift( ...newRemainingEnities );
                        remainingEntities = _.uniqBy( [ ...( remainingEntities || [] ) ], 'id' );
                        dispatch( setRemainingEntities( remainingEntities ) );
                    }
                    else
                    {
                        remainingEntities = [];
                        dispatch( setRemainingEntities( remainingEntities ) );
                    }
                    // If there are remaining posts, store them for the next scroll
                    if ( allPosts.length > 20 - allPosts.length )
                    {
                        console.log( "All Postst::::::::::::", allPosts );
                        console.log( "Slicing post :::::::::::::::::::", allPosts.slice( 20 - allPosts.length ) );
                        let test = allPosts.slice( 20 );
                        remainingPosts = remainingPosts.concat( test );
                        let FinalRemaining = [ ...remainingPosts, ...remainingPosts ]
                        FinalRemaining = _.uniqBy( [ ...( FinalRemaining || [] ) ], 'id' );
                        dispatch( setRemainingPosts( FinalRemaining ) );
                        console.log( "Remaining Posts::::::::::", remainingPosts );
                    }
                    allPosts = allPosts.slice( 0, 20 - allPosts.length );
                }
                if ( allPosts.length > 0 )
                {
                    const getRemainingEntities = ( remainingEntities : any[], processedEntityIds : any[][] ) =>
                    {
                        // Flatten the processedEntityIds to make comparison easier
                        const processedIds = processedEntityIds.flat().map( entity => entity.id );

                        return remainingEntities.filter( ( entity : any ) => !processedIds.includes( entity.id ) );
                    };
                    var newRemainingEnities = getRemainingEntities( remainingEntities, processedEntityIds );
                    if ( newRemainingEnities.length > 0 )
                    {
                        remainingEntities.unshift( ...newRemainingEnities );
                        remainingEntities = _.uniqBy( [ ...( remainingEntities || [] ) ], 'id' );
                        dispatch( setRemainingEntities( remainingEntities ) );
                    }
                    else
                    {
                        remainingEntities = [];
                        dispatch( setRemainingEntities( remainingEntities ) );
                    }
                    // Slice the first 20 IDs to fetch and remove them from remainingPosts
                    let postIdstoFetch = allPosts.slice( 0, 20 );

                    // Group posts by owner
                    let postsByOwner : { [ ownerId : string ] : any[] } = postIdstoFetch.reduce( ( group : any, post : any ) =>
                    {
                        const ownerId = post.owner;
                        if ( !group[ ownerId ] )
                        {
                            group[ ownerId ] = [];
                        }
                        group[ ownerId ].push( post );
                        return group;
                    }, {} );

                    // Initialize PostList to accumulate results
                    let allPostLists : any[] = [];

                    for ( const ownerId in postsByOwner )
                    {
                        if ( postsByOwner.hasOwnProperty( ownerId ) )
                        {
                            // Dynamically set fk with the current ownerId
                            let fk = { id: ownerId, pk: ownerId, region: currentUser.region, type: "Feed" };
                            console.log( "fk for owner:::::::::::::::::::", fk );

                            // Map the post IDs from this group
                            let postIds : string[] = postsByOwner[ ownerId ].map( ( post : any ) => post.id );
                            let PostList : any = await Feed.getPosts( fk, postIds.slice( 0, 20 ) );

                            console.log( "RemainingPosts for owner", PostList );
                            for ( let i = 0; i < PostList?.length; i++ )
                            {
                                if ( PostList[ i ].posts?.length > 0 )
                                {
                                    var cmdPost : any = await Feed.getPosts( AVEntity.getFk( PostList[ i ] ), PostList[ i ]?.posts?.slice( 0, 2 ) );
                                    if ( cmdPost?.length > 0 )
                                    {
                                        PostList[ i ].children = cmdPost;
                                    }
                                }
                            }
                            // Accumulate PostList results from all owners
                            allPostLists = allPostLists.concat( PostList );
                        }
                    }
                    await getData( allPostLists );
                }
            }
        }
        else
        {
            //console.log( "Get the Next Entity" );
            //await fetchFollowerFeeds( currentUser, new Date( 0 ), userfeeds );
            setHasMore( false );
        }

    }

    React.useEffect(() => {
        //refetch();
        let isMounted = true;
        if (isMounted) {
            if (feedsScrollUpdate) {
                scrollToTop()
                dispatch(setFeedsScrollUpdate(false));
            }
            if (feedsUpdate) {
                dispatch(setFeedsUpdate(false));
            }
            return () => {
                isMounted = false; // Set the flag to false when the component is unmounted
                // Cancel subscriptions or perform any cleanup tasks here
            };
        }

        }, [userfeeds, feedsScrollUpdate]);

    //React.useEffect(() => {
    //    onRefresh();
    //}, [feedsUpdate])

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    if (isLoading || feedsLoad) {
        return (
            <VStack width={'100%'}>
                {[...Array(5)].map((i, index) => (
                    <Box key={index} width={'100%'}>
                        <FeedsLoader />
                    </Box>
                ))}
            </VStack>
        );
    }

    return (
        <Box>
            {/* Hide for now, will use it later */}
            {/* {!showModal && props?.page !== 'Home' && (
                <HStack position={'absolute'} top={userPostFeeds && (currentstatus == Loc.currentLang.menu.all ? userPostFeeds?.filter(x => x?.status != "Deleted").length > 0 : posterFilter?.length > 0) ? '5px' : '30px'} right={'50px'} zIndex={999}>
                    {(userId == currentUser?.id || followerIds.includes(userId) || isPlayerOfEntity || isMember || entityId === "78b5b063-fd3e-4925-92bd-2a1888c6a46a" || entityId === "b495d4e0-bf52-4690-8dc0-677db954aa7f" || entityId === "b495d4e0-bf52-4690-8dc0-677db954aa71" || entityId === "b495d4e0-bf52-4690-8dc0-677db954aa72" || props.isOwner) &&
                        userPostFeeds?.filter(x => x?.status != "Deleted").length > 0 && (
                            <>
                                <HStack top={'4px'} zIndex={999}>
                                    <AVSelectDropdown dropdownValues={dropdownValues} currentstatus={currentstatus} setIsActives={setIsActives} isActives={isActives} />
                                </HStack>
                            </>
                        )}
                </HStack>
            )} */}
            {!showModal && (props?.page !== 'Profile' || (props?.page === 'Profile' && (props?.isOwner || allowpost || props?.userInfo?.preferences?.allowAllToPost || isAllowMemberPost || isAllowPlayerPost) && (visibilityPermission != "N"))) && (
                <>
                    {props?.Postdata?.status !== 'Canceled' && (
                        <Offline>
                            {({ isOffline }) => {
                                return (
                                    !isOffline && ( // Render the button only if the user is online
                                        <Button
                                            bgColor={'AVColor.primary'}
                                            borderRadius={'full'}
                                            padding={3}
                                            height="45px"
                                            width="45px"
                                            onPress={() => setShowModal(true)}
                                            zIndex={1}
                                            position={'fixed'}
                                            bottom={isMobile ? '110px' : '10px'}
                                            marginLeft={[props?.offW ? props?.offW - 60 : 100]}
                                        >
                                            <Image source={msg} alt="Actavivo" size="25px" />
                                        </Button>
                                    )
                                );
                            }}
                        </Offline>
                    )}
                </>

            )}
            {!feedsLoad && userPostFeeds && (currentstatus == Loc.currentLang.menu.all ? userPostFeeds?.filter(x => x?.status != "Deleted").length > 0 : posterFilter?.length > 0) ? (
                <InfiniteScroll
                    pageStart={0}
                    loadMore={loadMore}
                    hasMore={hasMore}
                    loader={
                        <Center key={0}>
                            <Box m={5}>
                                <Loader />
                            </Box>
                        </Center>
                    }
                    useWindow={true}
                >
                    {showItems(currentstatus == Loc.currentLang.menu.all ? userPostFeeds : posterFilter)}
                </InfiniteScroll>
            ) : (
                <div style={{ marginBottom: '-1px', marginTop: '40px', textAlign: 'center', top: '4px', minHeight: '50px' }}>
                    {Loc.currentLang.post.noFeeds}
                </div>

            )}

            {showModal && (
                <CreatePostpopup
                    visibilityPermission={visibilityPermission}
                    Postdata={allowpost ? props?.userInfo : props?.Postdata}
                    setShowCreatePost={setShowModal}
                />
            )}
        </Box>
    );
}

export default React.memo(Feeds);