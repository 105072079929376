import { HStack, VStack, Text, Pressable, Input, Box, Image, Divider, Button, AddIcon, CloseIcon, ScrollView } from 'native-base';
import React, { useState, useRef } from 'react';
import { Media } from '../../AVCore/Media';
import ProfilePic from '../common/ProfilePicture';
import { Person } from '../../AVCore/AVCore';
import {
MoreHorizontalIcon, LikeIcon, DeleteIcon, EditIcon, ChatIcon, CommentIcon, PaperPlaneIcon, CrossIcon, TickIcon, VideoIcon, FeedbackIcon, BugReportIcon, FeatureRequestIcon
} from '../../assets/icons/icon-assets';
import { Loc } from '../../App/Loc';
import Comment from '../../components/posts/Comment';
import useFillFeeds from '../../Hooks/useFillFeeds';
import report from '../../assets/images/report.png';
import { AHStack, AVStack } from 'components/ui';
import { Link } from 'react-router-dom';
import { Link as AVlink } from '../../AVCore/Link';
import * as AV from '../../AVCore/AVCore';
import fallBackImage from '../../assets/images/avatar.png';
import SvgIcon from 'assets/icons';
import AVCancel from 'components/common/AVCancel';
import AVImageSlider from 'components/common/AVImageSlider';
import EditPostpopup from '../../Screens/Home/EditPostpopup';
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux';
import AVAdd from 'components/common/AVAdd';
import { useWindowDimension } from 'Utility/WindowDimensions';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import unauthorPopup from '../../assets/images/unauthorPopup.png';
import AVlinktext from '../common/AVlinktext';
import { StyleSheet } from 'react-native';
import AVConfirmationModal from '../common/AVConfirmationModal';
import AVEmojiPicker from '../../components/common/AVEmojiPicker';
import settings from '../../Platform/settings.json';
import { setFeeds, setFeedsUpdate } from '../../Redux/Actions/feedsAction';
import AVConverttime from './AVConverttime';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import useConvoList from '../../Hooks/useConvoList';
import AVDescription from '../common/AVDescription'
import PostText from "../../components/common/preview/PostText";
import LinkPreview from "../../components/common/preview/LinkPreview"
import axios from 'axios';
import { View, Modal, TouchableOpacity, FlatList } from 'react-native';
import LogoSvg1 from '../../assets/images/Actavivo-Logo.svg';
import { baseFontSize } from 'native-base/lib/typescript/theme/tools';
import { IoClose } from "react-icons/io5";
function Card (props : any) {
  const location : any = useLocation();
  const dispatch = useDispatch();
  const dimensions = useWindowDimension();
  const currentUser = useSelector((state : RootStateOrAny) => state.user.user[ 0 ]);
  const userfeeds = useSelector((state : RootStateOrAny) => state.feeds.feeds[ 0 ]);
  const [ imageUrl, setImagesUrl ] = React.useState([]);
  const [ DocsUrl, setDocsUrl ] = React.useState([]);
  const [ videoUrls, setVideoUrl ] = React.useState([]);
  const [ commentItem, setIscommentItem ] = React.useState<any>();
  const [ showAddMedia, setShowAddMedia ] = React.useState(false);
  const [ picker, setPicker ] = React.useState(false);
  const [ isImageProcessing, setIsImageProcessing ] = React.useState(false);
  const [ noticeMessage, setNoticeMessage ] = React.useState<any>('');
  const navigate = useNavigate();
  const [ fullscreen, setIsFullScreen ] = useState(false);
  const [ isCommentSlider, setIsCommentSlider ] = React.useState(false);
  let imageUrls = [ ...new Set(imageUrl) ];
  const docsUrl:any = [ ...new Set(DocsUrl) ];
  const videoUrl = [ ...new Set(videoUrls) ];
  const feedsUpdate = useSelector((state : RootStateOrAny) => state.feeds.feedsUpdate);
  const [ Confirmationtext, setConfirmationtext ] = React.useState("Are you sure ?");
  const [ showconfirmationPopup, setshowconfirmationPopup ] = React.useState(false);
  const [ selectType, setselectType ] = React.useState<any>();
  const [ selectItem, setselectItem ] = React.useState(false);
  const [ ToComment, setToComment ] = React.useState(false);
  const [ videoStatus, setVideoStatus ] = React.useState<any>('')
	const [ videoItem, setVideoItem ] = React.useState<any>('')
	const [ modalVisible, setModalVisible ] = React.useState(false);
	const [ impressionDetails, setImpressionDetails ] = React.useState<any>([]);
  const usernewalert = useSelector((state : RootStateOrAny) => state?.alerts?.newalert[ 0 ]);
  const entitydatalist = useSelector( ( state : RootStateOrAny ) => state.user.entitydatalist );
    const [ shiftPressed, setShiftPressed ] = useState( false );
	const { showConvoListModal, setShowConvoListModal } = useConvoList();



	const [ sad, setSad ] = React.useState<any>([])
	const [ like, setLike ] = React.useState<any>([])
	const [ heart, setHeart ] = React.useState<any>([])
	const [ unlike, setUnlike ] = React.useState<any>([])
	const [ wow, setWow ] = React.useState<any>([])
	const [ cheer, setCheer ] = React.useState<any>([])
	const [ question, setQuestion ] = React.useState<any>([])
	const [ mad, setMad ] = React.useState<any>([])
	const [ lol, setLol ] = React.useState<any>([])
	const [ broken, setBroken ] = React.useState<any>([])


	const [ nsad, setnSad ] = React.useState<any>([])
	const [ nlike, setnLike ] = React.useState<any>([])
	const [ nheart, setnHeart ] = React.useState<any>([])
	const [ nunlike, setnUnlike ] = React.useState<any>([])
	const [ nwow, setnWow ] = React.useState<any>([])
	const [ ncheer, setnCheer ] = React.useState<any>([])
	const [ nquestion, setnQuestion ] = React.useState<any>([])
	const [ nmad, setnMad ] = React.useState<any>([])
	const [ nlol, setnLol ] = React.useState<any>([])
	const [ nbroken, setnBroken ] = React.useState<any>([])

	const [ imparray, setImparray ] = useState<any[]>([]);


	const [ selectedTab, setSelectedTab ] = React.useState<any>("All");

	const isMobile = dimensions?.width <= 500 || false;

	const handleKeyPress = e => {
		const { key } = e.nativeEvent;
		if (key === "Shift") {
			setShiftPressed(true);
		} else if (key === "Enter" && !shiftPressed) {
			e.preventDefault();
			handlePostComment();
		} else {
			setShiftPressed(false);
		}
	};


	//React.useEffect(() => {
	//	const combinedArray = [
	//		...sad.map(item => ({ ...item, type: 'sad' })),
	//		...like.map(item => ({ ...item, type: 'like' })),
	//		...heart.map(item => ({ ...item, type: 'heart' })),
	//		...unlike.map(item => ({ ...item, type: 'unlike' })),
	//		...wow.map(item => ({ ...item, type: 'wow' })),
	//		...cheer.map(item => ({ ...item, type: 'cheer' })),
	//		...question.map(item => ({ ...item, type: 'question' })),
	//		...mad.map(item => ({ ...item, type: 'mad' })),
	//		...lol.map(item => ({ ...item, type: 'lol' })),
	//		...broken.map(item => ({ ...item, type: 'broken' })),
	//	];

	//	// Update imparray with the combined array
	//	setImparray(combinedArray);
	//}, [ sad, like, heart, unlike, wow, cheer, question, mad, lol, broken ]);

	React.useEffect(() => {
		// Person Update
		let isMounted = true;
		if (isMounted) {
			if (props?.data?.person?.type === "Person" && currentUser?.id == props?.data?.person?.id) {
				props.data.person = AV.AVEntity.getLink(currentUser);
			} else if (
				[ "Team", "PlayerOrg", "Club", "School"].includes(props?.data?.person?.type) &&
				currentUser?.orgs?.some(x => x?.id == props?.data?.person?.id)
			) {
				props.data.person = currentUser?.orgs?.find(x => x?.id == props?.data?.person?.id);
			} else if (
				["Event"].includes(props?.data?.person?.type) &&
				currentUser?.events?.some(x => x?.id == props?.data?.person?.id)
			) {
				props.data.person = currentUser?.events?.find(x => x?.id == props?.data?.person?.id);
			} else if (
				["Person"].includes(props?.data?.person?.type) &&
				currentUser?.follows?.some(x => x?.id == props?.data?.person?.id)
			) {
				props.data.person = currentUser?.follows?.find(x => x?.id == props?.data?.person?.id);
			}

			//Owner Update

			if (props?.data?.owner?.type === "Person" && currentUser?.id == props?.data?.owner?.id) {
				props.data.owner = AV.AVEntity.getLink(currentUser);
			} else if (
				["Team", "LeagueTeam", "Club", "School", "Group"].includes(props?.data?.owner?.type) &&
				currentUser?.orgs?.some(x => x?.id == props?.data?.owner?.id)
			) {
				props.data.owner = currentUser?.orgs?.find(x => x?.id == props?.data?.owner?.id);
			} else if (
				["Event"].includes(props?.data?.owner?.type) &&
				currentUser?.events?.some(x => x?.id == props?.data?.owner?.id)
			) {
				props.data.owner = currentUser?.events?.find(x => x?.id == props?.data?.owner?.id);
			} else if (
				["Person"].includes(props?.data?.owner?.type) &&
				currentUser?.follows?.some(x => x?.id == props?.data?.owner?.id)
			) {
				props.data.owner = currentUser?.follows?.find(x => x?.id == props?.data?.owner?.id);
			}
			setItem(props?.data);
			setuserInfo(props?.userInfo);
		}
		return () => {
			isMounted = false; // Set the flag to false when the component is unmounted
			// Cancel subscriptions or perform any cleanup tasks here
		};
	}, [props?.data, userfeeds, feedsUpdate]);

	interface Impression {
		impression : string;
		user : string;
		timestamp ?: string;
	}

	interface Item {
		impressions : Impression[];
	}

	const {
		item,
		setItem,
		images,
		setImages,
		isAddComment,
		setIsAddComment,
		addComment,
		setAddComment,
		commentValue,
		setCommentValue,
		showFilePicker,
		setShowFilePicker,
		media,
		setMedia,
		gallery,
		setGallery,
		handleAddComment,
		handlePostComment,
		handleimgCancel,
		handleLikePost,
		showEmoji,
		handleEmojiPicker,
		handleConvoPostComment,
		setShowEmoji,
		showModal,
		handleremoveLike,
		setShowModal,
		handleFilePicker,
		showComment,
		setShowComment,
		handleComment,
		getImageGalleryFromFilePickerCreateConvo,
		handleReplyPost,
		onEmojiClick,
		showEditPopup,
		setShowFBEmoji,
		showFBEmoji,
		setShowEditPopup,
		handleEditPopup,
		handleEditPost,
		handleAcceptedPost,
		showEditPost,
		setShowEditPost,
		textEditRef,
		handleEditComment,
		handleUpdatePost,
		editComment,
		showEditFilePicker,
		handleEditFilePicker,
		isDeleted,
		setIsCommentLoading,
		isCommentLoading,
		showDetails,
		setShowDetails,
		showunauthorizedDetails,
		setshowunauthorizedDetails,
		commentMedia,
		setCommentMedia,
		editItem,
		setEditItem,
		userInfo,
		setuserInfo,
		previewitem,
		setpreviewitem,
		setShowEditPopups,
		showEditPopups,
		videos,
		setvideos,
		handleslideChange,
		isLikeLoading,
		HandleRejected,
	} = useFillFeeds(props, "feeds");

	const [position, setPosition] = React.useState("top");
	const [alertMessage, setAlertMessage] = React.useState("");
	const commentValueRef = useRef(null);
	const commentsRef: any = useRef(null);
	const focusref: any = useRef(null);
	const inputRef: any = useRef(null);

	const handlePoint = () => {
		inputRef.current.focus();
	};

	const emojiMapping : { [ key : string ] : string } = {
		Wow: settings.wow,
		Lol: settings.lol,
		Like: settings.like,
		Unlike: settings.unlike,
		Cheer: settings.cheer,
		Question: settings.question,
		Sad: settings.sad,
		heart: settings.heart,
		broken: settings.broken,
		Mad: settings.mad,
	};
	;

	const handleImpressionpop = (impression : string) => {
		setSelectedTab(impression);
		console.log("Selected Tabs::::::", selectedTab)
		var data;
		if (impression !== 'All')
			data = item?.impressions?.filter(x => x.impression === impression);
		else
			data = item?.impressions;

		if ('Like') {
			setLike(data)
			setLol([])
			setWow([])
			setUnlike([])
			setCheer([])
			setQuestion([])
			setSad([])
			setHeart([])
			setBroken([])
			setMad([])
		}
		else if ('Lol') {
			setLol(data)

			setWow([])
			setUnlike([])
			setCheer([])
			setQuestion([])
			setSad([])
			setHeart([])
			setBroken([])
			setMad([])
			setLike([])
		}
		else if ('Wow') {
			setWow(data)
			setUnlike([])
			setCheer([])
			setQuestion([])
			setSad([])
			setHeart([])
			setBroken([])
			setMad([])
			setLike([])
			setLol([])
		}
		else if ('Unlike') {
			setUnlike(data)
			setCheer([])
			setQuestion([])
			setSad([])
			setHeart([])
			setBroken([])
			setMad([])
			setLike([])
			setLol([])
			setWow([])
		}
		else if ('Cheer') {
			setCheer(data)
			setQuestion([])
			setSad([])
			setHeart([])
			setBroken([])
			setMad([])
			setLike([])
			setLol([])
			setWow([])
			setUnlike([])
		}
		else if ('Question') {
			setQuestion(data)
			setSad([])
			setHeart([])
			setBroken([])
			setMad([])
			setLike([])
			setLol([])
			setWow([])
			setUnlike([])
			setCheer([])
		}
		else if ('Sad') {
			setSad(data)
			setHeart([])
			setBroken([])
			setMad([])
			setLike([])
			setLol([])
			setWow([])
			setUnlike([])
			setCheer([])
			setQuestion([])
		}
		else if ('heart') {
			setHeart(data)
			setSad([])
			setBroken([])
			setMad([])
			setLike([])
			setLol([])
			setWow([])
			setUnlike([])
			setCheer([])
			setQuestion([])
		}
		else if ('broken') {
			setBroken(data)
			setMad([])
			setLike([])
			setLol([])
			setWow([])
			setUnlike([])
			setCheer([])
			setQuestion([])
			setHeart([])
			setSad([])
		}
		else if ('Mad') {
			setMad(data)
			setLike([])
			setLol([])
			setWow([])
			setUnlike([])
			setCheer([])
			setQuestion([])
			setHeart([])
			setSad([])
			setBroken([])
		}
		else {

			const likeImp = item.impressions.filter(x => x.impression === "Like");
			setLike(likeImp);
			setnLike(likeImp)


			const wowImp = item.impressions.filter(x => x.impression === "Wow");
			setWow(wowImp);
			setnWow(wowImp);

			const madImp = item.impressions.filter(x => x.impression === "Mad");
			setMad(madImp);
			setnMad(madImp);

			const lolImp = item.impressions.filter(x => x.impression === "Lol");
			setLol(lolImp);
			setnLol(lolImp);

			const heartImp = item.impressions.filter(x => x.impression === "heart");
			setHeart(heartImp);
			setnHeart(heartImp);

			const brokenImp = item.impressions.filter(x => x.impression === "broken");
			setBroken(brokenImp);
			setnBroken(brokenImp);

			const unlikeImp = item.impressions.filter(x => x.impression === "Unlike");
			setUnlike(unlikeImp);
			setnUnlike(unlikeImp);

			const questionImp = item.impressions.filter(x => x.impression === "Question");
			setQuestion(questionImp);
			setnQuestion(questionImp);

			const sadImp = item.impressions.filter(x => x.impression === "Sad");
			setSad(sadImp);
			setnSad(sadImp);

			const cheerImp = item.impressions.filter(x => x.impression === "Cheer");
			setCheer(cheerImp);
			setnCheer(cheerImp)
		}
	}

	const handleImpression = (item : Item, impression : string) : void => {
		const impressionDetails = item.impressions.filter(x => x.impression === impression);

		const likeImp = item.impressions.filter(x => x.impression === "Like");
		setLike(likeImp);
		setnLike(likeImp)


		const wowImp = item.impressions.filter(x => x.impression === "Wow");
		setWow(wowImp);
		setnWow(wowImp);

		const madImp = item.impressions.filter(x => x.impression === "Mad");
		setMad(madImp);
		setnMad(madImp);

		const lolImp = item.impressions.filter(x => x.impression === "Lol");
		setLol(lolImp);
		setnLol(lolImp);

		const heartImp = item.impressions.filter(x => x.impression === "heart");
		setHeart(heartImp);
		setnHeart(heartImp);

		const brokenImp = item.impressions.filter(x => x.impression === "broken");
		setBroken(brokenImp);
		setnBroken(brokenImp);

		const unlikeImp = item.impressions.filter(x => x.impression === "Unlike");
		setUnlike(unlikeImp);
		setnUnlike(unlikeImp);

		const questionImp = item.impressions.filter(x => x.impression === "Question");
		setQuestion(questionImp);
		setnQuestion(questionImp);

		const sadImp = item.impressions.filter(x => x.impression === "Sad");
		setSad(sadImp);
		setnSad(sadImp);

		const cheerImp = item.impressions.filter(x => x.impression === "Cheer");
		setCheer(cheerImp);
		setnCheer(cheerImp);

		console.log("Impression :::::::::::::::::", impressionDetails);
		setImpressionDetails(impressionDetails);
		setModalVisible(true);
		

	};



	const handleDeletePost = () => {
		setShowEditPopup(!showEditPopup);
		setshowconfirmationPopup(true);
		setConfirmationtext(Loc.currentLang.menu.deletePostConfirmText);
		setselectType("DeletePost");
		setselectItem(item);
	};

	const handleDeleteComment = item => {
		setShowEditPopup(!showEditPopup);
		setshowconfirmationPopup(true);
		setConfirmationtext("Are you sure you want to delete your comment?");
		setselectType("DeleteComment");
		setselectItem(item);
	};

	const handleReportPost = () => {
		setshowconfirmationPopup(true);
		setConfirmationtext(Loc.currentLang.menu.reportPostConfirmText);
		setselectType("ReportPost");
		setselectItem(item);
	};

	React.useEffect(() => {
		const FetchData = async () => {
			console.log("Update post media");
			const UpdatePost = userfeeds?.find((x: any) => x?.id === videoItem.id);
			if (UpdatePost) {
				await AV.Post.get(videoItem).then(async (post: any) => {
					if (post && post.status !== "Deleted") {
						if (post.gallery?.items?.length > 0) {
							for (let i = 0; i < post.gallery.items.length; i++) {
								await AV.Post.get({
									id: post.gallery.items[i],
									pk: post.pk,
									region: post.region,
									type: post.type,
								}).then(parentPost => {
									if (!post.galleryPost || post.galleryPost.length === 0) {
										post.galleryPost = [];
									}
									post.galleryPost.push(parentPost);
								});
							}
						}
						const existingpostAfterFetch = userfeeds?.find((x: any) => x?.id === videoItem.id);
						if (existingpostAfterFetch) {
							userfeeds[userfeeds.indexOf(existingpostAfterFetch)] = post;
							dispatch(setFeeds(userfeeds));
							dispatch(setFeedsUpdate(true));
						}
					}
				});
			}
		};

		if (videoStatus?.status == "Processing") {
			setTimeout(FetchData, 20000);
		}
	}, [videoItem, item]);
	const handleClick = () => {
		focusref.current.focus();
	};

	const handleClicks = () => {
		commentsRef.current.focus();
	};
	//const isMobile = dimensions?.width <= 600 || false;

  const handleCancel = () => {
    setpreviewitem([]);
    setIscommentItem('');
    setShowDetails(false);
    setshowunauthorizedDetails(false);
    setShowFBEmoji(false);
    setShowFilePicker(false);
    setCommentValue('');
    imageUrls = [];
    setImagesUrl( [] );
    setDocsUrl([]);
    setIsAddComment(false);
    setIsCommentSlider(false);
    setIsFullScreen(false);
  };

	const tooltipProps = {
		hasArrow: true,
		arrowSize: 15,
		offset: true,
		openDelay: 500,
		color: "white", // Text color of the tooltip
		fontSize: "md", // Font size of the tooltip text
		px: 4, // Horizontal padding of the tooltip
		py: 2, // Vertical padding of the tooltip
		_text: {
			textAlign: "center", // Text alignment within the tooltip
		},
		cursor: "default",
		zIndex: 999, // Remove the pointer cursor
	};

	const calculateTimeDifference = (timestamp) => {
		const lastUpdateDate = new Date(timestamp);
		const currentDate = new Date();
		const diffInMs = currentDate.getTime() - lastUpdateDate.getTime();

		const diffInSeconds = Math.floor(diffInMs / 1000);
		const diffInMinutes = Math.floor(diffInSeconds / 60);
		const diffInHours = Math.floor(diffInMinutes / 60);
		const diffInDays = Math.floor(diffInHours / 24);

		if (diffInSeconds < 60) {
			return `${ diffInSeconds } seconds ago`;
		} else if (diffInMinutes < 60) {
			return `${ diffInMinutes } minutes ago`;
		} else if (diffInHours < 24) {
			return `${ diffInHours } hours ago`;
		} else {
			return `${ diffInDays } days ago`;
		}
	};;




	const imageInputRef: any = React.useRef(null);

	const handleImages = async (e: any) => {
		try {
			setAlertMessage("");
			const fileInput: any = imageInputRef.current;
			setIsCommentLoading(true);

			let files: any = Array.from(e?.target?.files);
			const updatedFiles = files.map((file: any) => {
				const fileName = file.name;
				if (fileName.toLowerCase().endsWith(".jfif")) {
					const updatedFileName = fileName.replace(/\.\w+$/, ".jpg");
					const updatedFile = new File([file], updatedFileName, { type: "image/jpeg" });
					return updatedFile;
				} else {
					return file;
				}
			});
			files = updatedFiles;
			let m = "";
			console.log(files);
			if (files.length > 20) {
				m = Loc.currentLang?.alertMessages?.maxUploadFiles || "You cannot upload more than 20 files per post";
				setAlertMessage(m);
				return;
			}
			setShowAddMedia(false);
			setPicker(false);
			files.forEach((img: any) => {
				if (
					img.type !== "image/jpeg" &&
					img.type !== "image/jfif" &&
					img.type !== "image/png" &&
					img.type !== "image/webp" &&
					img.type !== "image/gif" &&
					img.type !== "video/mp4" &&
					img.type !== "video/quicktime"
				) {
					m =
						Loc.currentLang.alertMessages.supportedFormats ||
						"format is unsupported ! only Jpeg,Jfif, Png, Webp, Gif are allowed.";
					files = files.filter((item: any) => item.name !== img.name);
					return;
				} else {
					const reader = new FileReader();
					reader.readAsDataURL(img);
					reader.onload = readerEvent => {
						if (img.type.startsWith("image")) setImages(images => [...images, readerEvent.target?.result]);
						else setvideos(videos => [...videos, readerEvent.target?.result]);
					};
				}
			});
			setIsImageProcessing(true);
			setNoticeMessage("Please wait while we process your images...");
			await AV.Media.saveFiles(AV.Media.getFormData(files), currentUser?.region).then(data => {
				setCommentMedia(prev => [...prev, ...data]);
				setIsCommentLoading(false);
			});
			setNoticeMessage(null);
			fileInput.value = null;
			setIsImageProcessing(false);
		} catch {
			setIsCommentLoading(false);
		}
	};

	const showunauthorDetails = async () => {
		setshowunauthorizedDetails(true);
		location.state = "";
	};
	const handleimagecancel = () => {
		setImages([]);
		setvideos([]);
		setCommentMedia([]);
		setGallery([]);
		setMedia([]);
	};
	const [type, settype] = React.useState();

	React.useEffect(() => {
		if (
			location?.state?.previewdata &&
			location?.state?.previewdata?.alertType !== "PostReply" &&
			location?.state?.previewdata?.alertType !== "Impression"
		) {
			let datacheck = userfeeds?.filter(
				x => x?.id === location?.state?.previewdata?.id || x?.id === location.state?.previewdata?.target?.id,
			);

			if (datacheck?.length === 0 && location?.state?.previewdata) {
				showFeedDetails(location?.state?.previewdata, "Img");
				setpreviewitem(location?.state?.previewdata);
				// showunauthorDetails();
			} else {
				if (item?.id == location?.state?.previewdata?.id) {
					if (location?.state?.previewdata?.alertType) location.state = "";
					showFeedDetails(item, "ALL");
					setpreviewitem(item);
				}
				if (item?.id == location.state?.previewdata?.target?.id) {
					let val = userfeeds?.filter(x => x?.id == location?.state?.previewdata?.target?.id);
					location.state = "";
					showFeedDetails(val[0], "ALL");
					setpreviewitem(val[0]);
				}
			}
		}
		if (
			(item?.galleryPost && item?.galleryPost?.length > 0) ||
			(item?.media?.storeId && item?.media?.type?.startsWith("image"))
		) {
			for (let i = 0; i < item?.galleryPost?.length; i++) {
				media.push(item?.galleryPost[i]?.media);
			}
			if (item?.media?.storeId && item?.media?.type?.startsWith("image")) {
				media.push(item?.media);
			}
			let images = media.length && media.map(i => AV.Media.getURL(i));
			setImagesUrl(images);
		}
	}, [props?.previewdata, location?.state?.previewdata]);
	const menuRef = useRef(null);

  useOutsideAlerter(menuRef);
  //Hook that alerts clicks outside of the passed ref
  function useOutsideAlerter (ref) {
    React.useEffect(() => {
      // Alert if clicked on outside of element
      function handleClickOutside (event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowEditPopup(false);
          setShowFBEmoji(false);
          setShowEditPopups(false);
           // setShowDetails( false );
          
            /*setShowConvoListModal( false )*/
          // setShowComment(false);
        }
      }
      document.addEventListener('click', handleDocumentClick, false);
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ ref ]);
  }
  const handleshowComments = async (item : any) => {
    var newItem = item;
    if (item?.posts?.length > 0 && item?.posts?.length !== item?.children?.length) {
      var cmdPost : any = await AV.Feed.getPosts(AV.AVEntity.getFk(item), item?.posts);
      newItem.children = cmdPost;
      setItem(newItem);
      let feeds = userfeeds?.find((x : any) => x.id == newItem?.id);
      if (feeds) {
        userfeeds[ userfeeds.indexOf(feeds) ] = newItem
        dispatch(setFeeds(userfeeds));
      }
    }
    }


    const showFeedDetails = async ( item : any, pretype ) =>
    {
    /*setShowConvoListModal( false );*/
    location.state = '';
    setToComment(item?.parentPost ? true : false)
    settype(pretype);
    let imagemedia : any = [];
    let videomedia : any = [];
    let DocsMedia : any = [];
    if (
      (item?.galleryPost &&
        item?.galleryPost?.length > 0 &&
        item?.media?.type?.startsWith('image')) ||
      (item?.media?.storeId && item?.media?.type?.startsWith('image'))
    ) {
      for (let i = 0; i < item?.galleryPost?.length; i++) {
        imagemedia.push(item?.galleryPost[ i ].media);
      }
      if (item?.media?.storeId && item?.media?.type?.startsWith('image')) {
        imagemedia.push(item?.media);
        let images = imagemedia?.length && imagemedia.map((i) => AV.Media.getURL(i));
        setImagesUrl(images);
      }
    }
    else if (!item?.media?.type?.startsWith('image') && !item?.media?.type?.startsWith('video') && item?.galleryPost && item?.galleryPost?.length > 0) {
      {
        for (let i = 0; i < item?.galleryPost?.length; i++) {
          DocsMedia.push(item?.galleryPost[ i ].media);

        }
        setDocsUrl(DocsMedia);
      }
    }
	else if (!item?.media?.type?.startsWith( 'image' ) && !item?.media?.linkURL && !item?.media?.type?.startsWith('video') && item?.media?.type?.startsWith('application')) {
      DocsMedia.push(item?.media)
      setDocsUrl(DocsMedia);
    }
    else {
      for (var j = 0; j < item?.galleryPost?.length; j++) {
        if (
          item?.galleryPost[ j ].media?.storeId &&
          item?.galleryPost[ j ].media?.type?.startsWith('video')
        ) {
          videomedia.push(item?.galleryPost[ j ].media);
          setImagesUrl(videomedia);
        } else {
          imagemedia.push(item?.galleryPost[ j ].media);
          let images = imagemedia?.length && imagemedia.map((i) => AV.Media.getURL(i));
          setImagesUrl(images);
        }
      }
      if (item?.media?.storeId && item?.media?.type?.startsWith('video')) {
        videomedia.push(item?.media);
        setImagesUrl(videomedia);
      }
    }
        setShowDetails( true );
        setCommentValue( '' );
  };
  const handledocument = async (item) => {
    let val = await AV.Media.getFile(item?.media?.mListId, item?.media?.id, item?.media?.storeId);
    if (val) {
      var blobUrl = val;
      var fileName = item?.media?.orgFile;
      var a : any = document.createElement('a');
      a.href = blobUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      //    window.open(val);
    }
  };

	const handleDocumentClick = event => {
		let isEmojiClassFound = false;
		event &&
			event.path &&
			event.path.forEach(elem => {
				if (elem && elem.classList) {
					const data = elem.classList.value;
					if (data.includes("emoji")) {
						isEmojiClassFound = true;
					}
				}
			}); // end
	};

	const useStyles = makeStyles(() => ({
		singleVideo: {
			maxWidth: "100%",
			maxHeight: "500px",
			minHeight: "320px",
			height: "auto",
			width: "auto",
			objectFit: "contain",
		},
		processing: {
			opacity: "0.3", // Apply opacity when the video is processing
		},
		threeVideo: {
			maxWidth: "100%",
			maxHeight: "500px",
			minHeight: "320px",
			height: "auto",
			width: "auto",
			objectFit: "contain",
		},
		singleImage: {
			maxHeight: "100%",
			maxWidth: "100%", // Set the maximum width for a single image
			margin: "auto", // Center the image
		},
		fiveVideo: {
			maxHeight: "100%",
			maxWidth: "100%",
			margin: "auto",
		},
	}));

	const [showMore, setShowMore] = useState(false);

	const countWords = text => {
		return text.trim().split(/\s+/).length;
	};

	var truncatedText;
	const renderText = () => {
		if (!item?.text) return "";

		const words = item?.text?.trim()?.split(/\s+/);
		const wordCount = words?.length;

    if (showMore || wordCount <= 70) {
      return (
        <>
          <Text><AVlinktext text={ item?.text } /></Text>
          { wordCount > 70 && (
            <Pressable onPress={ () => setShowMore(false) }>
              <Text color="#32AE78"><AVlinktext text={ 'Less' } /> </Text>
            </Pressable>
          ) }
        </>
      );
    } else {
       truncatedText = words.slice(0, 92).join(' ') + ' ';
      return (
        <>
          { truncatedText }
          <Pressable onPress={ () => setShowMore(true) }>
            <Text color="#32AE78"><AVlinktext text={'More...'}/> </Text>
          </Pressable>
        </>
      );
    }
  };
  return (
    <>
      { showconfirmationPopup && <AVConfirmationModal showconfirmationPopup={ showconfirmationPopup } setshowconfirmationPopup={ setshowconfirmationPopup }
        Confirmationtext={ Confirmationtext } type={ selectType } item={ selectItem } /> }
      { showDetails && !isMobile && videoStatus?.status !== "Processing" && (
        <Box
        ref={menuRef}
          position={ 'fixed' }
          top={ 0 }
          bottom={ 0 }
          left={ 0 }
          right={ 0 }
          justifyContent={ 'space-between' }
          backgroundColor={ 'AVColor.overlay' }
          zIndex={ showconfirmationPopup ? 1 : 9999 }
        >
          <div className={ !fullscreen ? 'show_post_details' : 'fullscreen_image' }>
            {/* <ScrollView> */ }
            <VStack padding={ 3 } justifyContent={ 'space-between' } height={ '100%' }>
              <HStack justifyContent={ 'flex-end' }>
                { !isCommentSlider && (<AVCancel callback={ handleCancel } color={ undefined } size={ undefined } />) }
                { isCommentSlider && (
                  <Pressable
                    onPress={ () => handleCancel() }
                  >
                    <CloseIcon size={ '20px' } color={ '#ffff' } />
                  </Pressable>) }
              </HStack>
              <HStack space={ 3 } height={ '94%' }>
                { (imageUrls.length > 0 || docsUrl.length > 0) && (
                  <VStack
                    // width={'65%'}
                    flex={ 3 }
                    padding={ 2.5 }
                    alignItems={ 'flex-start' }
                    justifyContent={ 'flex-start' }
                    backgroundColor={ '#000' }
                    borderRadius={ 3 }
                    height={ '100%' }
                    overflowY={ type == 'ALL' ? 'auto' : undefined }
                  >
                    <div style={ { width: '100%', height: '100%' } }>
                      <AVImageSlider
                        slideItems={ ToComment ? commentItem : item && !ToComment ? item : previewitem && !ToComment ? previewitem : item }
                        previewitem={ previewitem }
                        setpreviewitem={ setpreviewitem }
                        type={ type }
                        settype={ settype }
                        fullscreen={ fullscreen }
                        setIsFullScreen={ setIsFullScreen }
                        setShowDetails={ setShowDetails }
                        setIscommentItem={ setIscommentItem }
                        setIsCommentSlider={ setIsCommentSlider }
                        isCommentSlider={ isCommentSlider }
                        setCommentValue = {setCommentValue}
                        handleslideChange={ handleslideChange } isHeader={ undefined } setShowPreview={ undefined } setHeader={ undefined } />
                    </div>
                  </VStack>
                ) }
                { !fullscreen && (
                  <VStack
                    flex={ 2 }
                    space={ 2 }
                    overflow={ 'hidden' }
                    overflowY={ 'scroll' }
                    height={ '65vh' }
                  >
                    <PostHeader
                      item={ type === 'ALL' ? item : type== 'IMG' ? previewitem : commentItem ? commentItem : item }
                      handleEditPopup={ handleEditPopup }
                      showEditPopup={ showEditPopup }
                      userInfo={ userInfo }
                      setShowEditPopup={ setShowEditPopup }
                      handleDeletePost={ handleDeletePost }
                      handleReportPost={ handleReportPost }
                      handleEditPost={ handleEditPost }
                      handleAcceptedPost={ handleAcceptedPost }
                      showEditPost={ showEditPost }
                      setEditItem={ setEditItem }
                      menuRef={ menuRef }
                      showModal={ showModal }
                      setShowModal={ setShowModal }
                      isDeleted={ isDeleted }
                      setShowEditPost={ setShowEditPost }
                      currentUser={ currentUser }
                      isMobile={ isMobile }
                      showDetails={ showDetails } handleLikePost={ undefined } refetch={ undefined } reload={ undefined }
										  tooltipProps={ undefined }
										  calculateTimeDifference={ calculateTimeDifference }
                      entitydatalist={ entitydatalist }
                    />
                    <AVStack position={ 'absolute' } right={ 0 } zIndex={ 'auto' }>
                      <Pressable
                        onPress={ () => {
                          handleEditPopup(item);
                        } }
                        _hover={ { backgroundColor: 'AVColor.hovercolor2', rounded: 'full' } }
                        p={ 2 }
                      >
                        <MoreHorizontalIcon size={ '18px' } color={ 'AVColor.primaryText' } />
                      </Pressable>
                      { showEditPopups && (
                        <VStack
                          ref={ menuRef }
                          position={ 'absolute' }
                          width='125px'
                          bgColor='#fff'
                          borderColor='coolGray.200'
                          borderWidth='1'
                          shadow={ 1 }
                          zIndex={ 1 }
                          top={ '43px' }
                          right={ 1 }
                          borderRadius={ 5 }
                        >
                          { item?.person?.id !== currentUser?.id && item.modStatus !== 'NR' && (
                            <>
                              <Pressable
                                onPress={ () => {
                                  handleReportPost();
                                } }
                                p={ 2 }
                                _hover={ { backgroundColor: 'AVColor.hovercolor2' } }
                              >
                                <HStack alignItems={ 'center' } space={ 2 }>
                                  <Image source={ report } alt='report' size='15' />
                                  <Text>{ Loc.currentLang.menu.report }</Text>
                                </HStack>
                              </Pressable>
                            </>
                          ) }
                          { item?.person?.id === currentUser?.id && item.modStatus !== 'NR' && (
                            <>
                              { item?.ptype !== 'S' && (<Pressable
                                onPress={ () => {
                                  setShowModal(!showModal);
                                  setShowEditPopups(!showEditPopups);
                                } }
                                p={ 2 }
                                _hover={ { backgroundColor: 'AVColor.hovercolor2' } }
                              >
                                <HStack alignItems={ 'center' } space={ 2 }>
                                  <EditIcon size={ '18px' } color={ 'AVColor.secondaryText' } />
                                  <Text>{ Loc.currentLang.menu.edit }</Text>
                                </HStack>
                              </Pressable>) }
                              { item?.ptype !== 'S' && <Divider thickness={ '0.5' } /> }

															<Pressable
																onPress={() => {
																	handleDeletePost();
																}}
																p={2}
																_hover={{ backgroundColor: "AVColor.hovercolor2" }}>
																<HStack alignItems={"center"} space={2}>
																	<DeleteIcon size={"18px"} color={"AVColor.secondaryText"} />
																	<Text>{Loc.currentLang.menu.delete} </Text>
																</HStack>
															</Pressable>
														</>
													)}
													{item.modStatus === "NR" && (
														<>
															<Pressable
																onPress={() => {
																	handleAcceptedPost(item);
																}}
																p={2}
																_hover={{ backgroundColor: "AVColor.hovercolor2" }}>
																<HStack alignItems={"center"} space={2}>
																	<TickIcon size={"18px"} color={"AVColor.secondaryText"} />
																	<Text>{Loc.currentLang.menu.accepted}</Text>
																</HStack>
															</Pressable>
															<Divider thickness={"0.5"} />
															<Pressable
																onPress={() => {
																	handleDeletePost();
																}}
																p={2}
																_hover={{ backgroundColor: "AVColor.hovercolor2" }}>
																<HStack alignItems={"center"} space={2}>
																	<CrossIcon size={"18px"} color={"AVColor.secondaryText"} />
																	<Text>{Loc.currentLang.menu.rejected}</Text>
																</HStack>
															</Pressable>
														</>
													)}
												</VStack>
											)}
										</AVStack>


                    <Box overflow={ 'hidden' }>
                                          <Text>{ type === 'ALL' ? <AVDescription text={ item?.text } /> : type == 'IMG' ? <AVDescription text={ previewitem?.text } /> : <AVDescription text={ item?.text } /> }</Text>
                    </Box>
                    <Divider mt={ 1 } />
                    <HStack>
                      <PostActions
                        item={ type === 'ALL' ? item : type== 'IMG' ? previewitem : !item ? previewitem : commentItem ? commentItem : item }
                        showFBEmoji={ showFBEmoji }
                        handleComment={ handleComment }
                        props={ props }
                        showComment={ showComment }
                        handleLikePost={ handleLikePost }
                        setShowFBEmoji={ setShowFBEmoji }
                        setCommentValue={ setCommentValue }
                        position={ position }
                        handleClick={ handleClick }
                        handleClicks={ handleClicks }
                        currentUser={ currentUser }
                        handleAddComment={ handleAddComment }
											  handleremoveLike={ handleremoveLike }
											  handleImpression={ handleImpression }
											  modalVisible={ modalVisible }
											  setModalVisible={ setModalVisible }
											  impressionDetails={ impressionDetails }
											  emojiMapping={ emojiMapping }
											  sad={ sad }
											  like={ like }
											  heart={ heart }
											  wow={ wow }
											  unlike={ unlike }
											  cheer={ cheer }
											  question={ question }
											  mad={ mad }
											  lol={ lol }
											  broken={ broken }
											  nsad={ nsad }
											  nlike={ nlike }
											  nheart={ nheart }
											  nwow={ nwow }
											  nunlike={ nunlike }
											  ncheer={ ncheer }
											  nquestion={ nquestion }
											  nmad={ nmad }
											  nlol={ nlol }
											  nbroken={ nbroken }
											  selectedTab={ selectedTab }
											  calculateTimeDifference={ calculateTimeDifference }
											  handleImpressionpop={ handleImpressionpop }
											  navigate={navigate }
                        handlePoint={ handlePoint } showDetails={ undefined } />
                    </HStack>
                    <Divider my={ 1 } />
                    {/*  detailed view comments // Todo create a component while refactoring  */ }
                    <HStack alignItems='center' px={ 3 } space={ 2 } my={ 2 }>
                      <Pressable
                        onPress={ () =>
                          navigate(`/profile/${ currentUser?.id }/${ currentUser?.region }`, {
                            state: { user: currentUser },
                          })
                        }
                      >
                        <ProfilePic width={ 35 } height={ 35 } link={ Person.getFk(Person.self()) } />
                      </Pressable>
                      <HStack flex={ 1 } marginTop={ 2 }>
                        <HStack flex={ 2 } space={ 1 }>
                          <HStack flex={ 2 } borderRadius={ 'full' } bgColor={ 'AVColor.inputBg' } my={ 3 }>
                            <Input
                              ref={ focusref }
                              width={ '100%' }
                              size='xl'
                              outlineColor={ 'transparent' }
                              _focus={ { bgColor: 'AVColor.inputBg', borderRadius: 'full' } }
                              focusOutlineColor={ 'transparent' }
                              value={ commentValue }
                              placeholder={ Loc.currentLang.placeholderText.commentPlaceholder }
                              borderRadius={ 0 }
                              borderWidth={ 0 }
                              color={ 'black' }
                              placeholderTextColor={ 'gray.500' }
                              onChangeText={ ( e ) => handleAddComment( e ) }
                              onKeyPress={ handleKeyPress }
                              multiline
                                                          
                            />
                          </HStack>
                          <HStack alignItems={ 'center' }>
                            { (isAddComment || commentValue || showFilePicker) && (
                              <Button
                                bgColor={ 'none' }
                                isLoading={ isCommentLoading }
                                isDisabled={ isLikeLoading }
                                _loading={ { bgColor: 'AVColor.primary' } }
                                onPress={ handlePostComment }
                              >
                                <PaperPlaneIcon color={ 'AVColor.primary' } size={ '25px' } />
                              </Button>
                            ) }
                            <Pressable
                              onPress={ () => {
                                setShowEmoji(!showEmoji);
                                setShowFilePicker(false);
                              } }
                              mx={ 2 }
                            >
                              <SvgIcon name='HAPPY' size={ 20 } />
                            </Pressable>
                            <Pressable onPress={ handleFilePicker }>
                              <SvgIcon name={ 'ADD_PHOTO' } size={ 20 } />
                            </Pressable>
                          </HStack>
                        </HStack>
                      </HStack>
                    </HStack>

                    {/* end */ }
                    { showEmoji && (
                      <VStack
                        ref={ menuRef }
                        alignItems={ 'center' }
                        justifyContent={ 'center' }
                        mx={ '10px' }
                        backgroundColor={ 'AVColor.hovercolor2' }
                        p={ 1 }
                        borderRadius={ 8 }
                      >
                        <AVEmojiPicker onEmojiSelect={ (e) => { return onEmojiClick(e); } } />
                      </VStack>
                    ) }
                    <VStack justifyContent={ 'flex-start' } alignItems={ 'flex-start' }>
                      { showFilePicker && (
                        <Box
                          minHeight={ 220 }
                          height={ 'auto' }
                          borderWidth='1'
                          rounded='sm'
                          borderStyle={ 'dashed' }
                          width={ '90%' }
                          marginTop={ 2 }
                          marginLeft={ 6 }
                          marginBottom={ 6 }
                          justifyContent={ 'center' }
                        >
                          {/* <FilePicker getPostGallery={getImageGalleryFromFilePickerCreateConvo} />*/ }
                          <div className='overflow_a scrollbar'>
                            {/*{ images?.length > 0 && ( <Button*/ }
                            {/*    position={ 'absolute' }*/ }
                            {/*    isDisabled={ isCommentLoading }*/ }
                            {/*    right={ '10px' }*/ }
                            {/*    top={ '10px' }*/ }
                            {/*    backgroundColor={ '#fffff' }*/ }
                            {/*    p={ 2 }*/ }
                            {/*    zIndex={ 999 }*/ }
                            {/*    rounded='full'*/ }
                            {/*    onPress={ () => { handleimagecancel() } }*/ }
                            {/*>*/ }
                            {/*    <CloseIcon size={ '20px' } color={ 'black' } />*/ }
                            {/*</Button>)}*/ }
                            <input
                              type='file'
                              accept='image/jpeg,image/png,image/jfif,image/webp,image/gif,video/mp4,'
                              multiple
                              hidden
                              ref={ imageInputRef }
                              onChange={ handleImages }
                            />
                            <HStack my={ 3 } mx={ 5 } space={ 4 } alignItems={ 'center' } zIndex={ 0 }>
                              { (commentMedia?.length) ? (
                                <RenderImages
                                  images={ images }
                                  imageInputRef={ imageInputRef }
                                  setImages={ setImages }
                                  setvideos={ setvideos }
                                  videos={ videos }
                                  commentMedia={ commentMedia }
                                  setMedia={ setMedia }
                                  setGallery={ setGallery }
                                  setCommentMedia={ setCommentMedia }
                                  isCommentLoading={ isCommentLoading } handleimgCancel={ undefined } />
                              ) : (
                                <Box
                                  //my={ 1 }
                                  // mx={ 3 }
                                  borderWidth={ 1 }
                                  borderRadius={ 5 }
                                  overflow={ 'hidden' }
                                  borderColor={ 'avcolors.black' }
                                  borderStyle={ 'none' }
                                  maxH={ '300px' }
                                  height='100%'
                                  width='100%'
                                  overflowY={ 'auto' }
                                //  zIndex={ 0 }
                                >
                                  <Pressable onPress={ () => imageInputRef?.current.click() }>
                                    <VStack
                                      alignItems={ 'center' }
                                      justifyContent={ 'center' }
                                      minH={ '150px' }
                                      space={ 2 }
                                    >
                                      <Box
                                        backgroundColor={ 'AVColor.grey' }
                                        p={ 2 }
                                        borderRadius='full'
                                      >
                                        <AddIcon color={ 'AVColor.secondary' } />
                                      </Box>
                                      <Text fontSize={ 14 } color={ 'AVColor.grey' }>
                                        { Loc.currentLang.menu.imageOrVideo }
                                      </Text>
                                    </VStack>
                                  </Pressable>
                                </Box>
                              ) }
                            </HStack>
                          </div>
                        </Box>
                      ) }
                      { type === 'ALL' &&
                        item?.children?.map((subitem) => (
                            subitem?.status !== 'Deleted' && (
                          <Comment
                                    subitem={ subitem }
                                    setEditItem={ setEditItem }
                                    setShowCreatePost={ setShowModal }
                                    data={ props }
                                    showFeedDetails={ showFeedDetails }
                                    setIsFullScreen={ setIsFullScreen }
                                    setIscommentItem={ setIscommentItem }
                                    setIsCommentSlider={ setIsCommentSlider }
                                    key={ subitem?.id }
                                    handleEditPost={ handleEditPost }
                                    showDetails={ showDetails }
                                    handleDeleteComment={ handleDeleteComment }
                          />)
                        )) }

                      { type !== 'ALL' &&
                        previewitem?.children?.map((subitem) => (
                            subitem?.status !== 'Deleted' && (
                          <Comment
                                    subitem={ subitem }
                                    setEditItem={ setEditItem }
                                    setShowCreatePost={ setShowModal }
                                    data={ props }
                                    key={ subitem?.id }
                                    handleEditPost={ handleEditPost }
                                    showDetails={ showDetails }
                                    showFeedDetails={ showFeedDetails }
                                    setIsFullScreen={ setIsFullScreen }
                                    setIscommentItem={ setIscommentItem }
                                    setIsCommentSlider={ setIsCommentSlider }
                                    handleDeleteComment={ handleDeleteComment }
                          />)
                        )) }
                    </VStack>
                  </VStack>
                ) }
              </HStack>
            </VStack>
            {/* </ScrollView> */ }
          </div>
        </Box>
      ) }
      { showDetails && isMobile && (
        <Box
        ref={menuRef}
          position={ 'fixed' }
          top={ 0 }
          bottom={ 0 }
          left={ 0 }
          right={ 0 }
          justifyContent={ 'space-between' }
          backgroundColor={ 'AVColor.overlay' }
          zIndex={ 9999 }
        >
          <div
            style={ {
              position: 'fixed',
              top: '53%',
              left: '50%',
              transform: 'translate(-53%, -50%)',
              background: fullscreen ? '#000000' : '#ffffff',
              boxShadow:
                '0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5)',
              width: isMobile ? '90%' : '55%',
              borderRadius: '8px',
              minHeight: '400px',
            } }
          >
            <VStack padding={ 3 } justifyContent={ 'space-between' } width={ '100%' }>
              <HStack justifyContent={ 'flex-end' }>
                <AVCancel callback={ handleCancel } color={ undefined } size={ undefined } />
              </HStack>

              <VStack space={ 3 } width={ '100%' } height={ '400px' }>
                <HStack height={ !fullscreen && imageUrls.length > 0 ? '200px' : imageUrls.length == 0 && !fullscreen ? 0 : '400px' }>
                  { imageUrls.length > 0 && (
                    <VStack
                      // width={'65%'}
                      flex={ 3 }
                      padding={ 2.5 }
                      alignItems={ 'flex-start' }
                      justifyContent={ 'flex-start' }
                      backgroundColor={ '#000' }
                      borderRadius={ 3 }
                      maxHeight={ '70vh' }
                      overflow={ !fullscreen ? 'auto' : '' }
                    >
                      <div style={ { width: '100%', height: '100%' } }>
                        <AVImageSlider
                          slideItems={ item }
                          previewitem={ previewitem }
                          setpreviewitem={ setpreviewitem }
                          type={ type }
                          fullscreen={ fullscreen }
                          setIsFullScreen={ setIsFullScreen }
                          setCommentValue={setCommentValue}
                          settype={ settype } setShowDetails={ undefined } setIscommentItem={ undefined } setIsCommentSlider={ undefined } isCommentSlider={ undefined } handleslideChange={ undefined } isHeader={ undefined } setShowPreview={ undefined } setHeader={ undefined } />
                      </div>
                    </VStack>
                  ) }
                </HStack>
                { !fullscreen && (<VStack
                  flex={ 2 }
                  space={ 2 }
                  overflow={ 'hidden' }
                  overflowY={ 'scroll' }
                  height={ '200px' }
                >
                  <PostHeader
                    entitydatalist={ entitydatalist }
                    item={ type === 'ALL' ? item : previewitem }
                    handleEditPopup={ handleEditPopup }
                    showEditPopup={ showEditPopup }
                    userInfo={ userInfo }
                    setShowEditPopup={ setShowEditPopup }
                    handleDeletePost={ handleDeletePost }
                    handleReportPost={ handleReportPost }
                    handleEditPost={ handleEditPost }
                    handleAcceptedPost={ handleAcceptedPost }
                    showEditPost={ showEditPost }
                    menuRef={ menuRef }
                    showModal={ showModal }
                    setShowModal={ setShowModal }
									  isDeleted={ isDeleted }
									  calculateTimeDifference={ calculateTimeDifference }
                    setShowEditPost={ setShowEditPost }
                    currentUser={ currentUser }
                    isMobile={ isMobile } handleLikePost={ undefined } refetch={ undefined } reload={ undefined } tooltipProps={ undefined } setEditItem={ undefined } showDetails={ undefined } />
                  { showEditPopups && (
                    <VStack
                      ref={ menuRef }
                      position={ 'absolute' }
                      width='125px'
                      bgColor='#fff'
                      borderColor='coolGray.200'
                      borderWidth='1'
                      shadow={ 1 }
                      zIndex={ 1 }
                      top={ '43px' }
                      right={ 1 }
                      borderRadius={ 5 }
                    >
                      { item?.person?.id !== currentUser?.id && item?.modStatus !== 'NR' && (
                        <>
                          <Pressable
                            onPress={ () => {
                              handleReportPost();
                            } }
                            p={ 2 }
                            _hover={ { backgroundColor: 'AVColor.hovercolor2' } }
                          >
                            <HStack alignItems={ 'center' } space={ 2 }>
                              <Image source={ report } alt='report' size='15' />
                              <Text>{ Loc.currentLang.menu.report }</Text>
                            </HStack>
                          </Pressable>
                        </>
                      ) }
                      { item?.person?.id === currentUser?.id && item?.modStatus !== 'NR' && (
                        <>
                          { item?.ptype !== 'S' && (<Pressable
                            onPress={ () => {
                              setShowModal(!showModal);
                              setShowEditPopups(!showEditPopups);
                              setEditItem(item);
                            } }
                            p={ 2 }
                            _hover={ { backgroundColor: 'AVColor.hovercolor2' } }
                          >
                            <HStack alignItems={ 'center' } space={ 2 }>
                              <EditIcon size={ '18px' } color={ 'AVColor.secondaryText' } />
                              <Text>{ Loc.currentLang.menu.edit }</Text>
                            </HStack>
                          </Pressable>) }
                          { item?.ptype !== 'S' && (<Divider thickness={ '0.5' } />) }

														<Pressable
															onPress={() => {
																handleDeletePost();
															}}
															p={2}
															_hover={{ backgroundColor: "AVColor.hovercolor2" }}>
															<HStack alignItems={"center"} space={2}>
																<DeleteIcon size={"18px"} color={"AVColor.secondaryText"} />
																<Text>{Loc.currentLang.menu.delete} </Text>
															</HStack>
														</Pressable>
													</>
												)}
												{item.modStatus === "NR" && (
													<>
														<Pressable
															onPress={() => {
																handleAcceptedPost(item);
															}}
															p={2}
															_hover={{ backgroundColor: "AVColor.hovercolor2" }}>
															<HStack alignItems={"center"} space={2}>
																<TickIcon size={"18px"} color={"AVColor.secondaryText"} />
																<Text>{Loc.currentLang.menu.accepted}</Text>
															</HStack>
														</Pressable>
														<Divider thickness={"0.5"} />
														<Pressable
															onPress={() => {
																handleDeletePost();
															}}
															p={2}
															_hover={{ backgroundColor: "AVColor.hovercolor2" }}>
															<HStack alignItems={"center"} space={2}>
																<CrossIcon size={"18px"} color={"AVColor.secondaryText"} />
																<Text>{Loc.currentLang.menu.rejected}</Text>
															</HStack>
														</Pressable>
													</>
												)}
											</VStack>
										)}
										<Box maxH={"100px"} overflow={"hidden"}>
											<Text>{item?.text}</Text>
										</Box>
										<Divider mt={1} />
										<HStack>
											<PostActions
										  item={ type === "ALL" ? item : previewitem }
										  handleImpression={ handleImpression }
												showFBEmoji={showFBEmoji}
												props={props}
												handleComment={handleComment}
												showComment={showComment}
												handleLikePost={handleLikePost}
												setShowFBEmoji={setShowFBEmoji}
												setCommentValue={setCommentValue}
												position={position}
												handleClick={handleClick}
												handleClicks={handleClicks}
												currentUser={currentUser}
												handleAddComment={handleAddComment}
												handleremoveLike={handleremoveLike}
												handlePoint={handlePoint}
										  showDetails={ undefined }
										  modalVisible={ modalVisible }
										  setModalVisible={ setModalVisible }
										  impressionDetails={ impressionDetails }
										  emojiMapping={ emojiMapping }
										  sad={ sad }
										  like={ like }
										  heart={ heart }
										  wow={ wow }
										  unlike={ unlike }
										  cheer={ cheer }
										  question={ question }
										  mad={ mad }
										  lol={ lol }
										  broken={ broken }
										  nsad={ nsad }
										  nlike={ nlike }
										  nheart={ nheart }
										  nwow={ nwow }
										  nunlike={ nunlike }
										  ncheer={ ncheer }
										  nquestion={ nquestion }
										  nmad={ nmad }
										  nlol={ nlol }
										  nbroken={ nbroken }
										  selectedTab={ selectedTab }
										  handleImpressionpop={ handleImpressionpop }
										  calculateTimeDifference={ calculateTimeDifference }
										  navigate={ navigate }


											/>
										</HStack>
										<Divider my={1} />
										{/*  detailed view comments // Todo create a component while refactoring  */}

										<HStack alignItems="center" px={3} space={2} my={2}>
											<Pressable
												onPress={() =>
													navigate(`/profile/${currentUser?.id}/${currentUser?.region}`, {
														state: { user: currentUser },
													})
												}>
												<ProfilePic width={35} height={35} link={Person.getFk(Person.self())} />
											</Pressable>
											<HStack flex={1} marginTop={2}>
												<HStack flex={2} space={1}>
													<HStack flex={2} borderRadius={"full"} bgColor={"AVColor.inputBg"} my={3}>
														<Input
															ref={commentsRef}
															width={"100%"}
															size="xl"
															outlineColor={"transparent"}
															_focus={{ bgColor: "AVColor.inputBg", borderRadius: "full" }}
															focusOutlineColor={"transparent"}
															value={commentValue}
															placeholder={Loc.currentLang.placeholderText.commentPlaceholder}
															borderRadius={0}
															borderWidth={0}
															color={"black"}
															placeholderTextColor={"gray.500"}
															onChangeText={e => handleAddComment(e)}
															onKeyPress={handleKeyPress}
															multiline
														/>
													</HStack>
													<HStack alignItems={"center"}>
														{(isAddComment || commentValue || showFilePicker) && (
															<Button
																bgColor={"none"}
																isLoading={isCommentLoading}
																_loading={{ bgColor: "AVColor.primary" }}
																onPress={handlePostComment}>
																<PaperPlaneIcon color={"AVColor.primary"} size={"25px"} />
															</Button>
														)}
														<Pressable
															onPress={() => {
																setShowEmoji(!showEmoji);
																setShowFilePicker(false);
															}}
															mx={2}>
															<SvgIcon name="HAPPY" size={20} />
														</Pressable>
														<Pressable onPress={handleFilePicker}>
															<SvgIcon name={"ADD_PHOTO"} size={20} />
														</Pressable>
													</HStack>
												</HStack>
											</HStack>
										</HStack>

                  {/* end */ }
                  { showEmoji && (
                    <VStack
                      //ref={ menuRef }
                      alignItems={ 'center' }
                      justifyContent={ 'center' }
                      mx={ '10px' }
                      backgroundColor={ 'AVColor.hovercolor2' }
                      p={ 1 }
                      borderRadius={ 8 }
                    >
                      <AVEmojiPicker onEmojiSelect={ (e) => { return onEmojiClick(e); } } />
                    </VStack>
                  ) }
                  <VStack justifyContent={ 'flex-start' } alignItems={ 'flex-start' }>
                    { showFilePicker && (
                      <Box
                        minHeight={ 220 }
                        height={ 'auto' }
                        borderWidth='1'
                        rounded='sm'
                        borderStyle={ 'dashed' }
                        width={ '90%' }
                        marginTop={ 2 }
                        marginLeft={ 6 }
                        marginBottom={ 6 }
                        justifyContent={ 'center' }
                      >
                        {/* <FilePicker getPostGallery={getImageGalleryFromFilePickerCreateConvo} />*/ }
                        <div className='overflow_a scrollbar'>
                          {/*{ images?.length > 0 && (<Button*/ }
                          {/*    position={ 'absolute' }*/ }
                          {/*    isDisabled={ isCommentLoading }*/ }
                          {/*    right={ '10px' }*/ }
                          {/*    top={ '10px' }*/ }
                          {/*    backgroundColor={ '#fffff' }*/ }
                          {/*    p={ 2 }*/ }
                          {/*    zIndex={ 999 }*/ }
                          {/*    rounded='full'*/ }
                          {/*    onPress={ () => { handleimagecancel() } }*/ }
                          {/*>*/ }
                          {/*    <CloseIcon size={ '20px' } color={ 'black' } />*/ }
                          {/*</Button>)}*/ }
                          <input
                            type='file'
                            accept='image/jpeg,image/png,image/jfif,image/webp,image/gif,video/mp4,'
                            multiple
                            hidden
                            ref={ imageInputRef }
                            onChange={ handleImages }
                          />
                          <HStack my={ 3 } mx={ 5 } space={ 4 } alignItems={ 'center' } zIndex={ 0 }>
                            { (commentMedia?.length) ? (
                              <RenderImages
                                images={ images }
                                imageInputRef={ imageInputRef }
                                setImages={ setImages }
                                setvideos={ setvideos }
                                videos={ videos } commentMedia={ commentMedia }

                                setMedia={ setMedia }
                                setGallery={ setGallery }
                                setCommentMedia={ setCommentMedia } handleimgCancel={ undefined } isCommentLoading={ undefined } />
                            ) : (
                              <Box
                                //my={ 1 }
                                // mx={ 3 }
                                borderWidth={ 1 }
                                borderRadius={ 5 }
                                overflow={ 'hidden' }
                                borderColor={ 'avcolors.black' }
                                borderStyle={ 'none' }
                                maxH={ '300px' }
                                height='100%'
                                width='100%'
                                overflowY={ 'auto' }
                              //  zIndex={ 0 }
                              >
                                <Pressable onPress={ () => imageInputRef?.current.click() }>
                                  <VStack
                                    alignItems={ 'center' }
                                    justifyContent={ 'center' }
                                    minH={ '150px' }
                                    space={ 2 }
                                  >
                                    <Box backgroundColor={ 'AVColor.grey' } p={ 2 } borderRadius='full'>
                                      <AddIcon color={ 'AVColor.secondary' } />
                                    </Box>
                                    <Text fontSize={ 14 } color={ 'AVColor.grey' }>
                                      { Loc.currentLang.menu.imageOrVideo }
                                    </Text>
                                  </VStack>
                                </Pressable>
                              </Box>
                            ) }
                          </HStack>
                        </div>
                      </Box>
                    ) }
                    { type === 'ALL' &&
                      item?.children?.map((subitem) => (
                          subitem?.status !== 'Deleted' && (
                        <Comment
                          subitem={ subitem }
                          setEditItem={ setEditItem }
                          setShowCreatePost={ setShowModal }
                          data={ props }
                          key={ subitem?.id }
                          handleEditPost={ handleEditPost }
                          showDetails={ showDetails }
                          handleDeleteComment={ handleDeleteComment }
                        />)
                      )) }

                    { type !== 'ALL' &&
                      previewitem?.children?.map((subitem) => (
                          subitem?.status !== 'Deleted' && (
                        <Comment
                          subitem={ subitem }
                          setEditItem={ setEditItem }
                          setShowCreatePost={ setShowModal }
                          data={ props }
                          key={ subitem?.id }
                          handleEditPost={ handleEditPost }
                          showDetails={ showDetails }
                          handleDeleteComment={ handleDeleteComment }
                        />)
                      )) }
                  </VStack>
                </VStack>) }
              </VStack>
            </VStack>
          </div>
        </Box>
      ) }
      { showunauthorizedDetails && (
        <Box
          position={ 'fixed' }
          top={ 0 }
          bottom={ 0 }
          left={ 0 }
          right={ 0 }
          display={ 'flex' }
          alignItems={ 'center' }
          justifyContent={ 'center' }
          backgroundColor={ 'rgba(0, 0, 0, 0.25)' }
          zIndex={ 9999 }
        >
          <div className={ `customModal` }>
            <div className={ `show_unauthorized_details` }>
              <VStack>
                <Pressable
                  padding={ 1.5 }
                  rounded={ 'full' }
                  zIndex={ 999 }
                  position={ 'absolute' }
                  top={ '20px' }
                  right={ '20px' }
                  _hover={ { backgroundColor: 'AVColor.hovercolor2' } }
                  backgroundColor={ 'AVColor.buttonBg' }
                  onPress={ () => {
                    handleCancel();
                  } }
                >
                  <CloseIcon size={ '16px' } color={ 'AVColor.secondaryText' } />
                </Pressable>
                <HStack marginLeft={ '208px' } marginTop={ '46px' }>
                  <img src={ unauthorPopup } style={ { width: '101px', height: '112px' } } />
                </HStack>
                <HStack justifyContent={ 'center' }>
                  <Text color={ '#32AE78' } fontSize={ '24px' } textAlign={ 'center' }>
                   This post has been Deleted
                  </Text>
                </HStack>
                <HStack justifyContent={ 'center' } marginTop={ '5px' }>
                  <Text color={ '#000000' }>{ Loc.currentLang.menu.unableProcess }</Text>
                </HStack>
                <HStack justifyContent={ 'center' } marginTop={ '55px' }>
                  <Button
                    rounded='full'
                    backgroundColor={ '#EEEEEE' }
                    width={ '108px' }
                    height={ '40px' }
                    onPress={ () => {
                      handleCancel();
                    } }
                  >
                    <Text fontSize={ '16px' } color={ 'rgba(0,0,0,0.5)' } fontWeight={ 'bold' }>
                      { Loc.currentLang.menu.ok }
                    </Text>
                  </Button>
                </HStack>
              </VStack>
            </div>
          </div>
        </Box>
      ) }

			{((!isDeleted && item?.status !== "Deleted" && item.modStatus !== "R" && item?.modStatus !== "NR") ||
				(item?.modStatus && item?.modStatus === "NR" && item?.owner?.id == currentUser?.id)) && (
				<VStack
					shadow={1}
					borderRadius={{
						base: 0,
						sm: 0,
						md: 8,
						lg: 8,
						xl: 8,
					}}
					mb={{
						base: 1,
						sm: 1,
						md: 15,
						lg: 15,
						xl: 15,
					}}
					paddingBottom={15}
					bgColor="AVColor.white"
					borderWidth={0}
					width={"100%"}
					alignItems={"center"}
					// borderWidth={2}
					// maxHeight={'600px'}
				>
					<VStack width={"100%"}>
						{/* header */}

						<PostHeader
							entitydatalist={entitydatalist}
							item={item}
							userInfo={userInfo}
							handleEditPopup={handleEditPopup}
							showEditPopup={showEditPopup}
							setShowEditPopup={setShowEditPopup}
							handleDeletePost={handleDeletePost}
							handleReportPost={handleReportPost}
							handleEditPost={handleEditPost}
							handleAcceptedPost={handleAcceptedPost}
							showEditPost={showEditPost}
							showModal={showModal}
							setShowModal={setShowModal}
							menuRef={menuRef}
							isDeleted={isDeleted}
							setShowEditPost={setShowEditPost}
							currentUser={currentUser}
						  tooltipProps={ tooltipProps }
						  calculateTimeDifference={ calculateTimeDifference }
							isMobile={isMobile}
							handleLikePost={undefined}
							refetch={undefined}
							reload={undefined}
							setEditItem={undefined}
							showDetails={undefined}
						/>
						<AHStack padding={4} paddingHorizontal={10} marginTop={item?.text ? 8 : ""}>
							{/* <Text numberOfLines={showMore ? 1000 : truncatedText ? 5 : 1000}>{renderText()}</Text> */}
							<PostText item={item} />
						</AHStack>

						{item?.media?.type?.startsWith("image") ||
						item?.media?.type?.startsWith("video") ||
						item?.galleryPost?.length > 0 ? (
							<Pressable
								onPress={() => {
									showFeedDetails(item, "IMG");
									setShowEditPopups(false);
								}}>
								<LoadMedia
									item={item}
									videoStatus={videoStatus}
									setVideoStatus={setVideoStatus}
									useStyles={useStyles}
									setVideoItem={setVideoItem}
								/>
							</Pressable>
						) : (
							<Pressable
								onPress={() => {
									handledocument(item);
								}}>
								<LoadMedia
									item={item}
									videoStatus={videoStatus}
									setVideoStatus={setVideoStatus}
									useStyles={useStyles}
									setVideoItem={setVideoItem}
								/>
							</Pressable>
						)}
						<PostActions
							item={item}
							props={props}
							showFBEmoji={showFBEmoji}
							handleComment={handleComment}
							showComment={showComment}
							handleLikePost={handleLikePost}
							setShowFBEmoji={setShowFBEmoji}
							setCommentValue={setCommentValue}
							position={position}
							handleClick={handleClick}
							handleClicks={handleClicks}
							currentUser={currentUser}
							handleAddComment={handleAddComment}
							handleremoveLike={handleremoveLike}
							handlePoint={handlePoint}
						  showDetails={ undefined }
						  modalVisible={ modalVisible }
						  setModalVisible={ setModalVisible }
						  impressionDetails={ impressionDetails }
						  emojiMapping={ emojiMapping }
						  sad={ sad }
						  like={ like }
						  heart={ heart }
						  wow={ wow }
						  unlike={ unlike }
						  cheer={ cheer }
						  question={ question }
						  mad={ mad }
						  lol={ lol }
						  broken={ broken }
						  nsad={ nsad }
						  nlike={ nlike }
						  nheart={ nheart }
						  nwow={ nwow }
						  nunlike={ nunlike }
						  ncheer={ ncheer }
						  nquestion={ nquestion }
						  nmad={ nmad }
						  nlol={ nlol }
						  nbroken={ nbroken }
						  selectedTab={ selectedTab }
						  handleImpression={ handleImpression }
						  calculateTimeDifference={ calculateTimeDifference }
						  handleImpressionpop={ handleImpressionpop }
						  navigate={ navigate }



						/>
						{/* end */}
						{/* input */}
						{(item?.allowReply == true || currentUser?.id === item?.person?.id || props?.iscommentAccess) && (
							<HStack alignItems="center" px={3} mb={2} flex={1} space={2}>
								<Pressable
									onPress={() =>
										navigate(`/profile/${currentUser?.id}/${currentUser?.region}`, {
											state: { user: currentUser },
										})
									}>
									<ProfilePic width={35} height={35} link={Person.getFk(Person.self())} />
								</Pressable>

                <HStack flex={ 1 }>
                  <HStack flex={ 2 } space={ 1 }>

                    <HStack flex={ 2 } borderRadius={ 'full' } bgColor={ 'AVColor.inputBg' }>
                      <Input
                        ref={ commentsRef }
                        width={ '100%' }
                        size='xl'
                        outlineColor={ 'transparent' }
                        _focus={ { bgColor: 'AVColor.inputBg', borderRadius: 'full' } }
                        focusOutlineColor={ 'transparent' }
                        value={ commentValue }
                        placeholder={ Loc.currentLang.placeholderText.commentPlaceholder }
                        borderRadius={ 0 }
                        borderWidth={ 0 }
                        color={ 'black' }
                        placeholderTextColor={ 'gray.500' }
                        onChangeText={(e) => handleAddComment(e)}
                        onKeyPress={handleKeyPress}
                        multiline
                                              
                      />
                    </HStack>
                    <HStack alignItems={ 'center' }>
                      { (isAddComment || commentValue || showFilePicker) && (
                        <Button
                          bgColor={ 'none' }
                          isLoading={ isCommentLoading }
                          _loading={ { bgColor: 'AVColor.primary' } }
                          onPress={ handlePostComment }
                        >
                          <PaperPlaneIcon color={ 'AVColor.primary' } size={ '25px' } />
                        </Button>
                      ) }
                      <Pressable
                        onPress={ () => {
                          setShowEmoji(!showEmoji);
                        } }
                        mx={ 2 }
                      >
                        <SvgIcon name='HAPPY' size={ 20 } />
                      </Pressable>
                      <Pressable onPress={ handleFilePicker }>
                        <SvgIcon name={ 'ADD_PHOTO' } size={ 20 } />
                      </Pressable>
                    </HStack>
                  </HStack>
                </HStack>
              </HStack>
            ) }

            { showEmoji && (
              <VStack
                //ref={ menuRef }
                justifyContent={ 'center' }
                mx={ '10px' }
                backgroundColor={ 'AVColor.hovercolor2' }
                p={ 1 }
                marginBottom={ '15px' }
                borderRadius={ 8 }
              >

                <AVEmojiPicker onEmojiSelect={ (e) => { return onEmojiClick(e); } } />
              </VStack>
            ) }
            <VStack justifyContent={ 'flex-start' } alignItems={ 'flex-start' }>
              { showFilePicker && (
                <Box
                  minHeight={ 220 }
                  height={ 'auto' }
                  borderWidth='1'
                  rounded='sm'
                  borderStyle={ 'dashed' }
                  width={ '90%' }
                  marginTop={ 2 }
                  marginLeft={ 6 }
                  marginBottom={ 6 }
                  justifyContent={ 'center' }
                >
                  {/*  <FilePicker getPostGallery={getImageGalleryFromFilePickerCreateConvo} />*/ }
                  <div className='overflow_a scrollbar'>
                    {/* {images?.length > 0 && ( <Button*/ }
                    {/*    position={ 'absolute' }*/ }
                    {/*    isDisabled={ isCommentLoading }*/ }
                    {/*    right={ '10px' }*/ }
                    {/*    top={ '10px' }*/ }
                    {/*    backgroundColor={ '#fffff' }*/ }
                    {/*    p={ 2 }*/ }
                    {/*    zIndex={ 999 }*/ }
                    {/*    rounded='full'*/ }
                    {/*    onPress={ () => { handleimagecancel() } }*/ }
                    {/*>*/ }
                    {/*    <CloseIcon size={ '20px' } color={ 'black' } />*/ }
                    {/*</Button>)}*/ }
                    <input
                      type='file'
                      accept='image/jpeg,image/png,image/jfif,image/webp,image/gif,video/mp4,'
                      multiple
                      hidden
                      ref={ imageInputRef }
                      onChange={ handleImages }
                    />
                    <HStack my={ 3 } mx={ 5 } space={ 4 } alignItems={ 'center' } zIndex={ 0 }>
                      { (commentMedia?.length) ? (
                        <RenderImages
                          images={ images }
                          imageInputRef={ imageInputRef }
                          setImages={ setImages }
                          setvideos={ setvideos }
                          videos={ videos } commentMedia={ commentMedia }

                          handleimgCancel={ handleimgCancel }
                          setMedia={ setMedia }
                          setGallery={ setGallery }
                          setCommentMedia={ setCommentMedia } isCommentLoading={ undefined } />
                      ) : (
                        <Box
                          borderWidth={ 1 }
                          borderRadius={ 5 }
                          overflow={ 'hidden' }
                          borderColor={ 'avcolors.black' }
                          borderStyle={ 'none' }
                          maxH={ '300px' }
                          height='100%'
                          width='100%'
                          overflowY={ 'auto' }
                        >
                          <Pressable
                            ref={ menuRef }
                            onPress={ () => imageInputRef?.current.click() }>
                            <VStack
                              alignItems={ 'center' }
                              justifyContent={ 'center' }
                              minH={ '150px' }
                              space={ 2 }
                            >
                              <Box backgroundColor={ 'AVColor.grey' } p={ 2 } borderRadius='full'>
                                <AddIcon color={ 'AVColor.secondary' } />
                              </Box>
                              <Text fontSize={ 14 } color={ 'AVColor.grey' }>
                                { Loc.currentLang.menu.imageOrVideo }
                              </Text>
                            </VStack>
                          </Pressable>
                        </Box>
                      ) }
                    </HStack>
                  </div>
                </Box>
                    ) }
                  { item?.children?.slice( 0, 2 ).map( ( subitem ) => (
                      subitem?.status !== 'Deleted' && subitem !== null && subitem !== undefined && (
                                  <Comment
                                      type='feed'
                                      subitem={ subitem }
                                      handleEditPost={ handleEditPost }
                                      setEditItem={ setEditItem }
                                      setShowCreatePost={ setShowModal }
                                      data={ props }
                                      key={ subitem?.id }
                                      showFeedDetails={ showFeedDetails }
                                      setIsFullScreen={ setIsFullScreen }
                                      setIscommentItem={ setIscommentItem }
                                      setIsCommentSlider={ setIsCommentSlider }
                                      showDetails={ showDetails }
                                      handleDeleteComment={ handleDeleteComment }
                                  />
                              )
                          ) ) }
              { (item?.children?.length > 1 ||
                (item?.children != undefined && item?.children[ 0 ]?.children?.length > 0)) && (
                  <VStack width={ '100%' } alignItems={ 'flex-end' } px={ 10 }>
                    <Pressable
                      onPress={ () => {
                        showFeedDetails(item, 'ALL'); handleshowComments(item);
                      } }
                    >
                      <Text color={ '#32ae78' }>{ Loc.currentLang.menu.more } ...</Text>
                    </Pressable>
                  </VStack>
                ) }
            </VStack>
          </VStack>
        </VStack>
      ) }

			{showModal && (
				<EditPostpopup
					item={editItem}
					setItem={setEditItem}
					setShowCreatePost={setShowModal}
					setShowDetails={setShowDetails}
					showModal={showModal}
					windowDimensions={undefined}
					headerHeight={undefined}
					reload={undefined}
				/>
			)}
		</>
	);
}

const EditPostMenu = ({
	item,
	showEditPopup,
	setShowEditPopup,
	handleEditPost,
	setEditItem,
	handleAcceptedPost,
	currentUser,
	handleDeletePost,
	handleReportPost,
}) => {
	const menuRef = useRef(null);
	useOutsideAlerter(menuRef);
	//Hook that alerts clicks outside of the passed ref
	function useOutsideAlerter(ref) {
		React.useEffect(() => {
			// Alert if clicked on outside of element
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					setShowEditPopup(false);
				}
			}
			// Bind the event listener
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref]);
	}
	return (
		<>
			{showEditPopup && (
				//<VStack
				<Box
					ref={menuRef}
					position={"absolute"}
					width="125px"
					bgColor="#fff"
					borderColor="coolGray.200"
					borderWidth="1"
					shadow={1}
					zIndex={1}
					top={"43px"}
					right={1}
					borderRadius={5}>
					{item?.modStatus === "NR" && item?.person?.id === currentUser?.id && (
						<>
							<Pressable
								onPress={() => {
									handleAcceptedPost(item);
								}}
								p={2}
								_hover={{ backgroundColor: "AVColor.hovercolor2" }}>
								<HStack alignItems={"center"} space={2}>
									<TickIcon size={"18px"} color={"AVColor.secondaryText"} />
									<Text>{Loc.currentLang.menu.accepted}</Text>
								</HStack>
							</Pressable>
							<Divider thickness={"0.5"} />

							<Pressable
								onPress={() => {
									handleDeletePost(item);
								}}
								p={2}
								_hover={{ backgroundColor: "AVColor.hovercolor2" }}>
								<HStack alignItems={"center"} space={2}>
									<CrossIcon size={"18px"} color={"AVColor.secondaryText"} />
									<Text>{Loc.currentLang.menu.rejected}</Text>
								</HStack>
							</Pressable>
						</>
					)}
					{item?.person?.id !== currentUser?.id && item?.modStatus !== "NR" && (
						<>
							<Pressable
								onPress={() => {
									handleReportPost(item);
								}}
								p={2}
								_hover={{ backgroundColor: "AVColor.hovercolor2" }}>
								<HStack alignItems={"center"} space={2}>
									<Image source={report} alt="report" size="15" />
									<Text>{Loc.currentLang.menu.report}</Text>
								</HStack>
							</Pressable>
						</>
					)}
					{item?.person?.id === currentUser?.id && item.modStatus !== "NR" && (
						<>
							{item?.ptype !== "S" && (
								<Pressable
									onPress={() => {
										handleEditPost(item);
									}}
									p={2}
									_hover={{ backgroundColor: "AVColor.hovercolor2" }}>
									<HStack alignItems={"center"} space={2}>
										<EditIcon size={"18px"} color={"AVColor.secondaryText"} />
										<Text>{Loc.currentLang.menu.edit}</Text>
									</HStack>
								</Pressable>
							)}
							{item?.ptype !== "S" && <Divider thickness={"0.5"} />}
							<Pressable
								onPress={() => {
									handleDeletePost(item);
								}}
								p={2}
								_hover={{ backgroundColor: "AVColor.hovercolor2" }}>
								<HStack alignItems={"center"} space={2}>
									<DeleteIcon size={"18px"} color={"AVColor.secondaryText"} />
									<Text>{Loc.currentLang.menu.delete}</Text>
								</HStack>
							</Pressable>
						</>
					)}
				</Box>
			)}
		</>
	);
};
const PostHeader = ({
	item,
	handleEditPopup,
	userInfo,
	showEditPopup,
	setShowEditPopup,
	handleDeletePost,
	handleReportPost,
	handleEditPost,
	handleAcceptedPost,
	handleLikePost,
	menuRef,
	refetch,
	isDeleted,
	reload,
	showModal,
	setShowModal,
	showEditPost,
	setShowEditPost,
	tooltipProps,
	currentUser,
	calculateTimeDifference,
	isMobile,
	setEditItem,
	showDetails,
	entitydatalist,
}) => {
	let uri = AVlink.getTummb(item?.person);

	if (item?.person?.id === AV.Person.self()?.id) {
		let link = AV.Person.getLink(AV.Person.self());
		uri = AVlink.getTummb(link);
	}

	//const reload = () => {
	//    refetch();
	//};

	const navigate = useNavigate();
	return (
		<>
			<HStack justifyContent={"space-between"} width={"100%"} px={2} pt={2}>
				<Pressable
					width={"100%"}
					onPress={() => {
						item?.person?.type == "Club" || item?.person?.type == "School"
							? navigate(`/organizations/${item?.person?.id}/${item?.person?.region}/${item?.person?.type}`, {
									state: { isCreate: false, orgType: item?.person?.type, org: item?.person },
								})
							: item?.person?.type == "LeagueTeam"
								? navigate(`/season/${item?.person?.id}/${item?.person?.region}`, {
										state: { isCreate: false, team: item?.person },
									})
								: item?.person?.type == "Team" || item?.owner?.type == "Group"
									? navigate(`/teams/${item?.person?.id}/${item?.person?.region}`, {
											state: { isCreate: false, team: item?.person },
										})
									: item?.person?.type == "Event"
										? navigate(`/Events/${item?.person?.id}/${item?.person?.region}`, {
												state: { eventdata: item?.person },
											})
										: navigate(`/profile/${item?.person?.id}/${item?.person?.region}`, {
												state: {
													user: {
														id: item?.person?.id,
														pk: item?.person?.pk,
														region: item?.person?.region,
														type: item?.person?.type,
														name: item?.person?.title,
													},
												},
											});
					}}>
					<AHStack width={"100%"}>
						<Link
							to="/"
							style={{
								backgroundImage: `url(${fallBackImage})`,
								backgroundRepeat: "no-repeat",
								width: 50,
								height: 50,
								borderRadius: "50%",
							}}>
							<ProfilePic
								width={50}
								height={50}
								link={
									currentUser?.id == item?.person?.id
										? AV.AVEntity.getLink(currentUser)
										: entitydatalist?.find((x: any) => x?.id == item?.person?.id)
											? AV.AVEntity.getLink(entitydatalist?.find((x: any) => x?.id == item?.person?.id))
											: item?.person
								}
							/>
						</Link>
						<AVStack
							justifyContent={"center"}
							marginLeft={8}
							width={
								!isMobile
									? "60%"
									: isMobile && item?.owner?.id !== userInfo?.id && item?.owner?.id !== item?.person?.id
										? "30%"
										: "70%"
							}>
							<Text
								fontSize={[16]}
								fontWeight={["bold"]}
								text-overflow={"ellipsis"}
								numberOfLines={1}
								width={isMobile ? "85%" : "100%"}>
								{ item?.person?.type == "PlayerOrg" && item?.person?.data
									? item?.person?.data
									: currentUser?.id == item?.person?.id
										? currentUser?.name
										: entitydatalist?.find((x: any) => x?.id == item?.person?.id)
											? entitydatalist?.find((x: any) => x?.id == item?.person?.id).name
											: item?.person?.title
												? item?.person?.title
												: item?.person?.name}
							</Text>
							<Text fontSize={[12, 14]}>
								<AVConverttime date={item?.createDate} page={"Post"} />
							</Text>
						</AVStack>
					</AHStack>
				</Pressable>
				{item?.owner?.id !== userInfo?.id && item?.owner?.id !== item?.person?.id && (
					<Pressable
						position={"absolute"}
						right={isMobile ? 0 : "35px"}
						onPress={() => {
							const owner = item?.owner;
							const { id, region, type, title, pk } = owner || {};

              switch (type) {
				  case 'PlayerOrg':
                  navigate(`/season/${ id }/${ region }`, {
                    state: { isCreate: false, team: owner },
                  });
                  break;
                case 'Team':
                case 'Group':
                  navigate(`/teams/${ id }/${ region }`, {
                    state: { isCreate: false, team: owner },
                  });
                  break;
                case 'Event':
                  navigate(`/teams/${ id }/${ region }`, {
                    state: { isCreate: false, team: owner },
                  });
                  break;
                case 'Person':
                  navigate(`/profile/${ id }/${ region }`, {
                    state: {
                      user: {
                        id,
                        pk,
                        region,
                        type,
                        name: title,
                      },
                    },
                  });
                  break;
                default:
                  if ([ "78b5b063-fd3e-4925-92bd-2a1888c6a46a", "b495d4e0-bf52-4690-8dc0-677db954aa7f", "b495d4e0-bf52-4690-8dc0-677db954aa71", "b495d4e0-bf52-4690-8dc0-677db954aa72" ].includes(id)) {
                    navigate(`/teams/${ id }/${ currentUser?.region }`, {
                      state: {
                        team: {
                          id: id,
                          pk: 'a46a',
                          region: currentUser?.region,
                          type: 'Organization',
                          name: 'Actavivo',
                        },
                        type: 'Feedback'
                      },
                    });
                  } else {
                      navigate( `/teams/${ id }/${ region }`, {
                          state: { isCreate: false, team: owner },
                      } );
                  }
                  break;
              }
            } }

          >
            <AHStack>
              <Link
                to='/'
                style={ {
                  backgroundImage: `url(${ fallBackImage })`,
                  backgroundRepeat: 'no-repeat',
                  width: 35,
                  height: 35,
                  borderRadius: '50%',
                } }
              >
                { item?.owner?.id === "b495d4e0-bf52-4690-8dc0-677db954aa7f" ?
                  <FeedbackIcon height={ '35' } width={ '35' } /> :
                  item?.owner?.id === "b495d4e0-bf52-4690-8dc0-677db954aa71" ?
                    <BugReportIcon height={ '35' } width={ '35' } /> :
                    item?.owner?.id === "b495d4e0-bf52-4690-8dc0-677db954aa72" ?
                      <FeatureRequestIcon height={ '35' } width={ '35' } /> : <ProfilePic width={ '35px' } height={ '35px' } link={ entitydatalist?.find((x : any) => x?.id == item?.owner?.id) ?
                        AV.AVEntity.getLink(entitydatalist?.find((x : any) => x?.id == item?.owner?.id)) : item?.owner  } /> }


              </Link>
              <AVStack justifyContent={ 'center' } marginLeft={ '10px' }>
                <Text
                  fontSize={ [ 13 ] }
                  fontWeight={ [ 'bold' ] }
                  text-overflow={ 'ellipsis' }
                  numberOfLines={ 1 }
                  width={ '110px' }
                >
									{ item?.owner?.type == 'PlayerOrg'
                    ? item?.owner?.name
                      ? item?.owner?.name
                      : item?.owner?.title
                    : item?.owner?.title
                      ? item?.owner?.title
                      : item?.owner?.name }
                </Text>
                <Text
                  fontSize={ [ 13 ] }
                  color={ '#00A7BC' }
                  text-overflow={ 'ellipsis' }
                  numberOfLines={ 1 }
                  width={ '110px' }
                >
                  { item?.owner?.subType === "Class" ? Loc.currentLang.menu.class :
                    item?.owner?.subType == "GradClass" ? Loc.currentLang.menu.gradClass :
                      item?.owner?.subType === 'Club' ? Loc.currentLang.menu.club :
                        item?.owner?.type == "Organization" ? Loc.currentLang.menu.organization :
                          item?.owner?.type == "Community" ? Loc.currentLang.menu.community :
                            item?.owner?.type == "Neighborhood" ? Loc.currentLang.menu.neighborHood :
															item?.owner?.type === 'PlayerOrg' && item?.owner?.subType !== "Term" ? Loc.currentLang.menu.season :
																item?.owner?.type == "PlayerOrg" && item?.owner?.subType == "Term" ? "Term" :
                                item?.owner?.type === 'Event' ? Loc.currentLang.menu.event :
                                  (item?.owner?.type === 'Team' && !item?.owner?.subType) ? Loc.currentLang.menu.team :
                                    (item?.owner?.type === 'Club' && !item?.owner?.subType) ? Loc.currentLang.menu.eliteclub :
                                      item?.owner?.type === 'School' ? Loc.currentLang.menu.school :
                                        item?.owner?.type === 'Group' && item?.owner?.title !== 'Feedback' ? Loc.currentLang.menu.group :
                                          item?.owner?.title === 'Feedback' ? item?.owner?.title :
                                            item?.owner?.subType ? item?.owner?.subType : item?.owner?.type }
                </Text>
              </AVStack>
            </AHStack>
          </Pressable>
        ) }
        { !showDetails && (
          <AVStack alignItems={ 'flex-start' } justifyContent={ 'flex-start' } marginLeft={ '-30px' }>
            <Pressable
              onPress={ () => {
                handleEditPopup('card');
              } }
              _hover={ { backgroundColor: 'AVColor.hovercolor2', rounded: 'full' } }
              p={ 2 }
            >
              <MoreHorizontalIcon size={ '18px' } color={ 'AVColor.primaryText' } />
            </Pressable>
          </AVStack>
        ) }
      </HStack>

			{/* post */}
			<EditPostMenu
				item={item}
				showEditPopup={showEditPopup}
				setEditItem={setEditItem}
				currentUser={currentUser}
				setShowEditPopup={setShowEditPopup}
				handleAcceptedPost={handleAcceptedPost}
				handleEditPost={handleEditPost}
				handleDeletePost={handleDeletePost}
				handleReportPost={handleReportPost}
			/>
		</>
	);
};
const PostActions = ({
	item,
	setShowFBEmoji,
	showFBEmoji,
	handleComment,
	showComment,
	handleLikePost,
	setCommentValue,
	handleAddComment,
	handleClick,
	handleClicks,
	showDetails,
	position,
	currentUser,
	handleremoveLike,
	props,
	handlePoint,
	handleImpression,
	modalVisible,
	setModalVisible,
	impressionDetails,
	emojiMapping,
	sad,
	like,
	heart,
	wow,
	unlike,
	cheer,
	question,
	mad,
	lol,
	broken,
	nsad,
	nlike,
	nheart,
	nwow,
	nunlike,
	ncheer,
	nquestion,
	nmad,
	nlol,
	nbroken,
	calculateTimeDifference,
	handleImpressionpop,
	selectedTab,
	navigate,

}) => {
	const [showFeedbackImpressions, setShowFeedbackImpressions] = React.useState(false);
	const menuRef = useRef(null);
	useOutsideAlerter(menuRef);
	function useOutsideAlerter(ref) {
		React.useEffect(() => {
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					setShowFeedbackImpressions(false);
				}
			}
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref]);
	}

	return (
		<AHStack alignItems={"center"} paddingHorizontal={10} paddingVertical={3}>
			{item?.impressions?.filter(x => x?.id == currentUser?.id && x?.impression == "Like").length > 0 ? (
				<Pressable
					onPress={() => {
						handleremoveLike(item, "Like");
					}}
					p={1.5}
					px={2}
					_hover={{ backgroundColor: "AVColor.hovercolor2", borderRadius: 5 }}>
					<HStack flexDirection={"row"} space="1">
						<LikeIcon size={"AVIconSmall"} color={"#32AE78"} />
						<Text color={"#32AE78"} bold={true} fontSize={"15px"} mt={1}>
							{Loc.currentLang.menu.like}
						</Text>
					</HStack>
				</Pressable>
			) : (
				<Pressable
					onPress={() => {
						handleLikePost(item, "Like");
					}}
					p={1.5}
					px={2}
					_hover={{ backgroundColor: "AVColor.hovercolor2", borderRadius: 5 }}>
					<HStack flexDirection={"row"} space="1">
						<LikeIcon size={"AVIconSmall"} color={"AVColor.lightGrey"} />
						<Text color="AVColor.secondaryText" fontSize={"15px"} mt={1}>
							{Loc.currentLang.menu.like}
						</Text>
					</HStack>
				</Pressable>
			)}

      <Box>
        { item?.impressions?.filter((x) => x?.id == currentUser?.id && x?.impression !== 'Like' && x?.impression !== null && x?.impression !== undefined).length >
          0 ? <Pressable
            onPress={ () => {
              setShowFeedbackImpressions(!showFeedbackImpressions);
            } }
            p={ 1.5 }
            px={ 2 }
            _hover={ { backgroundColor: 'AVColor.hovercolor2', borderRadius: 5 } }
          >
          <AHStack alignItems='center' gap={ '5px' }>
            <ChatIcon size={ '18px' } color='#32AE78' />
            <Text color='#32AE78' fontSize={ '15px' } mt={ 1 }>
              { Loc.currentLang.menu.feedBack }
            </Text>
          </AHStack>
        </Pressable> : <Pressable
          onPress={ () => {
            setShowFeedbackImpressions(!showFeedbackImpressions);
          } }
          p={ 1.5 }
          px={ 2 }
          _hover={ { backgroundColor: 'AVColor.hovercolor2', borderRadius: 5 } }
        >
          <AHStack alignItems='center' gap={ '5px' }>
            <ChatIcon size={ '18px' } color='AVColor.secondaryText' />
            <Text color='AVColor.secondaryText' fontSize={ '15px' } mt={ 1 }>
              { Loc.currentLang.menu.feedBack }
            </Text>
          </AHStack>
        </Pressable> }
        { showFeedbackImpressions && (
          <HStack
            space={ 2 }
            backgroundColor={ 'gray.100' }
            ref={ menuRef }
            position={ 'absolute' }
            top={ '-40px' }
            alignItems={ 'center' }
            justifyContent={ 'space-around' }
            px={ 2 }
            py={ 1 }
            borderRadius={ 'full' }
          >
            <Pressable
              onPress={ () => {
                handleLikePost(item, 'Like');
                setShowFeedbackImpressions(false);
              } }
            >
              <HStack>
                <Tooltip
				  title={Loc.currentLang.impression.like }
                  enterDelay={ 100 }
                  leaveDelay={ 0 }
                  arrow
                  placement="top"
                >
                  <Image source={ { uri: settings.like } } alt='like' size='20px' />
                </Tooltip>
              </HStack>
            </Pressable>

						<Pressable
							onPress={() => {
								handleLikePost(item, "Unlike");
								setShowFeedbackImpressions(false);
							}}>
							<HStack>
								<Tooltip title={ Loc.currentLang.impression.unlike }
									enterDelay={ 100 } leaveDelay={ 0 } arrow placement="top">
									<Image source={{ uri: settings.unlike }} alt="unLike" size="20px" />
								</Tooltip>
							</HStack>
						</Pressable>

						<Pressable
							onPress={() => {
								handleLikePost(item, "heart");
								setShowFeedbackImpressions(false);
							}}>
							<HStack>
								<Tooltip title={ Loc.currentLang.impression.heart } enterDelay={100} leaveDelay={0} arrow placement="top">
									<Image source={{ uri: settings.heart }} alt="heart" size="20px" />
								</Tooltip>
							</HStack>
						</Pressable>

						<Pressable
							onPress={() => {
								handleLikePost(item, "Wow");
								setShowFeedbackImpressions(false);
							}}>
							<HStack>
								<Tooltip title={ Loc.currentLang.impression.wow } enterDelay={100} leaveDelay={0} arrow placement="top">
									<Image source={{ uri: settings.wow }} alt="wow" size="20px" />
								</Tooltip>
							</HStack>
						</Pressable>

						<Pressable
							onPress={() => {
								handleLikePost(item, "Lol");
								setShowFeedbackImpressions(false);
							}}>
							<HStack>
								<Tooltip title={ Loc.currentLang.impression.lol } enterDelay={100} leaveDelay={0} arrow placement="top">
									<Image source={{ uri: settings.lol }} alt="lol" size="20px" />
								</Tooltip>
							</HStack>
						</Pressable>

						<Pressable
							onPress={() => {
								handleLikePost(item, "Cheer");
								setShowFeedbackImpressions(false);
							}}>
							<HStack>
								<Tooltip title={ Loc.currentLang.impression.cheer } enterDelay={100} leaveDelay={0} arrow placement="top">
									<Image source={{ uri: settings.cheer }} alt="fan" size="20px" />
								</Tooltip>
							</HStack>
						</Pressable>

						<Pressable
							onPress={() => {
								handleLikePost(item, "Question");
								setShowFeedbackImpressions(false);
							}}>
							<HStack>
								<Tooltip title={ Loc.currentLang.impression.question } enterDelay={100} leaveDelay={0} arrow placement="top">
									<Image source={{ uri: settings.question }} alt="Question" size="20px" />
								</Tooltip>
							</HStack>
						</Pressable>

						<Pressable
							onPress={() => {
								handleLikePost(item, "broken");
								setShowFeedbackImpressions(false);
							}}>
							<HStack>
								<Tooltip title={ Loc.currentLang.impression.broken } enterDelay={100} leaveDelay={0} arrow placement="top">
									<Image source={{ uri: settings.broken }} alt="broken" size="20px" />
								</Tooltip>
							</HStack>
						</Pressable>
						<Pressable
							onPress={() => {
								handleLikePost(item, "Sad");
								setShowFeedbackImpressions(false);
							}}>
							<HStack>
								<Tooltip title={ Loc.currentLang.impression.sad } enterDelay={100} leaveDelay={0} arrow placement="top">
									<Image source={{ uri: settings.sad }} alt="sads" size="20px" />
								</Tooltip>
							</HStack>
						</Pressable>

						<Pressable
							onPress={() => {
								handleLikePost(item, "Mad");
								setShowFeedbackImpressions(false);
							}}>
							<HStack>
								<Tooltip title={ Loc.currentLang.impression.mad } enterDelay={100} leaveDelay={0} arrow placement="top">
									<Image source={{ uri: settings.mad }} alt="mad" size="20px" />
								</Tooltip>
							</HStack>
						</Pressable>
					</HStack>
				)}
			</Box>

			{!showDetails && (item?.allowReply == true || currentUser?.id === item?.person?.id || props?.iscommentAccess) && (
				<Pressable
					onPress={() => {
						handleClicks();
						handleClick();
					}}
					p={1.5}
					px={2}
					_hover={{ backgroundColor: "AVColor.hovercolor2", borderRadius: 5 }}>
					<AHStack alignItems="center" gap={"5px"}>
						<CommentIcon size={"18px"} color="AVColor.secondaryText" />
						{item?.children?.filter(x => x?.status !== "Deleted").length > 0 && (
							<Text color="AVColor.secondaryText" fontSize={"15px"} mt={1}>
								{item?.children?.filter(x => x?.status !== "Deleted").length}
							</Text>
						)}
						<Text color="AVColor.secondaryText" fontSize={"15px"} mt={1}>
							{Loc.currentLang.post.comment}
						</Text>
					</AHStack>
				</Pressable>
			)}

			{/*  <Pressable
                onPress={showDetails ? handleClicks:handleClick }
                    //  onPress={() => handleClick('shows')}
                    p={ 1.5 }
                    px={ 2 }
                    _hover={ { backgroundColor: 'AVColor.hovercolor2', borderRadius: 5 } }
                >
                    <AHStack alignItems='center' gap={ '5px' }>
                        <CommentIcon size={ '18px' } color='AVColor.secondaryText' />
                        <Text color='AVColor.secondaryText' fontSize={ '15px' } mt={ 1 }>
                            Comment
                        </Text>
                    </AHStack>
                </Pressable>*/}

			{/*expressions  */}
			<AHStack gap={ 3 } justifyContent={ "flex-end" } flex={ 1 } paddingLeft={ 5 }>
				{ item?.impressions?.filter(x => x.impression == "Wow").length > 0 && (
					<Pressable
						onPress={ () => handleImpression(item, "Wow") }
					>
						<AHStack
							gap={ 3 }
							borderRadius={ "20px" }
							alignItems={ "center" }
							backgroundColor={ "#def2ff" }
							paddingHorizontal={ "5px" }
							paddingVertical={ "2px" }>
							<Image source={ { uri: settings.wow } } alt="startruck" size="18" />
							<Text fontSize={ 11 } fontWeight={ "bold" }>
								{ item?.impressions?.filter(x => x.impression == "Wow").length }
							</Text>
						</AHStack>
					</Pressable>

				) }
				{ item?.impressions?.filter(x => x.impression == "Lol").length > 0 && (
					<Pressable
						onPress={ () => handleImpression(item, "Lol") }

					>
						<AHStack
							gap={ 3 }
							borderRadius={ "20px" }
							alignItems={ "center" }
							backgroundColor={ "#def2ff" }
							paddingHorizontal={ "5px" }
							paddingVertical={ "2px" }>
							<Image source={ { uri: settings.lol } } alt="startruck" size="18" />
							<Text fontSize={ 11 } fontWeight={ "bold" }>
								{ item?.impressions?.filter(x => x.impression == "Lol").length }
							</Text>
						</AHStack>
					</Pressable>

				) }

				{ item?.impressions?.filter(x => x.impression == "Like").length > 0 && (
					<Pressable
						onPress={ () => handleImpression(item, "Like") }

					>
						<AHStack

							gap={ 3 }
							borderRadius={ "20px" }
							alignItems={ "center" }
							backgroundColor={ "#def2ff" }
							paddingHorizontal={ "5px" }
							paddingVertical={ "2px" }

						>

							<Image source={ { uri: settings.like } } alt="Like" size="18" />
							<Text
								fontWeight="bold"
								fontSize="11"


							>
								{ item?.impressions?.filter(x => x.impression == "Like").length }
							</Text>
						</AHStack>
					</Pressable>

				) }
				{ item?.impressions?.filter(x => x.impression == "Unlike").length > 0 && (
					<Pressable
						onPress={ () => handleImpression(item, "Unlike") }
					>
						<AHStack
							gap={ 3 }
							borderRadius={ "20px" }
							alignItems={ "center" }
							backgroundColor={ "#def2ff" }
							paddingHorizontal={ "5px" }
							paddingVertical={ "2px" }>
							<Image source={ { uri: settings.unlike } } alt="unLike" size="18" />
							<Text fontWeight={ "bold" } fontSize={ "11" }>
								{ item?.impressions?.filter(x => x.impression == "Unlike").length }
							</Text>
						</AHStack>
					</Pressable>

				) }
				{ item?.impressions?.filter(x => x.impression == "Cheer").length > 0 && (
					<Pressable
						onPress={ () => handleImpression(item, "Cheer") }
					>
						<AHStack
							gap={ 3 }
							borderRadius={ "20px" }
							alignItems={ "center" }
							backgroundColor={ "#def2ff" }
							paddingHorizontal={ "5px" }
							paddingVertical={ "2px" }>
							<Image source={ { uri: settings.cheer } } alt="sad" size="18" />
							<Text fontWeight={ "bold" } fontSize={ "11" }>
								{ item?.impressions?.filter(x => x.impression == "Cheer").length }
							</Text>
						</AHStack>
					</Pressable>

				) }

				{ item?.impressions?.filter(x => x.impression == "Question").length > 0 && (
					<Pressable
						onPress={ () => handleImpression(item, "Question") }

					>
						<AHStack
							gap={ 3 }
							borderRadius={ "20px" }
							alignItems={ "center" }
							backgroundColor={ "#def2ff" }
							paddingHorizontal={ "5px" }
							paddingVertical={ "2px" }>
							<Image source={ { uri: settings.question } } alt="sad" size="18" />
							<Text fontWeight={ "bold" } fontSize={ "11" }>
								{ item?.impressions?.filter(x => x.impression == "Question").length }
							</Text>
						</AHStack>
					</Pressable>

				) }

				{ item?.impressions?.filter(x => x.impression == "Sad").length > 0 && (
					<Pressable
						onPress={ () => handleImpression(item, "Sad") }
					>
						<AHStack
							gap={ 3 }
							borderRadius={ "20px" }
							alignItems={ "center" }
							backgroundColor={ "#def2ff" }
							paddingHorizontal={ "5px" }
							paddingVertical={ "2px" }>
							<Image source={ { uri: settings.sad } } alt="sads" size="18" />
							<Text fontWeight={ "bold" } fontSize={ "11" }>
								{ item?.impressions?.filter(x => x.impression == "Sad").length }
							</Text>
						</AHStack>
					</Pressable>

				) }

				{ item?.impressions?.filter(x => x.impression == "heart").length > 0 && (
					<Pressable
						onPress={ () => handleImpression(item, "heart") }
					>		<AHStack
						gap={ 3 }
						borderRadius={ "20px" }
						alignItems={ "center" }
						backgroundColor={ "#def2ff" }
						paddingHorizontal={ "5px" }
						paddingVertical={ "2px" }>
							<Image source={ { uri: settings.heart } } alt="sad" size="18" />
							<Text fontWeight={ "bold" } fontSize={ "11" }>
								{ item?.impressions?.filter(x => x.impression == "heart").length }
							</Text>
						</AHStack>
					</Pressable>

				) }
				{ item?.impressions?.filter(x => x.impression == "broken").length > 0 && (
					<Pressable
						onPress={ () => handleImpression(item, "broken") }
					>
						<AHStack
							gap={ 3 }
							borderRadius={ "20px" }
							alignItems={ "center" }
							backgroundColor={ "#def2ff" }
							paddingHorizontal={ "5px" }
							paddingVertical={ "2px" }>
							<Image source={ { uri: settings.broken } } alt="sad" size="18" />
							<Text fontWeight={ "bold" } fontSize={ "11" }>
								{ item?.impressions?.filter(x => x.impression == "broken").length }
							</Text>
						</AHStack>
					</Pressable>

				) }
				{ item?.impressions?.filter(x => x.impression == "Mad").length > 0 && (
					<Pressable
						onPress={ () => handleImpression(item, "Mad") }>
						<AHStack

							gap={ 3 }
							borderRadius={ "20px" }
							alignItems={ "center" }
							backgroundColor={ "#def2ff" }
							paddingHorizontal={ "5px" }
							paddingVertical={ "2px" }>
							<Image source={ { uri: settings.mad } } alt="mad" size="18" />
							<Text fontWeight={ "bold" } fontSize={ "11" }>
								{ item?.impressions?.filter(x => x.impression == "Mad").length }
							</Text>
						</AHStack>
					</Pressable>

				) }



			</AHStack>
			<Modal visible={ modalVisible } transparent={ true } animationType="slide">
				<View style={ {
					flex: 1,
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: 'rgba(0, 0, 0, 0.5)',
				//	overflow: 'hidden',
				} }>
					<View style={ {
						width: 567,
						height: 447,
						padding: 24,
						overflow: 'scroll',
						backgroundColor: 'white',
						borderRadius: 10,
					} }>

						<AHStack style={ { justifyContent: "spaceBetween", gap: "10px" } }>
							<Text style={ {
								fontWeight: 'bold',
								fontSize: 22,
								marginBottom: 10,
							} }>Reactions</Text>
							{/*<Text>All ( { impressionDetails.length } )</Text>*/ }
							{/*<div>*/ }
							{/*	<Image source={ { uri: emojiMapping[ item.impression ] } } alt="mad" size="20px" />*/ }
							{/*	<span>({ item.impression ? item.impression.length : 0 })</span>*/ }
							{/*</div>*/ }
							<View style={ { flex: 1 } } />
							<Text
								onPress={ () => setModalVisible(false) } 
								style={ {
									color: 'gray',
									backgroundColor: "transparent",
									textAlign: "right",
									fontWeight: 'bold',
									fontSize: 22,
								} }
								numberOfLines={ 1 } // This limits the text to a single line
								ellipsizeMode="tail" // Adds ellipsis ("...") at the end if the text overflows
							>
								
								<CloseIcon/></Text>						</AHStack>

						{/* Displaying all reactions */ }
						{/*<FlatList*/ }
						{/*	data={ impressionDetails }*/ }
						{/*	keyExtractor={ ( item, index ) => index.toString() }*/ }
						{/*	renderItem={ ( { item } ) => (*/ }
						{/*		<AHStack style={ { marginBottom: 10, alignItems: 'center' } }>*/ }
						{/*			<Text>All ( { impressionDetails.length} )</Text>*/ }
						{/*			<Image source={ { uri: emojiMapping[ item.impression ] } } alt={ item.impression } size="18" />*/ }

						{/*			*/ }{/*<Image source={ { uri: settings.sad } } alt="sads" size="18" />*/ }
						{/*			<Text style={ { fontSize: 16, fontWeight: 'bold', marginLeft: 5 } }>{ item.title }</Text>*/ }
						{/*			<Image source={ { uri: emojiMapping[ item.impression ] } } alt={ item.impression } size="18" />*/ }
						{/*			{ item.timestamp && (*/ }
						{/*				<Text style={ { fontSize: 12, color: 'gray', marginLeft: 5 } }>*/ }

						{/*					Date: { new Date( item.timestamp ).toLocaleString() }*/ }
						{/*				</Text>*/ }
						{/*			) }*/ }
						{/*		</AHStack>*/ }
						{/*	) }*/ }
						{/*/>*/ }
						<AHStack
							style={ {
								alignItems: 'center',
							
								borderBottomColor: '#333',
								paddingBottom: 10,
								justifyContent: 'space-evenly',
						

								display: 'flex',
								flexDirection: 'row',
								overflowX: 'auto', // Ensures horizontal scroll
								position: 'sticky', // Keeps it fixed horizontally
								top: 0, // Stick at the top
								zIndex: 1,
							} }
						>
							<Pressable onPress={ () => handleImpressionpop("All") }>

								<AHStack
									borderBottomColor={ selectedTab == 'All' ? "#32AE78" : "transparent" }
									borderBottomWidth={ 4 }
									paddingBottom={ 12 }
									paddingLeft={ 10 }
								>


									<Text
										style={ {
											fontFamily: 'Roboto',
											fontSize: 19,
											fontWeight: '500',
											lineHeight: 18.75,
											width: 'auto',
											opacity: 1,
											textAlign: 'center',
											height: 19,
											paddingBottom: 15,
											paddingRight: 10,
										} }
									>


										All ( { item?.impressions?.length } )



									</Text>
								</AHStack>
							</Pressable>
							<div style={ { display: "flex", justifyContent: "space-around", width:"100%" } }>
								<Pressable onPress={ () => handleImpressionpop("Like") }>
									{ nlike && nlike.length > 0 && (
										<AHStack
											borderBottomColor={ selectedTab === 'Like' ? "#32AE78" : "transparent" }
											borderBottomWidth={ 4 }
											paddingBottom={ 10 }
											paddingLeft={ 10 }
											style={ {
												alignItems: 'center',
												gap: 5,
												opacity: 1,
												display: 'flex',
												flexShrink: 0,
											} }
										>
											<Image
												source={ { uri: emojiMapping[ 'Like' ] } }
												alt="like"
												size="21"
											/>
											<span
												style={ {
													width: "auto",
													fontSize: "16px",
													paddingLeft: "6px",
												} }
											>
												( { nlike.length } )
											</span>
										</AHStack>
									) }
								</Pressable>


								{/* Sad Button */ }
								<Pressable onPress={ () => handleImpressionpop("Sad") }>
									{ nsad && nsad.length > 0 && (
										<AHStack
											borderBottomColor={ selectedTab === 'Sad' ? "#32AE78" : "transparent" }
											borderBottomWidth={ 4 }
											paddingBottom={ 10 }
											paddingLeft={ 10 }
											style={ {
												alignItems: 'center',
												gap: 5,
												opacity: 1,
												display: 'flex',
												flexShrink: 0,
											} }
										>
											<Image
												source={ { uri: emojiMapping[ 'Sad' ] } }
												alt="sad"
												size="21"
											/>
											<span
												style={ {
													width: "auto",
													fontSize: "16px",
													paddingLeft: "6px",
												} }
											>
												( { nsad.length } )
											</span>
										</AHStack>
									) }
								</Pressable>

								<Pressable onPress={ () => handleImpressionpop("heart") }>
									{ nheart && nheart.length > 0 && (
										<AHStack
											borderBottomColor={ selectedTab === 'heart' ? "#32AE78" : "transparent" }
											borderBottomWidth={ 4 }
											paddingBottom={ 10 }
											paddingLeft={ 10 }
											style={ {
												alignItems: 'center',
												gap: 5,
												opacity: 1,
												display: 'flex',
												flexShrink: 0,

											} }
										>
											<Image
												source={ { uri: emojiMapping[ 'heart' ] } }
												alt="Heart"
												size="21"
											/>
											<span
												style={ {
													width: "auto",
													fontSize: "16px",
													paddingLeft: "6px",
												} }
											>
												( { nheart.length } )
											</span>
										</AHStack>
									) }
								</Pressable>
								<Pressable onPress={ () => handleImpressionpop("Wow") }>
									{ nwow && nwow.length > 0 && (
										<AHStack
											borderBottomColor={ selectedTab === 'Wow' ? "#32AE78" : "transparent" }
											borderBottomWidth={ 4 }
											paddingBottom={ 10 }
											paddingLeft={ 10 }
											style={ {
												alignItems: 'center',
												gap: 5,
												opacity: 1,
												display: 'flex',
												flexShrink: 0,
											} }
										>
											<Image
												source={ { uri: emojiMapping[ 'Wow' ] } }
												alt="wow"
												size="21"
											/>
											<span
												style={ {
													width: "auto",
													fontSize: "16px",
													paddingLeft: "6px",
												} }
											>
												( { nwow.length } )
											</span>
										</AHStack>
									) }
								</Pressable>
								<Pressable onPress={ () => handleImpressionpop("Unlike") }>
									{ nunlike && (
										<FlatList
											data={ nunlike }
											horizontal // Setting the FlatList to horizontal
											keyExtractor={ (item, index) => index.toString() }
											renderItem={ ({ item }) => (
												<AHStack
													borderBottomColor={ selectedTab == 'Unlike' ? "#32AE78" : "transparent" }
													borderBottomWidth={ 4 }
													paddingBottom={ 10 }
													paddingLeft={ 10 }
													style={ {
														alignItems: 'center',
														gap: 5,
														opacity: 1,
														display: 'flex',
														flexShrink: 0,
													} }
												>
													<Image
														source={ { uri: emojiMapping[ 'Unlike' ] } }
														alt={ item.impression }
														size="21"
													/>
													<span
														style={ {
															width: "39px",
															fontSize: "16px",
															paddingLeft: "6px",
														} }
													>
														({ nunlike.length })
													</span>
												</AHStack>
											) }
										/>
									) }
								</Pressable>
								<Pressable onPress={ () => handleImpressionpop("Cheer") }>
									{ ncheer && ncheer.length > 0 && (
										<AHStack
											borderBottomColor={ selectedTab === 'Cheer' ? "#32AE78" : "transparent" }
											borderBottomWidth={ 4 }
											paddingBottom={ 10 }
											paddingLeft={ 10 }
											style={ {
												alignItems: 'center',
												gap: 5,
												opacity: 1,
												display: 'flex',
												flexShrink: 0,
											} }
										>
											<Image
												source={ { uri: emojiMapping[ 'Cheer' ] } }
												alt="Cheer"
												size="21"
											/>
											<span
												style={ {
													width: "auto",
													fontSize: "16px",
													paddingLeft: "6px",
												} }
											>
												( { ncheer.length } )
											</span>
										</AHStack>
									) }
								</Pressable>
								<Pressable onPress={ () => handleImpressionpop("Question") }>
									{ nquestion && nquestion.length > 0 && (
										<AHStack
											borderBottomColor={ selectedTab === 'Question' ? "#32AE78" : "transparent" }
											borderBottomWidth={ 4 }
											paddingBottom={ 10 }
											paddingLeft={ 10 }
											style={ {
												alignItems: 'center',
												gap: 5,
												opacity: 1,
												display: 'flex',
												flexShrink: 0,
											} }
										>
											<Image
												source={ { uri: emojiMapping[ 'Question' ] } }
												alt="question"
												size="21"
											/>
											<span
												style={ {
													width: "auto",
													fontSize: "16px",
													paddingLeft: "6px",
												} }
											>
												( { nquestion.length } )
											</span>
										</AHStack>
									) }
								</Pressable>
								<Pressable onPress={ () => handleImpressionpop("Mad") }>
									{ nmad && nmad.length > 0 && (
										<AHStack
											borderBottomColor={ selectedTab === 'Mad' ? "#32AE78" : "transparent" }
											borderBottomWidth={ 4 }
											paddingBottom={ 10 }
											paddingLeft={ 10 }
											style={ {
												alignItems: 'center',
												gap: 5,
												opacity: 1,
												display: 'flex',
												flexShrink: 0,
											} }
										>
											<Image
												source={ { uri: emojiMapping[ 'Mad' ] } }
												alt="Mad"
												size="21"
											/>
											<span
												style={ {
													width: "auto",
													fontSize: "16px",
													paddingLeft: "6px",
												} }
											>
												( { nmad.length } )
											</span>
										</AHStack>
									) }
								</Pressable>

								{/*{ like &&*/ }
								{/*	<FlatList*/ }
								{/*		data={ like }*/ }
								{/*		keyExtractor={ (item, index) => index.toString() }*/ }
								{/*		renderItem={ ({ item }) => (*/ }
								{/*			<AHStack style={ { marginHorizontal: 5, alignItems: 'center' } }>*/ }
								{/*				<Image source={ { uri: emojiMapping[ item.impression ] } } alt={ item.impression } size="18" />*/ }
								{/*				<span>({ item.impression.length })</span>*/ }

								{/*			</AHStack>*/ }
								{/*		) }*/ }
								{/*	/>*/ }
								{/*}*/ }
								<Pressable onPress={ () => handleImpressionpop("Lol") }>
									{ nlol && nlol.length > 0 && (
										<AHStack
											borderBottomColor={ selectedTab === 'Lol' ? "#32AE78" : "transparent" }
											borderBottomWidth={ 4 }
											paddingBottom={ 10 }
											paddingLeft={ 10 }
											style={ {
												alignItems: 'center',
												gap: 5,
												opacity: 1,
												display: 'flex',
												flexShrink: 0,
											} }
										>
											<Image
												source={ { uri: emojiMapping[ 'Lol' ] } }
												alt="lol"
												size="21"
											/>
											<span
												style={ {
													width: "auto",
													fontSize: "16px",
													paddingLeft: "6px",
												} }
											>
												( { nlol.length } )
											</span>
										</AHStack>
									) }
								</Pressable>
								<Pressable onPress={ () => handleImpressionpop("broken") }>
									{ nbroken && nbroken.length > 0 && (
										<AHStack
											borderBottomColor={ selectedTab === 'broken' ? "#32AE78" : "transparent" }
											borderBottomWidth={ 4 }
											paddingBottom={ 10 }
											paddingLeft={ 10 }
											style={ {
												alignItems: 'center',
												gap: 5,
												opacity: 1,
												display: 'flex',
												flexShrink: 0,
											} }
										>
											<Image
												source={ { uri: emojiMapping[ 'broken' ] } }
												alt="broken"
												size="21"
											/>
											<span
												style={ {
													width: "auto",
													fontSize: "16px",
													paddingLeft: "6px",
												} }
											>
												( { nbroken.length } )
											</span>
										</AHStack>
									) }
								</Pressable>
							</div>


						</AHStack>
						<>
							<div style={ { marginBottom: "10px", marginLeft: "-22px", marginRight: "-22px" } }>
								<Divider thickness={ '0.5' } />
							</div>

						</>


						<AVStack style={ {
							margin: "20px",
							padding: "40px",
							position: "sticky",  
							zIndex: 1, 
						} }>

							{ like && (
								<FlatList
									data={ like }
									keyExtractor={ (item, index) => index.toString() }
									renderItem={ ({ item }) => (
										<>
											<Pressable
												onPress={ () => {
													navigate(`/profile/${ item?.id }/${ currentUser?.region }`, {
														state: { user: item },
													})
												}
												}

											>
											<AHStack style={ { marginTop: 25, alignItems: 'center',flexDirection: 'row' }}>
										
													<ProfilePic
														width={ 35 } height={ 35 }
														link={ item } />
											
												

												<AVStack style={ { margin: "2px" } }>
													<Text style={ { fontSize: 18, fontWeight: '500', marginLeft: 5 } }>{ item.title }</Text>
													{ item.date && (
														<Text style={ { fontSize: 10, fontWeight: '400', color: 'gray', marginLeft: 5 } }>
															{ calculateTimeDifference(item.date) }
														</Text>
													) }
												</AVStack>
												<View style={ { flex: 1 } } />
												<Image
													source={ { uri: emojiMapping[ item.impression ] } }
													alt={ item.impression }
													style={ { width: 24, height: 24 } }
													resizeMode="contain"
												/>
											</AHStack>
											</Pressable>
											{/* Add the bottom line */ }
											<View style={ { height: 1, backgroundColor: '#E0E0E0', marginTop: 10, marginBottom: 10 } } />
										</>
									) }
								/>
							) }
							{ wow && (
								<FlatList
									data={ wow }
									keyExtractor={ (item, index) => index.toString() }
									renderItem={ ({ item }) => (
										<>
											<Pressable
												onPress={ () => {
													navigate(`/profile/${ item?.id }/${ currentUser?.region }`, {
														state: { user: item },
													})
												}
												}

											>
												<AHStack style={ { marginTop: 25, alignItems: 'center' } }>

													<ProfilePic
														width={ 35 } height={ 35 }
														link={ item } />



													<AVStack style={ { margin: "2px" } }>
														<Text style={ { fontSize: 18, fontWeight: '500', marginLeft: 5 } }>{ item.title }</Text>
														{ item.date && (
															<Text style={ { fontSize: 10, fontWeight: '400', color: 'gray', marginLeft: 5 } }>
																{ calculateTimeDifference(item.date) }
															</Text>
														) }
													</AVStack>
													<View style={ { flex: 1 } } />
													<Image
														source={ { uri: emojiMapping[ item.impression ] } }
														alt={ item.impression }
														style={ { width: 24, height: 24 } }
														resizeMode="contain"
													/>
												</AHStack>
											</Pressable>

											{/* Add the bottom line */ }
											<View style={ { height: 1, backgroundColor: '#E0E0E0', marginTop: 10, marginBottom: 10 } } />
										</>
									) }
								/>
							) }
							{ sad && (
								<FlatList
									data={ sad }
									keyExtractor={ (item, index) => index.toString() }
									renderItem={ ({ item }) => (
										<>
											<Pressable
												onPress={ () => {
													navigate(`/profile/${ item?.id }/${ currentUser?.region }`, {
														state: { user: item },
													})
												}
												}

											>
												<AHStack style={ { marginTop: 25, alignItems: 'center' } }>

													<ProfilePic
														width={ 35 } height={ 35 }
														link={ item } />



													<AVStack style={ { margin: "2px" } }>
														<Text style={ { fontSize: 18, fontWeight: '500', marginLeft: 5 } }>{ item.title }</Text>
														{ item.date && (
															<Text style={ { fontSize: 10, fontWeight: '400', color: 'gray', marginLeft: 5 } }>
																{ calculateTimeDifference(item.date) }
															</Text>
														) }
													</AVStack>
													<View style={ { flex: 1 } } />
													<Image
														source={ { uri: emojiMapping[ item.impression ] } }
														alt={ item.impression }
														style={ { width: 24, height: 24 } }
														resizeMode="contain"
													/>
												</AHStack>
											</Pressable>

											{/* Add the bottom line */ }
											<View style={ { height: 1, backgroundColor: '#E0E0E0', marginTop: 10, marginBottom: 10 } } />
										</>
									) }
								/>
							) }
							{ heart && (
								<FlatList
									data={ heart }
									keyExtractor={ (item, index) => index.toString() }
									renderItem={ ({ item }) => (
										<><Pressable
											onPress={ () => {
												navigate(`/profile/${ item?.id }/${ currentUser?.region }`, {
													state: { user: item },
												})
											}
											}

										>
											<AHStack style={ { marginTop: 25, alignItems: 'center' } }>

												<ProfilePic
													width={ 35 } height={ 35 }
													link={ item } />



												<AVStack style={ { margin: "2px" } }>
													<Text style={ { fontSize: 18, fontWeight: '500', marginLeft: 5 } }>{ item.title }</Text>
													{ item.date && (
														<Text style={ { fontSize: 10, fontWeight: '400', color: 'gray', marginLeft: 5 } }>
															{ calculateTimeDifference(item.date) }
														</Text>
													) }
												</AVStack>
												<View style={ { flex: 1 } } />
												<Image
													source={ { uri: emojiMapping[ item.impression ] } }
													alt={ item.impression }
													style={ { width: 24, height: 24 } }
													resizeMode="contain"
												/>
											</AHStack>
										</Pressable>
											{/* Add the bottom line */ }
											<View style={ { height: 1, backgroundColor: '#E0E0E0', marginTop: 10, marginBottom: 10 } } />
										</>
									) }
								/>
							) }
							{ unlike && (
								<FlatList
									data={ unlike }
									keyExtractor={ (item, index) => index.toString() }
									renderItem={ ({ item }) => (
										<>
											<Pressable
												onPress={ () => {
													navigate(`/profile/${ item?.id }/${ currentUser?.region }`, {
														state: { user: item },
													})
												}
												}

											>
												<AHStack style={ { marginTop: 25, alignItems: 'center' } }>

													<ProfilePic
														width={ 35 } height={ 35 }
														link={ item } />



													<AVStack style={ { margin: "2px" } }>
														<Text style={ { fontSize: 18, fontWeight: '500', marginLeft: 5 } }>{ item.title }</Text>
														{ item.date && (
															<Text style={ { fontSize: 10, fontWeight: '400', color: 'gray', marginLeft: 5 } }>
																{ calculateTimeDifference(item.date) }
															</Text>
														) }
													</AVStack>
													<View style={ { flex: 1 } } />
													<Image
														source={ { uri: emojiMapping[ item.impression ] } }
														alt={ item.impression }
														style={ { width: 24, height: 24 } }
														resizeMode="contain"
													/>
												</AHStack>
											</Pressable>

											{/* Add the bottom line */ }
											<View style={ { height: 1, backgroundColor: '#E0E0E0', marginTop: 10, marginBottom: 10 } } />
										</>
									) }
								/>
							) }
							{ cheer && (
								<FlatList
									data={ cheer }
									keyExtractor={ (item, index) => index.toString() }
									renderItem={ ({ item }) => (
										<>
											<Pressable
												onPress={ () => {
													navigate(`/profile/${ item?.id }/${ currentUser?.region }`, {
														state: { user: item },
													})
												}
												}

											>
												<AHStack style={ { marginTop: 25, alignItems: 'center' } }>

													<ProfilePic
														width={ 35 } height={ 35 }
														link={ item } />



													<AVStack style={ { margin: "2px" } }>
														<Text style={ { fontSize: 18, fontWeight: '500', marginLeft: 5 } }>{ item.title }</Text>
														{ item.date && (
															<Text style={ { fontSize: 10, fontWeight: '400', color: 'gray', marginLeft: 5 } }>
																{ calculateTimeDifference(item.date) }
															</Text>
														) }
													</AVStack>
													<View style={ { flex: 1 } } />
													<Image
														source={ { uri: emojiMapping[ item.impression ] } }
														alt={ item.impression }
														style={ { width: 24, height: 24 } }
														resizeMode="contain"
													/>
												</AHStack>
											</Pressable>
											{/* Add the bottom line */ }
											<View style={ { height: 1, backgroundColor: '#E0E0E0', marginTop: 10, marginBottom: 10 } } />
										</>
									) }
								/>
							) }
							{ question && (
								<FlatList
									data={ question }
									keyExtractor={ (item, index) => index.toString() }
									renderItem={ ({ item }) => (
										<>
											<Pressable
												onPress={ () => {
													navigate(`/profile/${ item?.id }/${ currentUser?.region }`, {
														state: { user: item },
													})
												}
												}

											>
												<AHStack style={ { marginTop: 25, alignItems: 'center' } }>

													<ProfilePic
														width={ 35 } height={ 35 }
														link={ item } />



													<AVStack style={ { margin: "2px" } }>
														<Text style={ { fontSize: 18, fontWeight: '500', marginLeft: 5 } }>{ item.title }</Text>
														{ item.date && (
															<Text style={ { fontSize: 10, fontWeight: '400', color: 'gray', marginLeft: 5 } }>
																{ calculateTimeDifference(item.date) }
															</Text>
														) }
													</AVStack>
													<View style={ { flex: 1 } } />
													<Image
														source={ { uri: emojiMapping[ item.impression ] } }
														alt={ item.impression }
														style={ { width: 24, height: 24 } }
														resizeMode="contain"
													/>
												</AHStack>
											</Pressable>

											{/* Add the bottom line */ }
											<View style={ { height: 1, backgroundColor: '#E0E0E0', marginTop: 10, marginBottom: 10 } } />
										</>
									) }
								/>
							) }
							{ mad && (
								<FlatList
									data={ mad }
									keyExtractor={ (item, index) => index.toString() }
									renderItem={ ({ item }) => (
										<>
											<Pressable
												onPress={ () => {
													navigate(`/profile/${ item?.id }/${ currentUser?.region }`, {
														state: { user: item },
													})
												}
												}

											>
												<AHStack style={ { marginTop: 25, alignItems: 'center' } }>

													<ProfilePic
														width={ 35 } height={ 35 }
														link={ item } />



													<AVStack style={ { margin: "2px" } }>
														<Text style={ { fontSize: 18, fontWeight: '500', marginLeft: 5 } }>{ item.title }</Text>
														{ item.date && (
															<Text style={ { fontSize: 10, fontWeight: '400', color: 'gray', marginLeft: 5 } }>
																{ calculateTimeDifference(item.date) }
															</Text>
														) }
													</AVStack>
													<View style={ { flex: 1 } } />
													<Image
														source={ { uri: emojiMapping[ item.impression ] } }
														alt={ item.impression }
														style={ { width: 24, height: 24 } }
														resizeMode="contain"
													/>
												</AHStack>
											</Pressable>
											{/* Add the bottom line */ }
											<View style={ { height: 1, backgroundColor: '#E0E0E0', marginTop: 10, marginBottom: 10 } } />
										</>
									) }
								/>
							) }
							{ lol && (
								<FlatList
									data={ lol }
									keyExtractor={ (item, index) => index.toString() }
									renderItem={ ({ item }) => (
										<>
											<Pressable
												onPress={ () => {
													navigate(`/profile/${ item?.id }/${ currentUser?.region }`, {
														state: { user: item },
													})
												}
												}

											>
												<AHStack style={ { marginTop: 25, alignItems: 'center' } }>

													<ProfilePic
														width={ 35 } height={ 35 }
														link={ item } />



													<AVStack style={ { margin: "2px" } }>
														<Text style={ { fontSize: 18, fontWeight: '500', marginLeft: 5 } }>{ item.title }</Text>
														{ item.date && (
															<Text style={ { fontSize: 10, fontWeight: '400', color: 'gray', marginLeft: 5 } }>
																{ calculateTimeDifference(item.date) }
															</Text>
														) }
													</AVStack>
													<View style={ { flex: 1 } } />
													<Image
														source={ { uri: emojiMapping[ item.impression ] } }
														alt={ item.impression }
														style={ { width: 24, height: 24 } }
														resizeMode="contain"
													/>
												</AHStack>
											</Pressable>
											{/* Add the bottom line */ }
											<View style={ { height: 1, backgroundColor: '#E0E0E0', marginTop: 10, marginBottom: 10 } } />
										</>
									) }
								/>
							) }
							{ broken && (
								<FlatList
									data={ broken }
									keyExtractor={ (item, index) => index.toString() }
									renderItem={ ({ item }) => (
										<>
											<Pressable
												onPress={ () => {
													navigate(`/profile/${ item?.id }/${ currentUser?.region }`, {
														state: { user: item },
													})
												}
												}

											>
												<AHStack style={ { marginTop: 25, alignItems: 'center' } }>

													<ProfilePic
														width={ 35 } height={ 35 }
														link={ item } />



													<AVStack style={ { margin: "2px" } }>
														<Text style={ { fontSize: 18, fontWeight: '500', marginLeft: 5 } }>{ item.title }</Text>
														{ item.date && (
															<Text style={ { fontSize: 10, fontWeight: '400', color: 'gray', marginLeft: 5 } }>
																{ calculateTimeDifference(item.date) }
															</Text>
														) }
													</AVStack>
													<View style={ { flex: 1 } } />
													<Image
														source={ { uri: emojiMapping[ item.impression ] } }
														alt={ item.impression }
														style={ { width: 24, height: 24 } }
														resizeMode="contain"
													/>
												</AHStack>
											</Pressable>

											{/* Add the bottom line */ }
											<View style={ { height: 1, backgroundColor: '#E0E0E0', marginTop: 10, marginBottom: 10 } } />
										</>
									) }
								/>
							) }
						</AVStack>
					</View>
				</View>
			</Modal>

		</AHStack>
	);
};

const RenderImages = ({ images, imageInputRef, setImages, videos, commentMedia, setvideos, handleimgCancel, setCommentMedia, setGallery, setMedia, isCommentLoading }) => {
  const Grid2styles = StyleSheet.create({
    container: { flex: 1, flexDirection: 'row', flexWrap: 'wrap', minwidth: '120px' },
    img1: {
      backgroundColor: 'lightblue',
      borderColor: '#fff',
      borderWidth: 1,
      width: '230px',
      height: 'auto'
    },
  });

	const handlecancelimage = async media => {
		setCommentMedia(commentMedia.filter((f: any) => f.id != media.id));
	};

	return (
		<div className="add_pics_inside1 p0">
			<div className="preview_actions">
				<AVAdd addEvent={() => imageInputRef.current.click()} />
			</div>
			{commentMedia.length > 0 && (
				<HStack alignContent={"center"} alignSelf={"center"} justifyContent={"center"} space={3}>
					{commentMedia.length > 0 && (
						<Box style={Grid2styles.container}>
							{commentMedia.length > 0 &&
								commentMedia?.slice(0, 20).map((media, i) => (
									<HStack style={Grid2styles["img1"]} key={media.id + i}>
										<Pressable
											position={"absolute"}
											background={"#dcdc"}
											borderRadius={"50px"}
											zIndex={4}
											padding={2}
											right={0}
											onPress={() => {
												handlecancelimage(media);
											}}>
											<CloseIcon color={"#fff"} size={5} />
										</Pressable>
										{media?.type?.startsWith("video") && (
											<HStack position={"absolute"} top={"45%"} left={"40%"}>
												<VideoIcon size={"60px"} color={"white"} />
											</HStack>
										)}
										<img
											width={"230px"}
											height={230}
											src={media?.type?.startsWith("image") ? Media.getURL(media) : Media.getVideoPicURL(media)}
											key={media.id + i}
											alt=""
										/>
									</HStack>
								))}

							{/*    { videos.slice( 0, 20 ).map( ( vid, i ) => (*/}
							{/*        <HStack style={ Grid2styles[ 'img1' ] } key={ vid + i }>*/}
							{/*            <video*/}
							{/*                controls*/}
							{/*                autoPlay*/}
							{/*                width={ '230px' }*/}
							{/*                height={ '230px' }*/}
							{/*                src={ vid }*/}
							{/*                key={ vid + i }*/}
							{/*            />*/}
							{/*        </HStack>*/}
							{/*    ) ) }*/}
						</Box>
					)}
				</HStack>
			)}
		</div>
	);
};

const LoadMedia = ({ item, setVideoStatus, videoStatus, useStyles, setVideoItem }) => {
	let videomedia: any = [];
	let imagemedia: any = [];
	let DocsMedia: any = [];
	const [imageUrls, setImagesUrl] = React.useState([]);
	const [videoUrls, setVideoUrl] = React.useState([]);
	const [ DocsUrl, setDocsUrl ] = React.useState( [] );
	const [ linkUrl, setLinkUrl ] = React.useState<any>([]);
	const [ previewData, setPreviewData ] = useState<any>( null );

	const VideoProcessing = ({ vid }) => {
		setVideoStatus(vid);
		setVideoItem(item);
		return (
			<>{vid?.status === "Processing" ? <div className="loader"></div> : <VideoIcon size={"60px"} color={"white"} />}</>
		);
	};
	const fetchLinkPreview = async (url) =>
	{
			const response:any = await axios.get( 'https://api.linkpreview.net', {
				params: {
					key: 'c85dba6f05f57b13b2fd2bea4dc9a583', // replace with your actual API key
					q: url,
				},
			} );
		return response?.image;
		
	};

	React.useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			if (
				(item?.galleryPost && item?.galleryPost?.length > 0 && item?.media?.type?.startsWith("image")) ||
				(item?.media?.storeId &&
					item?.media?.type?.startsWith( "image" ) &&
					!item?.media?.linkURL &&
					!item?.media?.type?.startsWith("application") &&
					!item?.media?.type?.startsWith("text"))
			) {
				for (let i = 0; i < item?.galleryPost?.length; i++) {
					imagemedia.push(item?.galleryPost[i].media);
					let images = imagemedia?.length && imagemedia.map(i => AV.Media.getURL(i));
					setImagesUrl(images);
				}
				if (item?.media?.storeId && item?.media?.type?.startsWith("image")) {
					imagemedia.push(item?.media);
					let images = imagemedia?.length && imagemedia.map(i => AV.Media.getURL(i));
					setImagesUrl(images);
				}
			}
			else if (
				!item?.media?.type?.startsWith("image") &&
				!item?.media?.type?.startsWith( "video" ) &&
				!item?.media?.linkURL &&
				(item?.media?.type?.startsWith("application") || item?.media?.type?.startsWith("text")) &&
				item?.galleryPost &&
				item?.galleryPost?.length > 0
			) {
				{
					for (let i = 0; i < item?.galleryPost?.length; i++) {
						DocsMedia.push(item?.galleryPost[i].media);
					}
					setDocsUrl(DocsMedia);
				}
			}
			else if (
				!item?.media?.type?.startsWith("image") &&
				!item?.media?.type?.startsWith( "video" ) &&
				!item?.media?.linkURL &&
				(item?.media?.type?.startsWith("application") || item?.media?.type?.startsWith("text"))
			) {
				DocsMedia.push(item?.media);
				setDocsUrl(DocsMedia);
			}
			if ( item?.media?.linkURL && item?.media?.linkURL?.trim()?.length > 0)
			{
				linkUrl?.push( item?.media )
				setLinkUrl( linkUrl )
			}

			{
				for (var j = 0; j < item?.galleryPost?.length; j++) {
					if (item?.galleryPost[j]?.media?.storeId && item?.galleryPost[j]?.media?.type?.startsWith("video")) {
						videomedia.push(item?.galleryPost[j]?.media);
						setVideoUrl(videomedia);
					} else if (
						(item?.galleryPost[j]?.media?.storeId && item?.galleryPost[j]?.media?.type?.startsWith("application")) ||
						item?.galleryPost[j]?.media?.type?.startsWith("text")
					) {
						DocsMedia.push(item?.galleryPost[j]?.media);
						setDocsUrl(DocsMedia);
					} else {
						imagemedia.push(item?.galleryPost[j]?.media);
						let images = imagemedia?.length && imagemedia.map(i => AV.Media.getURL(i));
						setImagesUrl(images);
					}
				}
				if (item?.media?.storeId && item?.media?.type?.startsWith("video")) {
					videomedia.push(item?.media);
					setVideoUrl(videomedia);
				}
			}
		}
		return () => {
			isMounted = false; // Set the flag to false when the component is unmounted
			// Cancel subscriptions or perform any cleanup tasks here
		};
	}, [item]);

	const Item = styled(Paper)(({ theme }) => ({
		backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
		...theme.typography.body2,
		padding: theme.spacing(1),
		textAlign: "center",
		color: theme.palette.text.secondary,
	}));

	const getGridItemXs = (index, length) => {
		if (length === 1) return 6;
		if (length === 2) return 6;
		if (length === 3) return index === 0 ? 12 : 6;
		if (length === 4) return 6;
		if (length === 5) return index < 2 ? 6 : 4;
		if (length > 5) return index < 2 ? 6 : 4;
	};

  const classes = useStyles();

  return (
    <AVStack width={ '100%' }>
          <div>
			  { ( ( imageUrls && imageUrls.length > 0 ) || ( DocsUrl && DocsUrl.length > 0 ) || ( videoUrls && videoUrls.length > 0 ) || (linkUrl?.length > 0))&& (
                  <div
                      className={
                          ( imageUrls.length + DocsUrl.length + videoUrls.length ) === 1
                              ? 'grid_1'
                              : ( imageUrls.length + DocsUrl.length + videoUrls.length ) === 2
                                  ? 'grid_2'
                                  : ( imageUrls.length + DocsUrl.length + videoUrls.length ) === 3
                                          ? videoUrls.length === 3 ? 'vidgrid_3' : 'grid_3' 
                                      : ( imageUrls.length + DocsUrl.length + videoUrls.length ) === 4
                                          ? 'grid_4'
                                          : 'grid_5' // Default case when total length >= 5
                      }
                  >
                     
                      { imageUrls && imageUrls.slice( 0, 5 ).map( ( url, i ) => (
                          <img
                              src={ url }
                              key={ `${ url }_${ i }` }
                              alt=''
                              className={ `img-${ i }` }
                              style={ { margin: '1px', objectFit: 'cover' } }
                          />
                      ) ) }

                      
                      { DocsUrl && DocsUrl.slice( 0, 5 - ( imageUrls ? imageUrls.length : 0 ) ).map( ( url:any, i ) => (
                          <img
                              //@ts-ignore
                              src={
                                  ( url?.type === 'application/pdf' && settings.pdf ) ||
                                  ( ( url?.type === 'application/msword' || url?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ) && settings.word ) ||
                                  ( ( url?.type === 'application/vnd.ms-powerpoint' || url?.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ) && settings.powerpoint ) ||
                                  ( ( url?.type === 'application/vnd.ms-excel' || url?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ) && settings.excel ) ||
                                  ( ( url?.type === 'text/plain' || url?.type === 'text/plain' ) && settings.file ) ||
                                  settings.file
                              }
                              key={ `${ url }_${ i }` }
                              alt=''
                              className={ `img-${ i }` }
                              style={ { margin: '1px', objectFit: 'contain' } }
                          />
                      ) ) }

            { videoUrls && videoUrls.length === 3 && (
              <div className="vidgrid-container">
                { videoUrls.map((vid, i) => (
                  <div className={ `vid vid_${ i + 1 }` } key={ i }>
                    <HStack left="-61px">
                      <HStack
                        top="45%"
                        left="60%"
                      >
                        <VideoProcessing vid={ vid } />
                      </HStack>
                      <img
                        src={ Media.getVideoPicURL(vid) }
                        alt=""
                        className={ `img-${ i } ${ videoStatus?.status === 'Processing' ? 'processing' : '' }` }
                        style={ {
                          margin: '1px',
                          objectFit: 'cover',
                          opacity: videoStatus?.status === 'Processing' ? '0.3' : '1',
                        } }
                      />
                    </HStack>
                  </div>
                )) }
              </div>
            ) }

                  { videoUrls && videoUrls.length !== 3 && videoUrls.slice( 0, 5 - ( imageUrls.length + DocsUrl.length ) ).map( ( vid, i ) => (
                          <Box key={ i }>
                              <HStack position={ 'absolute' } top={ '45%' } left={ '45%' }>
                                  <VideoProcessing vid={ vid } />
                              </HStack>
                              <img
                                  src={ Media.getVideoPicURL( vid ) }
                                  alt=''
                                  className={ `img-${ i }${ videoStatus?.status === "Processing" ? 'processing' : '' }` } // Apply 'processing' class when condition is true
                                  style={ { margin: '1px', objectFit: 'cover', opacity: videoStatus?.status === "Processing" ? '0.3' : '1' } }
                              />
                          </Box>
                      ) ) }

						{imageUrls.length + DocsUrl.length + videoUrls.length > 5 && (
							<div className="more-pics-shadow">+{imageUrls.length + DocsUrl.length + videoUrls.length - 5}</div>
						)}
					</div>
			  ) }
			  { linkUrl && linkUrl?.slice( 0, 5 - ( linkUrl ? linkUrl.length : 0 ) ).map( ( url : any, i ) => (
				  <LinkPreview url={ url?.linkURL } imageHeight={'240px' } width={ '100%' } height={ '300px' } />
			  ) ) }
			  
			</div>
		</AVStack>
	);
};

export default Card;
