import React from "react";
import { VStack } from "native-base";
import Feeds from "../Feeds/Feeds";
import { Person } from "../../AVCore/AVCore";
import AVBody from "components/common/AVBody";
import { useWindowDimension } from "Utility/WindowDimensions";
import { useLocation } from "react-router-dom";
import LinkPreview from "components/common/preview/AVLinkPreview";

function Home () {
    const location : any = useLocation();
    const middleRef = React.useRef<any>(null);
    const [ fixedWidth, setFixedWidth ] = React.useState(0);
    const person = Person.self();
    const dimensions = useWindowDimension();
    const [ showCreateNewPage, setShowCreateNewPage ] = React.useState<any>(false);
    const [ createType, setCreateType ] = React.useState<any>( "" );
    const [ isOpenConvo, setIsOpenConvo ] = React.useState( false );
    const [ isConvoData, setIsConvoData ] = React.useState( '' )

    React.useLayoutEffect(() => {
        return setFixedWidth(middleRef.current?.offsetWidth - 2);
    }, [ dimensions.width ]);
    const [ showEdit, setIsShowEdit ] = React.useState(false);
    return (
        <AVBody
            setShowCreateNewPage={ setShowCreateNewPage } setIsShowEdit={ setIsShowEdit } showCreateNewPage={ showCreateNewPage } setCreateType={ setCreateType } createType={ createType } passEntity={ undefined } setPassEntity={ undefined } isOpenConvo={ isOpenConvo } setIsOpenConvo={ setIsOpenConvo } setIsConvoData={ setIsConvoData } isConvoData={ isConvoData } >
            <VStack flex={ [ 1, 1, 1, 3 ] } ref={ middleRef }>
                <Feeds
                    page={ 'Home' }
                    id={ person?.id }
                    offW={ fixedWidth }
                    previewdata={ location?.state?.previewdata }
                />
            </VStack>
        </AVBody>
    );
}

export default React.memo(Home);
