import ReactPlayer from "react-player";
import React, { useRef, useLayoutEffect, useState } from "react";

function AVVideo(props: any) {
	const targetRef = useRef<any>();
	/*    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);    */
	const [dimensions, setDimensions] = useState({ width: props.width, height: props.height });

	useLayoutEffect(() => {
		if (targetRef.current && props.aspectRatio) {
			let height = props.minHeight;
			setDimensions({
				width: targetRef.current.wrapper.offsetWidth,
				height: height,
			});

			//forceUpdate();
		}
	}, []);

	let { aspectRatio, ...setProps } = props;

	return <ReactPlayer {...setProps} height={dimensions.height} ref={targetRef} />;
}

export default React.memo(AVVideo);
