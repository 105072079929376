import React, { useState } from 'react';
import axios from 'axios';
import { Box, Image, VStack, Button, Text, Pressable } from 'native-base'
import {Linking } from "react-native";

const LinkPreview = ({url,imageHeight,width,height }) =>
{
    const [ previewData, setPreviewData ] = useState<any>( null );
    const [ error, setError ] = useState<any>( null );

    const fetchLinkPreview = async () =>
    {
        try
        {
            const response = await axios.get( 'https://api.linkpreview.net', {
                params: {
                    key: 'c85dba6f05f57b13b2fd2bea4dc9a583', // replace with your actual API key
                    q: url,
                },
            } );
            setPreviewData( response.data );
        } catch ( error )
        {
            setPreviewData( {
                url: url,
                image: "https://pixabay.com/get/g01794082f3aba6bdb82a9dd31150d59c485636408c6905c1e41577160666f71af8695230d502ae7afb8cf86486960915.svg",
            } );
        }
    };

    React.useEffect( () =>
    {
        fetchLinkPreview();
    }, [ url ] );

    if ( error )
    {
        return <div>{ error }</div>;
    }

    if ( !previewData )
    {
        return <div>Loading...</div>;
    }

    return (
        <Box
            width={ width }
            height={ height }
            borderRadius="8px"
            overflow="hidden"
            shadow="2"
        >
            <VStack>
                <Box position="relative">
                    <Image
                        source={ previewData.image }
                        alt={ previewData.title }
                        width="100%"
                        height={ imageHeight }
                    />
                </Box>
                <Box padding="3" backgroundColor="#f5f5f5">
                    <Pressable onPress={ () => Linking.openURL(previewData.url)}>
                    <Text fontSize="12px" color="#888" marginBottom="1">{ previewData.url }</Text>
                        <Text fontSize="14px" color="#333" fontWeight="bold">{ previewData.title }</Text>
                    </Pressable>
                </Box>
            </VStack>
        </Box>
    );
};

export default LinkPreview;
