import React, { useState } from "react";
import { Button, Box, Image, HStack } from "native-base";
import { AddPhotoIcon, TeamBackground, BrushIcon } from "../../assets/icons/icon-assets";
import { useDropzone } from "react-dropzone";
import { useSelector, RootStateOrAny } from "react-redux";
import * as AV from "../../AVCore/AVCore";
import { GithubPicker } from "react-color";

const AVCoverPic = ( { Entity, handleUpload, profile, setProfile, isCreate, type, isAdminView, editAccess, isShowPicker, setIsShowpicker, imageLoader ,setImageLoader }) => {
    const currentUser = useSelector((state: RootStateOrAny) => state.user.user[0]);
    const [ backgroundImage, setBackgroudImage ] = useState<any>(isCreate? null : AV.Media.getURL(Entity?.profile?.background) ?AV.Media.getURL(Entity?.profile?.background) : null);
    const [IsLoader, setIsLoader] = useState(false);
    const [bannerColor, setBannerColor] = useState(Entity?.profile?.color ? Entity?.profile?.color : 'AVColor.secondary');
     React.useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setBackgroudImage( isCreate ? null : AV.Media.getURL( Entity?.profile?.background ) ? AV.Media.getURL( Entity?.profile?.background ) : null )
            setProfile( { background: null, pic: null, thumb: null, gallery: null, previousPic: null, text: null, color: null } )
            if (Entity && !profile)
                setProfile(Entity?.profile);
        }
        return () => {
            isMounted = false; // Set the flag to false when the component is unmounted
            // Cancel subscriptions or perform any cleanup tasks here
        };

  }, [isCreate, Entity])

    const { getRootProps: getBackgroundFile, getInputProps: getBgImageProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            setIsLoader( true );
            setImageLoader( true );
            if ( acceptedFiles.length > 0 && acceptedFiles[ 0 ].type.startsWith( 'image' ) )
            {
                setBackgroudImage( URL.createObjectURL( acceptedFiles[ 0 ] ) );
            } else
            {
                // Handle non-image files
                // Implement your validation or error handling logic here
                console.log( "Unsupported file type. Please drop an image file." );
                setIsLoader( false );
                return;
            }
            getMedia(acceptedFiles)
                .then((res) => {
                    setProfile((profile:any) => {
                        return { ...profile, background: res };
                    });
                  if (!profile) {
                      profile = { background: null, pic: Entity?.profile?.pic ? Entity?.profile?.pic: null, thumb: null, gallery: null, previousPic: null, text: null, color: Entity?.profile?.color ? Entity?.profile?.color: null, };
                    }
                    profile.pic = Entity?.profile?.pic?.id ? Entity?.profile?.pic : profile?.pic?.id ? profile?.pic : null;
                  profile.color = Entity?.profile?.color ? Entity?.profile?.color : null;
                    profile.text = Entity?.profile?.text ? Entity?.profile?.text : profile?.text ? profile?.text : null;
                  profile.background = [];
                  res = { ...res, parent: AV.AVEntity.getFk(Entity) }
                  profile.background = res;
                    setProfile(profile);
                })
                
                .then((x) => {
                    if (!isCreate){
                        Entity.profile = profile;
                        handleUpload( Entity );
                        setImageLoader( false );
                    }
                });
        },
    });

	const getMedia = async (file: any) => {
		const updatedFiles = file.map(file => {
			const fileName = file.name;
			if (fileName.toLowerCase().endsWith(".jfif")) {
				const updatedFileName = fileName.replace(/\.\w+$/, ".jpg");
				const updatedFile = new File([file], updatedFileName, { type: "image/jpeg" });
				return updatedFile;
			} else {
				return file;
			}
		});
		file = updatedFiles;
		let iMedia = await AV.Media.saveFiles(
			AV.Media.getFormData(file),
			Entity?.region ? Entity?.region : currentUser?.region,
		);
		setIsLoader(false);
		return iMedia[0];
	};
	const handleBanner = async color => {
		setBannerColor(color.hex);
		setProfile(profile => {
			return { ...profile, color: color.hex };
		});
		let img: any = {
			background: null,
			pic: null,
			thumb: null,
			gallery: null,
			previousPic: null,
			text: null,
			color: null,
		};
		if (Entity.profile) img = Entity.profile;
		if (!img.color) img.color = [];
		img.color = color.hex;
		Entity.profile = img;
		setProfile(img);
		setIsShowpicker(false);
		await handleUpload(Entity);
	};

    return (
        <>
           
            { ( type !== 'Feedback' && ( Entity?.type === "Event" ? Entity?.profile?.background?.id : true)) && (
                <Box width={'100%'} bgColor={type === 'Feedback' ? 'white' : 'gray.300'} borderTopLeftRadius={10}
            borderTopRightRadius={10} height={type === 'Feedback' ? '140px':'230px'}>
            {(type === 'Feedback' || Entity?.type == 'Event' ) && (
                        <Box
                backgroundColor={bannerColor}
                height={'42px'}
                display={'flex'}
                alignItems={'flex-end'}
                pt={0.5}
                borderTopLeftRadius={'10px'}
                borderTopRightRadius={'10px'}
            >
                        <HStack>
                            <Box>

                                {isShowPicker && (<Box marginTop={'42px'}>
                                    <GithubPicker
                                        color={bannerColor}
                                        onChangeComplete={handleBanner}
                                        width={'400px'}
                                    />
                                </Box>)}
                                    { (editAccess && isAdminView) &&  (<Button
                                    bgColor={'AVColor.white'}
                                    position={'absolute'}
                                    top={"5px"}
                                    right={"24px"}
                                    height={'32px'}
                                    width={'32px'}
                                    rounded={'full'}
                                    _hover={{ backgroundColor: 'AVColor.hovercolor' }}
                                    shadow={4}
                                    _spinner={{ color: 'AVColor.black' }}
                                    zIndex={99}
                                    onPress={() => { setIsShowpicker(!isShowPicker) }}
                                >
                                    <BrushIcon height={'22'} width={'22'} color={'black'} />
                                </Button>)}
                            </Box>
                                {(editAccess && isAdminView) &&  (<Button
                                bgColor={'AVColor.white'}
                                position={'absolute'}
                                top={"5px"}
                                right={"69px"}
                                height={'32px'}
                                width={'32px'}
                                rounded={'full'}
                                _hover={{ backgroundColor: 'AVColor.hovercolor' }}
                                shadow={4}
                                _spinner={{ color: 'AVColor.black' }}
                                zIndex={99}
                               //  isDisabled={ imageLoader }
                                isLoading={IsLoader}

                            >
                                <Box {...getBackgroundFile({ className: 'dropzone' })}>
                                    <input {...getBgImageProps()} />
                                    <AddPhotoIcon size={'22px'} color={'black'} />
                                </Box>
                            </Button>)}
                        </HStack>
               
            </Box>)}
                   
                    { ( ( type !== 'Feedback' && Entity?.type !== 'Event' && isAdminView && Entity?.status !== 'Deleted') || isCreate) && (<Button
                bgColor={'AVColor.white'}
                position={'absolute'}
                top={"20px"}
                right={"27px"}
                height={'40px'}
                width={'40px'}
                rounded={'full'}
                _hover={{ backgroundColor: 'AVColor.hovercolor' }}
                shadow={4}
                _spinner={{ color: 'AVColor.black' }}
                zIndex={99}
              //  isDisabled={ imageLoader }
                isLoading={IsLoader}
            >
                <Box {...getBackgroundFile({ className: 'dropzone' })}>
                    <input {...getBgImageProps()} />
                    <AddPhotoIcon size={'28px'} color={'black'} />
                </Box>
            </Button>)}
            
            {(Entity?.profile?.background || profile?.background) ?
          (<Image
           style={{ height: Entity?.type == 'Event' ? '82%' : '100%', width: '100%', borderTopLeftRadius: Entity?.type == 'Event' ? 0 : 10, borderTopRightRadius: Entity?.type == 'Event' ? 0 : 10,zIndex:-3 } }
            source={ {
              uri: backgroundImage,
            } }
          />) :
           (
            <TeamBackground />) }
            </Box>)}
            {(type === 'Feedback') && (<Box width={'100%'} bgColor={type === 'Feedback' ? 'white' : 'gray.300'} borderTopLeftRadius={10}
                borderTopRightRadius={10} height={type === 'Feedback' ? '140px' : '230px'}>
                <Box
                    backgroundColor={'#32AE78'}
                    height={'42px'}
                    display={'flex'}
                    alignItems={'flex-end'}
                    pt={0.5}
                    borderTopLeftRadius={'10px'}
                    borderTopRightRadius={'10px'}
                >
                </Box>
               
            </Box>)}
            { ( Entity?.type === "Event" && !Entity?.profile?.background?.id) && (<Box
                backgroundColor={bannerColor}
                height={'42px'}
                display={'flex'}
                alignItems={'flex-end'}
                pt={0.5}
                borderTopLeftRadius={'10px'}
                borderTopRightRadius={'10px'}
            >
                <HStack>
                    <Box>

                        {isShowPicker && (<Box marginTop={'42px'}>
                            <GithubPicker
                                color={bannerColor}
                                onChangeComplete={handleBanner}
                                width={'400px'}
                            />
                        </Box>)}
                        { (isAdminView && Entity?.status !== 'Canceled') &&(<Button
                            bgColor={'AVColor.white'}
                            position={'absolute'}
                            top={"5px"}
                            right={"24px"}
                            height={'32px'}
                            width={'32px'}
                            rounded={'full'}
                            _hover={{ backgroundColor: 'AVColor.hovercolor' }}
                            shadow={4}
                            _spinner={{ color: 'AVColor.black' }}
                            zIndex={99}
                            onPress={() => { setIsShowpicker(!isShowPicker) }}
                        >
                            <BrushIcon height={'22'} width={'22'} color={'black'} />
                        </Button>)}
                    </Box>
                    { (isAdminView && Entity?.status !== 'Canceled') && (<Button
                        bgColor={'AVColor.white'}
                        position={'absolute'}
                        top={"5px"}
                        right={"69px"}
                        height={'32px'}
                        width={'32px'}
                        rounded={'full'}
                        _hover={{ backgroundColor: 'AVColor.hovercolor' }}
                        shadow={4}
                        _spinner={{ color: 'AVColor.black' }}
                        zIndex={ 99 }
                       // isDisabled={ imageLoader }
                        isLoading={IsLoader}

                    >
                        <Box {...getBackgroundFile({ className: 'dropzone' })}>
                            <input {...getBgImageProps()} />
                            <AddPhotoIcon size={'22px'} color={'black'} />
                        </Box>
                    </Button>)}
                </HStack>

            </Box>)}
        </>
    )
}

export default React.memo(AVCoverPic);
